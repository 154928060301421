import React from 'react';

import Select from '../formElements/Select'
import MultiSelect from '../formElements/MultiSelect'
import StandartMultiSelect from '../formElements/StandartMultiSelect'
import TickBox from '../formElements/TickBox'
import Button from "../formElements/Button";

import  sAction  from "sAction";

class CalSettingsForm extends React.Component{
    constructor(props){
      super(props);
      
    const data = props.data.toJS();

      this.state = {
        showMeetings : data.showMeetings,
        meetStates: data.meetStates,
        showCalls : data.showCalls,
        callStates: data.callStates,
        showTasks: data.showTasks,
        tasksLastDay: data.tasksLastDay,
        taskStates: data.taskStates,
        weekNumbers: data.weekNumbers,
        showCompleted: data.showCompleted,
        showWeekends: data.showWeekends,
        initView: data.initView,
        sharedView: data.sharedView,
        sharedIds: data.sharedIds,
        startIntervalHours : data.startIntervalHours,
        startIntervalMinutes : data.startIntervalMinutes,
        endIntervalHours : data.endIntervalHours,
        endIntervalMinutes : data.endIntervalMinutes,
      }
    }

    updateStateFilters(field, key, val){
        //let pomStates = sAction.dataGet("calendar/filters/"+field).toJS();
        let pomStates = this.state[field];
        if(val){
          pomStates.push(key);
        }
        else{
          let pomInd = pomStates.indexOf(key);
          pomStates.splice(pomInd, 1);
        }
  
        this.updateFilters(field, pomStates);
       
      }

    updateFilters(name, value){
        let pom = {};
        pom[name] = value;
        this.setState(pom);
    }

    saveCalSettingCallBack(){
        sAction.calInit(sAction.calEvents.cssSelector);
        sAction.popupHide();
    }

    render(){
        var viewsOptions = [];
        sAction.app_strings.calendar_initial_view.forEachObject((lbl, key) => {
            viewsOptions.push({value: key, label: lbl});
        });

        
        var meetOptions = [];
        sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
            meetOptions.push({value: key, label: lbl});
        });

        var callOptions = [];
        sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
            callOptions.push({value: key, label: lbl});
        });

        var taskOptions = [];
        sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
            taskOptions.push({value: key, label: lbl});
        });

        const users = this.props.data.toJS().users;

        var userOptions = [];
        users.forEachObject((lbl, key) => {
            userOptions.push({value: key, label: lbl});
        });

        var pomMeetRender = [];
        meetOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.taskStates != null && this.state.meetStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomMeetRender.push(<div key={"meetStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("meetStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });

        var pomCallRender = [];
        callOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.callStates != null && this.state.callStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomCallRender.push(<div key={"callStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("callStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });

        var pomTaskRender = [];
        taskOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.taskStates != null && this.state.taskStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomTaskRender.push(<div key={"taskStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("taskStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });


        /*
                <div className="viewPanelButtonsDelimiter" />
                <Button className="calendarButton acmButton hoverRed" onClick={() => {sAction.popupHide()}}>
                        <div className={"calHeaderIcon icon-closeIcon"} ></div>
                        {sAction.translate("LBL_SETTINGS_CANCEL_BUTTON", "Calendar")}
                    </Button>  
        */

        return (
            <div className="calSettingsForm">
            <div className="calPopupTitle">{sAction.translate("LBL_SETTINGS", "Calendar")}</div>
            <div className="viewActionPanel">
                    <Button className="calendarButton acmButton hoverGreen" onClick={() => {sAction.saveCalSettings(this.state, this.saveCalSettingCallBack)}}>
                        <div className={"calHeaderIcon icon-saveIcon"} ></div>
                        {sAction.translate("LBL_SETTINGS_SAVE_BUTTON", "Calendar")}
                    </Button>  
                </div>
            <div className="calSettingsFormContainer">
                <table>
                    <tbody>
                    <tr className="lastSectionRow">
                        <td className="calSetLbl">{sAction.translate("LBL_FC_SHARED", "Calendar")}:</td>
                        <td><TickBox className="calFiltersStateTick" name="sharedView" checked={!!this.state.sharedView} onChange={(value) => this.updateFilters("sharedView", value)} /></td>
                        <td></td>
                        <td className="calSetLbl">{sAction.translate("LBL_EDIT_USERLIST", "Calendar")}: </td>
                        <td><MultiSelect open="false" name="sharedIds" id="sharedIds" options={userOptions} defaultValue={this.state.sharedIds} onBlur={(value) => this.updateFilters("sharedIds", value)} /></td>
                    </tr>
                    <tr>
                    <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_INITIAL_VIEW", "Calendar")}: </td>
                        <td className="calShiftPad"><Select open={false} name="initView" options={viewsOptions} defaultValue={this.state.initView} onChange={(ev) => this.updateFilters("initView", ev.target.value)} /></td>
                        <td className="calSetFiller"></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                    <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_TIME_STARTS", "Calendar")}:</td>
                        <td className="calShiftPad">
                            <div>
                            <input className="form-control form-control-sm datetimehours calTimeInput" type="number" maxLength="2" defaultValue={this.state.startIntervalHours} onBlur={(ev) => this.updateFilters("startIntervalHours", ev.target.value)}/>&nbsp;:&nbsp;
                            <input className="form-control form-control-sm datetimeminutes calTimeInput" type="number" maxLength="2" defaultValue={this.state.startIntervalMinutes} onBlur={(ev) => this.updateFilters("startIntervalMinutes", ev.target.value)}/>
                            </div>
                        </td>
                        <td></td>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_TIME_ENDS", "Calendar")}:</td>
                        <td className="calShiftPad">
                            <input className="form-control form-control-sm datetimehours calTimeInput" type="number" maxLength="2" defaultValue={this.state.endIntervalHours} onBlur={(ev) => this.updateFilters("endIntervalHours", ev.target.value)}/>&nbsp;:&nbsp;
                            <input className="form-control form-control-sm datetimeminutes calTimeInput" type="number" maxLength="2" defaultValue={this.state.endIntervalMinutes} onBlur={(ev) => this.updateFilters("endIntervalMinutes", ev.target.value)}/>
                        </td>
                    </tr>
                    <tr className="lastSectionRow">
                        <td className="calSetLbl">{sAction.translate("LBL_WEEK_NUMBERS_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="weekNumbers" checked={!!this.state.weekNumbers} onChange={(value) => this.updateFilters("weekNumbers", value)} /></td>
                        <td></td>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_WEEKEND_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="showWeekends" checked={!!this.state.showWeekends} onChange={(value) => this.updateFilters("showWeekends", value)} /></td>
                    </tr>

                    <tr>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_COMPLETED_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="showCompleted" checked={!!this.state.showCompleted} onChange={(value) => this.updateFilters("showCompleted", value)} /></td>
                        <td></td>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_MEETINGS_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="showMeetings" checked={!!this.state.showMeetings} onChange={(value) => this.updateFilters("showMeetings", value)} /></td>
                    </tr>
                    <tr>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_CALLS_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="showCalls" checked={!!this.state.showCalls} onChange={(value) => this.updateFilters("showCalls", value)} /></td>
                        <td></td>
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_TASKS_SHOW", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="showTasks" checked={!!this.state.showTasks} onChange={(value) => this.updateFilters("showTasks", value)} /></td>
                    </tr>
                    <tr className="lastSectionRow">
                        <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_TASKS_LAST_DAY", "Calendar")}: </td>
                        <td><TickBox className="calFiltersStateTick" name="tasksLastDay" checked={!!this.state.tasksLastDay} onChange={(value) => this.updateFilters("tasksLastDay", value)} /></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_MEETING_STATUS", "Calendar")}: </td>
                        <td className="calSetStateContainer">{pomMeetRender}</td>
                        <td></td>
                        <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_CALL_STATUS", "Calendar")}: </td>
                        <td className="calSetStateContainer">{pomCallRender}</td>
                    </tr>
                    <tr>
                        <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_TASK_STATUS", "Calendar")}:</td>
                        <td className="calSetStateContainer">{pomTaskRender}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </div>
        );
    }
}
export default CalSettingsForm