import React from "react";
import PureComponent from "../../pure";
import Relate from '../../formElements/Relate'
import Button from "../../formElements/Button";
import TickBox from '../../formElements/TickBox'

import  sAction  from "sAction";
import Loader from "../../loader";


export default class OppDuplicatePopup extends React.Component {

    constructor(props){
        super(props);
        const account = props.data.get("account");
        this.state = {
            account : {value: account.get("value"), id_value: account.get("id_value")},
            quotes: { },
          quotes_list: [],

          loaded: false
        }
    }

    handleCreate(){
        const params = {action: "opp_duplicate", 
        "account": this.state.account.id_value, 
        quotes: this.state.quotes,
        record: this.props.data.get("id")
    };
        sAction.load();
        sAction.rest.post("customAction", params, function(resp) {
            sAction.unLoad();
            if(resp.errCode == "OK"){
                sAction.href("#detail/Opportunities/"+resp.record);
            }
            else{
                sAction.error(resp.errMsg);
            }
        });

    }

    handleSelectAcc(item){
        this.setState({account: {value: item.name, id_value: item.id}})
    }

    updateStateQuotes(record, val){
        this.setState({quotes : { ...this.state.quotes, [record]: val }});
    }

    componentDidMount() {
        const opportunity_id = this.props.data.get("id");
        sAction.rest.post("getQuotesByOpportunity", {opportunity_id}, quotes => {
            this.setState({quotes_list: quotes || [], loaded: true});
        });
    }

    render(){
        if (!this.state.loaded) return <Loader />;

        const quotes = this.state.quotes_list;

        var quoteComp = [];
        quotes.forEach(quote =>{
            let itemSel = false;
            const id = quote.id;
            if(this.state.quotes[id] == true){
                itemSel = true;
            }
            quoteComp.push(<div key={"quote_"+id} className="">
                <TickBox className="calFiltersStateTick" checked={itemSel} onChange={(value) => this.updateStateQuotes(id, value)} />
                <span className="calFilterLbl">{quote.quote_num + " ("+quote.name+")"}</span>
            </div>)
        });

        const account = this.state.account;
        return <React.Fragment>
        <div className="viewActionPanelButtons">
                <Button className="hoverGreen" onClick={() => this.handleCreate()}>
                    <div className={"icon-saveIcon calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CREATE_QUOTE", "Quotes")}
                </Button>
                <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                    <div className={"icon-close calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CANCEL_ACTION")}
                </Button>
            </div>

            <div className="templateCreatorFormContainer templateCreatorForm oppDuplicateContainer duplicateOppFormContainer">
            <div className="templateCreatorFormRow">
                <div className="quoteTemplNameLbl">
                    {sAction.translate("LBL_ACCOUNT_NAME", "Opportunities")}
                </div>
                <Relate
                    newRecord={false}
                    module="Accounts"
                    data={account}
                    buttons={[]}
                    callback={(item) => this.handleSelectAcc(item)}
                    key={account.id_value}
                />
            </div>
            <div className="duplicateOppFormQuotesContainer">
                <div className="duppOppQuotesHeader">
                    {sAction.translate("LBL_DUPLICATE_OPP_SELECTED_QUOTES", "Opportunities")}:
                </div>
                {quoteComp}
            </div>

            </div>
            </React.Fragment>;
    }
}