import React, { Component } from 'react'
import PureComponent from '../../pure'

import CalDetailContainer from './calDetailContainer'
//import ActTabMenu from './actTabMenu'
import ButtonFloat from "../../formElements/Button";
import Button from "../../formElements/Button";

import DetailTimeline from "../../detailView/DetailTimeline";

import TabContainer from '../../formElements/TabContainer'

import  sAction  from "sAction";

class CalDataContainer extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            activeTab : 0,
        };
    }

    changeActiveTab(newTab, parent){
        if(newTab == this.state.activeTab){
            return;
        }

        this.setState({activeTab : newTab});
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.activeTab != this.props.activeTab){
            this.changeActiveTab(0);
        }
    }

    onClick(actionName, params){
        if(actionName != undefined && sAction[actionName] != undefined){
            sAction[actionName](params);
            this.setState({activeTab : 0});
        }
    }        


    render(){
        const calProps = this.props.data;
        const invitees = calProps.invitees;

        const type = this.props.type;
        const activeModule = this.props.activeTab;
        const activeTab = this.state.activeTab;
        const module = this.props.module;
        const way = this.props.prefix;

        var content = null;
        var parent_name = null;
        const id = calProps.calQuickCreate.toJS().id;
        
        var saveBtn = {
            id: "btn_save_record",
            onClick: "calSaveActivityDetailHelper",
            extraClass: "hoverGreen",
            params: {pomWay: way},
            icon: "icon-saveIcon",
            label: sAction.app_strings["LBL_SAVE_RECORD"]
        };

        var deleteBtn = {
            id: "btn_delete_record",
            onClick: "calActivityHelper",
            extraClass: "hoverRed",
            params: {type: "DELETE", id: id, module: module, pomWay: way},
            icon: "icon-deleteIcon",
            label: sAction.app_strings['LBL_DELETE_RECORD'],
            "newRecord" : false,
            "savedRecord" : true,
        };

        var cancelBtn = {
            id: "btn_cancel_record",
            onClick: "calActivityHelper",
            extraClass: "hoverRed",
            params: {type: "CANCEL", pomWay: way},
            icon: "icon-closeIcon",
            label: sAction.app_strings["LBL_ROUTING_BUTTON_CANCEL"]
        };

        var fullFormBtn = {
            id: "btn_fullform",
            onClick: "calActivityHelper",
            extraClass: "hoverBlue",
            params: {type: "FULLFORM", id: id, module: module, pomWay: way},
            icon: "icon-expandIcon",
            label: sAction.translate("LBL_FULL_FORM_BTN", "Calendar"),
            "newRecord" : false,
            "savedRecord" : true,
            "acl" : "detail",

        };

        var completeSaveBtn = {
            id : null,
            onClick: "calActivityHelper",
            extraClass: "hoverBlue",
            params: {type: "COMPLETEANDCREATENEW", pomWay: way},
            icon: "",
            label: "",
            "newRecord" : false,
            "savedRecord" : true,
        }

        var buttons = [];

        var timeline = null;

        if(type == "editView"){
            let pomSave = Object.assign({}, saveBtn);

            pomSave.params = {prefix : way+"/detailView", prefixInv : way+"/invitees", pomWay: way};
            deleteBtn.params.prefix = way+"/detailView";

            buttons = [pomSave, deleteBtn, fullFormBtn];

            let detailData = calProps.calQuickCreate;

            timeline = <DetailTimeline
                id={detailData.id}
                data={detailData.timeline}
                module={detailData.module}
                way={way+"/detailView"}
                field={detailData.fields.get(detailData.timeline.field)}
                type={detailData.type}
            />;

            if(module == "Meetings" || module == "Calls"){

                let newMeet = Object.assign({}, completeSaveBtn);
                newMeet.id = "btn_create_meeting";
                newMeet.icon = "icon-Meetings";
                newMeet.label = sAction.translate("LBL_CLOSE_AND_CREATE_MEETING", "Calendar");

                newMeet.params = {type: "COMPLETEANDCREATENEW", compValue : "Held", prefix : way+"/detailView", module: "Meetings", prefixInv : way+"/invitees", pomWay: way};

                
                

                let newCall = Object.assign({}, completeSaveBtn);
                newCall.id = "btn_create_call";
                newCall.label = sAction.translate("LBL_CLOSE_AND_CREATE_CALL", "Calendar");
                newCall.icon = "icon-Calls";
                newCall.params = {type: "COMPLETEANDCREATENEW", compValue : "Held", prefix : way+"/detailView", module: "Calls", prefixInv : way+"/invitees", pomWay: way};

                let newOpp = Object.assign({}, completeSaveBtn);
                newOpp.id = "btn_create_opp";
                newOpp.label = sAction.translate("LBL_CLOSE_AND_CREATE_OPPORTUNITY", "Calendar");
                newOpp.icon = "icon-Opportunities";
                newOpp.params = {type: "COMPLETEANDCREATENEWOPP", compValue : "Held", prefix : way+"/detailView", pomWay: way, module: module, prefixInv : way+"/invitees"};


                if(module == "Meetings"){
                    buttons.push(newMeet);
                    buttons.push(newCall);
                }
                else{
                    buttons.push(newCall);
                    buttons.push(newMeet);                    
                }

                buttons.push(newOpp);

            }
            else if(module == "Tasks"){
                let newTask = Object.assign({}, completeSaveBtn);
                newTask.id = "btn_create_task";
                newTask.label = sAction.translate("LBL_CLOSE_AND_CREATE_BUTTON_TITLE", "Calendar");
                newTask.icon = "icon-Tasks";
                newTask.params = {type: "COMPLETEANDCREATENEW", compValue : "Completed", prefix: way+"/detailView", module: "Tasks", prefixInv : way+"/invitees", pomWay: way};

                buttons.push(newTask);
            }

            content = <CalDetailContainer 
                        detailKey="detail" 
                        key="detail" 
                        data={calProps.calQuickCreate} 
                        way={way+"/detailView"} 
                        prefixInv={way+"/invitees"}
                        invitees={invitees} 
                        type={type} 
                        activeModule={activeModule} 
                        activeTab={activeTab}
                        />
            parent_name = calProps.calQuickCreate.fields.toJS().parent_name;
        }
        else if (type == "createView"){
            let pomSave = Object.assign({}, saveBtn);

            // console.log("createView", calProps.meetingView);

            let prefix = "";
                if(activeModule == "detailMeeting"){
                    prefix = way+"/meetingView";
                    let detailData = calProps.meetingView;

                    timeline = <DetailTimeline
                        id={detailData.id}
                        data={detailData.timeline}
                        module={detailData.module}
                        way={way+"/meetingView"}
                        field={detailData.fields.get(detailData.timeline.field)}
                        type={detailData.type}
                    />;
                }
                else if(activeModule == "detailCall"){
                    prefix = way+"/callView";

                    let detailData = calProps.callView;

                    timeline = <DetailTimeline
                        id={detailData.id}
                        data={detailData.timeline}
                        module={detailData.module}
                        way={way+"/callView"}
                        field={detailData.fields.get(detailData.timeline.field)}
                        type={detailData.type}
                    />;
                }
                else if(activeModule == "detailTask"){
                    prefix = way+"/taskView";

                    let detailData = calProps.taskView;

                    timeline = <DetailTimeline
                        id={detailData.id}
                        data={detailData.timeline}
                        module={detailData.module}
                        way={way+"/taskView"}
                        field={detailData.fields.get(detailData.timeline.field)}
                        type={detailData.type}
                    />;
                }

            pomSave.params = {prefix : prefix, prefixInv : way+"/invitees", pomWay: way};

            buttons = [pomSave, cancelBtn];
            
            content = [

            <CalDetailContainer 
                    detailKey="detailMeeting" 
                    key="detailMeeting" 
                    data={calProps.meetingView} 
                    way={way+"/meetingView"} 
                    prefixInv={way+"/invitees"}
                    invitees={invitees} 
                    activeTab={activeModule} 
                    type={type} 
                    activeModule={activeModule} 
                    activeTab={activeTab}

                />,
                <CalDetailContainer 
                    detailKey="detailCall" 
                    key="detailCall" 
                    data={calProps.callView} 
                    way={way+"/callView"} 
                    prefixInv={way+"/invitees"}
                    invitees={invitees} 
                    activeTab={activeModule} 
                    type={type} 
                    activeModule={activeModule} 
                    activeTab={activeTab}

                />,
                <CalDetailContainer 
                    detailKey="detailTask" 
                    key="detailTask" 
                    data={calProps.taskView} 
                    way={way+"/taskView"}
                    prefixInv={way+"/invitees"}
                    invitees={invitees} 
                    activeTab={activeModule} 
                    type={type} 
                    activeModule={activeModule} 
                    activeTab={activeTab}
                />];


            if(this.props.activeTab == "detailMeeting"){
                parent_name = calProps.meetingView.fields.toJS().parent_name;
            }
            else if(this.props.activeTab == "detailCall"){
                parent_name = calProps.callView.fields.toJS().parent_name;
            }

        }
        var renderButtons = [];

        var acl = null;

        if(type == "editView"){
            acl = calProps.calQuickCreate.acl.get("edit");
        }

        buttons.forEach(button => {

            if(acl === false){
                if(button.acl !== "detail"){
                    return;
                }
            }

            var actionName = button.onClick
            
            var params = button.params;
            renderButtons.push(
                <Button
                onClick={() => this.onClick(actionName, params)}
                variant="text"
                className={"calendarButton acmButton "+button.extraClass}
                key={button.id}
                id={button.id}
                >
                <i className="far fa-calendar-alt icon"></i>
                <div className={button.icon+" calHeaderIcon"} />
                {button.label}
                </Button>
            )
            renderButtons.push(
                <div key={"delimineter_"+button.id} className="viewPanelButtonsDelimiter" />
            );
        });

        let menuComp = null;
        if(module == "Meetings" || module == "Calls"){
            const tabs = [sAction.translate("LBL_GENERAL_TAB", "Calendar"), sAction.translate("LBL_PARTICIPANTS_TAB", "Calendar")];
            menuComp = <TabContainer
            onChange={(e,value) => this.changeActiveTab(value, parent_name)}
            value={activeTab}
            tabs={tabs}
            ></TabContainer>;
        }
        
        let classPom = "calWindowContainer";

        if(calProps.menuOpen === false || type != "createView"){
            classPom += " max";
        }

        return(
            <div className={classPom}>
                <div className="viewActionPanelButtons">
                    {renderButtons}    
                </div>
                <div className="detailCard calComponent">
                {timeline}
                {menuComp}
                {content}                
                </div>
            </div>
        );

    }

}
export default CalDataContainer
