import {List} from 'immutable';
import {DataConstructors} from '../../store/DataConstructors';

export default function exportActivitiesOrHistoryList() {
  this.load();
  const params = this.dataGet("view",["id","module"]);

  let data = DataConstructors.ActivitiesTimelineView();
  data = data.set('id', params.id);
  data = data.set('module', params.module);

  const timeLineParams = this.activitiesTimeLineParams(data);
  this.rest.post("getListActivities", timeLineParams, response => {
    response.forEachObject((value,key) => {
      value = Array.isArray(value) ? List(value) : value;
      data = data.set(key, value);
    })
    this.unLoad();

    this.printTimeline({prefix: '', all: true, storeData: data });
  });
}