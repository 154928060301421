export default function parseMultienum(multienumString) {
    let selected = multienumString.match(/(\^[^\^,]+?\^)/g);
    if (selected) {
        selected = selected.map((value) => {
            return value.slice(1, -1);
        });
    }

    return selected;
}
