import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';

import ListViewTable2 from "../../list/listViewTable2";
import Tag from "../../Tags/Tag";

export default class TagRelatesList extends PureComponent {
    constructor(props) {
        super(props);

        var tagID = this.props.data.get('tagData').get('id');
        this.state = {
            name: "",
            activeFilters: [tagID],
        };
    }

    removeFilter(tag, key, way) {
        var activeFilters = this.state.activeFilters.slice(0);

        if (activeFilters.length > 1) {
            for (var i = 0; i < activeFilters.length; i++) {
                if (activeFilters[i] === tag.get('id')) {
                    activeFilters.splice(i, 1);
                }
            }
            this.state.activeFilters = activeFilters;
            this.applyFilter();
        } else {
            activeFilters = [];
        }
        this.setState({ activeFilters: activeFilters })
    }

    setFilter(tag) {
        var activeFilters = this.state.activeFilters.slice(0);
        activeFilters.push(tag.get('id'));
        this.state.activeFilters = activeFilters;
        this.applyFilter();
        this.setState({ activeFilters: activeFilters })
    }

    applyFilter() {
        sAction.searchTagRelatives(this.state.activeFilters, this.props.way)
    }

    render() {
        const data = this.props.data;
        const lists = this.props.data.get('lists');
        const tag = this.props.data.get('tagData');
        const way = this.props.way;
        const tags = this.props.data.get('tags');
        var renderLists = [];
        if (lists != undefined) {
            lists.forEach((list, ind) => {
                renderLists.push(
                    <div className='globalSearchListContainer'
                        ref={this.listView}
                        key={ind}>
                        <div className='moduleTitle'>{sAction.translate('LBL_MODULE_NAME', list.modul)}</div>
                        {list.records.size > 0 && (
                            <ListViewTable2 data={list} key={ind} parent={this} prefix="" />
                        )}
                    </div>
                );
            });
        }
        var filteredByTags = [];
        var availableTags = [];
        tags.forEach((tag, key) => {
            if (this.state.activeFilters.includes(tag.get('id'))) {
                filteredByTags.push(
                    <Tag
                        tag={tag}
                        way={way}
                        removable={false}
                        onClick={(e) => this.removeFilter(tag, key, way)}
                        colored={true}
                        key={key}
                    />
                );
            } else {
                availableTags.push(
                    <Tag
                        tag={tag}
                        way={way}
                        removable={false}
                        onClick={(e) => this.setFilter(tag)}
                        colored={true}
                        key={key}
                    />
                );
            }
        });

        return (
            <React.Fragment>
                <div className='acmPopupHeader'>
                    {sAction.translate('LBL_TAG_RELATES')}
                </div>
                <div className='popupTagRelatesHeader'>
                    <div className='popupFilteredByTags'>
                        <div className='left'>
                                {sAction.translate('LBL_TAG_ACTIVE_FILTER')}
                        </div>
                        <div className='right'>
                            <div className='tags'>
                                {filteredByTags}
                            </div>
                        </div>
                    </div>
                    <div className='icon-dragNDropContainer tagIcon'></div>
                    <div className='popupAvailableTags'>
                        <div className='left'>
                                {sAction.translate('LBL_TAG_AVAILABLE_FILTER')}
                        </div>
                        <div className='right'>
                            <div className='tags'>
                                {availableTags}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='popupTagRelatesList'>
                    {renderLists}
                </div>
            </React.Fragment>
        );
    }
}
