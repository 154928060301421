import React from "react";
import MaterialDefault from "./MaterialDefault";
import TextField from "@material-ui/core/TextField";
export default class InputText extends MaterialDefault {
  constructor(props) {
    super(props);
    this.state = {
      focus: false
    };
    this.containerRef = React.createRef();
  }
  onBlur(e) {
    this.setState({ focus: false });
    if (this.props.onBlur != undefined) {
      this.props.onBlur(e);
    }
  }
  handleClearButton(e) {
    this.props.myRef.current.value = "";
    this.props.onClearButton && this.props.onClearButton(e);
    this.props.myRef.current.focus();
  }
  componentDidMount() {}
  render() {
    const clearButton = this.props.clearButton || false;
    const searchButton = this.props.searchButton || false;
    const attr = this.createAttr("acmInput");
    delete attr.clearButton;
    delete attr.searchButton;
    delete attr.containerStyle;
    attr.onFocus= (e) => {
      var arrayOfTypes=['currency', 'float', 'decimal', 'int']
      if (arrayOfTypes.includes(this.props.fieldtype)) {
        e.target.select()
      }
    }


    if(this.props.freeFormat == true){
      attr.type = "text";
      delete attr.freeFormat;
    }

    return (
      <div
        className={
          this.props.containerClassName != undefined
            ? "acmInputContainer " + this.props.containerClassName
            : "acmInputContainer"
        }
        style={this.props.containerStyle ? this.props.containerStyle : null}
        ref={this.containerRef}
      >
        <TextField {...attr} />
        {clearButton && (
          <span
            className="icon-closeIconLight clearButton"
            onClick={e => this.handleClearButton(e)}
          />
        )}
        {searchButton && (
          <span
            className="icon-search clearButton searchButton"
            onClick={e => this.props.onSearchButton(e)}
          />
        )}
      </div>
    );
  }
}
