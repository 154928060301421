
import React, { Component } from 'react'

import InputField from '../inputFieldClass'

export default class Account_name extends InputField {
  constructor(){
    super();
    this.state = {
      state: "fine",
    //  reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/,
    };
  }
}
