export default function addTagRelate(id, way, prefix, record, module) {
    this.load();
    this.rest.post("tags/addTagRelate/", { tagId: id, record: record, module: module }, data => {
        if (data['status']) {
            this.dsClear();
            this.dsAdd('set', prefix + '/tags', data.tags);
            this.dsProcess();
            this.popupHide();
        } else {
            this.dsClear();
            this.dsAdd("set", way + "/data/message", data['message']);
            this.dsProcess();
        }
        this.unLoad();
    });
}
