import $ from 'jquery';
import moment from 'moment';

export default function calActivityDashlet(params){
    var self = this;

    let curDate = self.calBulkActions(params.cssSelector, 'getDate', null, false);
    let newStart = null;
    let newEnd = null;

    if(params.action == "prev"){
        newStart = curDate.clone().subtract(60, "day").format("YYYY-MM-DD");
        newEnd = curDate.format("YYYY-MM-DD");
    }
    else if(params.action == "next"){
        newStart = curDate.format("YYYY-MM-DD");
        newEnd = curDate.clone().add(60, "day").format("YYYY-MM-DD");
    }
    else if(params.action == "today"){
        newStart = moment().subtract(30, "day").format("YYYY-MM-DD");
        newEnd = moment().add(30, "day").format("YYYY-MM-DD");
    }
    else if(params.action == "refresh"){
        newStart = curDate.clone().subtract(30, "day").format("YYYY-MM-DD");
        newEnd = curDate.clone().add(30, "day").format("YYYY-MM-DD");
    }

    const options = self.dataGet(params.way+"/data/options").toJS();

    if(params.action == "filter"){
        
        const eventsPom = self.dataGet(params.way+"/data/viewData/eventData");
        setDashletEvents(self, eventsPom, options, params);
    }
    else{
        var attrs = {
            date_range : {
                date_start : newStart,
                date_end : newEnd
            },
            params : { 
                sharedView : false
            },
            user_id : options.user_id
        };
        
        this.rest.post("calActivity", attrs, function(data){
            setDashletEvents(self, data.eventData.calendars[0], options, params);
            if(params.action != "refresh"){
                self.calBulkActions(params.cssSelector, params.action, null, false);
            }
        });
    }
}

function setDashletEvents(self, eventData, options, params){
    let events = [];

        if(options.showMeetings){
            eventData.Meetings.forEach((ev) => {
                if(!options.showCompleted){
                    if(ev.status != "Planned"){
                        return;
                    }
                }
    
                if(options.meetStates && options.meetStates.size > 0){
                    if(!options.meetStates.includes(ev.status)){
                        return;
                    }
                }
                let allDay = ev.all_day == "1" ? true: false;
                events.push(self.setEventStyle({id: ev.id, title: ev.name, start : ev.date_start, end: ev.date_end, act_type: "Meetings", status: ev.status, allDay: allDay}));
            });
        }

        if(options.showCalls){
            eventData.Calls.forEach((ev) => {
                if(!options.showCompleted){
                    if(ev.status != "Planned"){
                        return;
                    }
                }
    
                if(options.callStates && options.callStates.size > 0){
                    if(!options.callStates.includes(ev.status)){
                        return;
                    }
                }
                let allDay = ev.all_day == "1" ? true: false;
                events.push(self.setEventStyle({id: ev.id, title: ev.name, start : ev.date_start, end: ev.date_end, act_type: "Calls", status: ev.status, allDay: allDay}));
            });
        }

        if(options.showTasks){

            let tasksLastDay = self.dataGet(params.way+"/data/viewData/setting/tasksLastDay");

            eventData.Tasks.forEach((ev) => {
                if(!options.showCompleted){
                    if(ev.status == "Completed"){
                        return;
                    }
                }
    
                if(options.taskStates && options.taskStates.size > 0){
                    if(!options.taskStates.includes(ev.status)){
                        return;
                    }
                }

                let dateStartPom = ev.date_start;
                if(tasksLastDay){
                    if(ev.date_start.substring(0, 10) != ev.date_end.substring(0, 10)){
                        let dateObj = moment(ev.date_end).subtract(30, "minutes");
                        dateStartPom = dateObj.format("YYYY-MM-DD HH:mm:ss");
                    }
                }

                let allDay = ev.all_day == "1" ? true: false;
                events.push(self.setEventStyle({id: ev.id, title: ev.name, start : dateStartPom, end: ev.date_end, act_type: "Tasks", status: ev.status, allDay: allDay}));
            });
        }

        
        //TODO TT tady bude zpracovani eventu a nahazeni do storu (nebo mozna ne - mozna se tam bude ukladat jen current datum - jeste se uvidi, jak tam bude fungovat ta aktualizace)
        self.calBulkActions(params.cssSelector, 'removeEvents', null, false);
        self.calBulkActions(params.cssSelector, 'addEventSource', events, false);

}