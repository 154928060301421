export default function toggleSweetSpot(){
    const auth = this.rest.getAuth("sId");
    if(auth.sID == null || auth.sID == -1){
        return;
    }

    const show = this.dataGet("conf/popup/show");

    if(show){
        this.dataSet("conf/popup/show", false);
    }
    else{
        this.popup("SweetSpot", {module : this.getModuleName()});
    }

}