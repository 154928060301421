import React from "react";

export default function Loader(props) {
  const loader = (
    <div className="pageLoadLoader">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

  return props.fullscreen ? (
    <div className="pageLoad">
      {loader}
    </div>
  ) : (
    loader
  );
}
