import React from "react";
import PureComponent from "../../pure";
import ReportWindowColumns from "../reportWindow/ReportWindowColumns";
// import InputText from "../formElements/InputText";

import Button from "../../formElements/Button";

import sAction from "sAction";

export default class DefaultListViewEdit extends PureComponent {
  constructor(props) {
    super(props);
  }


  render() {
    const data = this.props.data;
    const notAllowedTypes = [
      "id",
      "Ss",
      "ss_search",
    ];
    return (
      <div className="defultListViewEditContainer">
        <div className="defultListViewEditContainerButtons">
          <Button
            onClick={() =>
              sAction.defaultListViewEditSave(data, this.props.way + "/data")
            }
          >
            <div className="icon-saveIcon buttonIcon"/>
            {sAction.translate("LBL_SAVE_RECORD")}
          </Button>
          <Button onClick={() => sAction.defaultListViewEditSetDefault(data)}>
            <div className="icon-defaultLayout buttonIcon"></div>
            {sAction.translate("LBL_DEFAULT_LAYOUT")}
          </Button>
        </div>

        <ReportWindowColumns notAllowedTypes={notAllowedTypes} data={data} way={this.props.way + "/data"}/>
      </div>
    );
  }
}
