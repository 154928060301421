import React from "react";
import  sAction  from "sAction";
export default class ErrorView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;

    var subject = "";
    var body = "";
    if(data.get("type") == "unauthorized"){
        subject = sAction.translate("LBL_UNAUTHORIZED");
        body = sAction.translate("LBL_UNAUTHORIZED_BODY");
    } else if (data.get("type") === "notValidId"){
        subject = sAction.translate("LBL_NOTVALIDID");
        body = sAction.translate("LBL_NOTVALIDID_BODY");
    }


    return <div className="errorViewContainer">
        <div className="errorView">
            <div className="header">
                <div className="icon-errorHeaderIcon"></div>
                {subject}
            </div>
            <div className="content">
                {body}
            </div>
        </div>
    </div>;
  }
}
