import DefaultFloat from '../../defaultFields/Float/Float';
import  sAction  from "sAction";

export default class Float extends DefaultFloat {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}
  
}
