export default function deleteRecords(paramData) {
    const records = this.dataGet(paramData.prefix + "/selected");
    var label = records.size > 1 || records === "all" ? "LBL_BUTTON_CONFIRM_DELETE_RECORDS" : "LBL_BUTTON_CONFIRM_DELETE_RECORD";
    this.confrim(this.translate(label), () => {
        const module = this.dataGet(paramData.prefix + "/modul");
        var ids = [];
        var filterData = null;
        if(records === "all"){
            ids = "all";
            const list = this.dataGet(paramData.prefix);
            const savedSearch = list.actSavedSearch;
            filterData = this.reloadListParams({
                list,
                prefix: paramData.prefix,
                savedSearch,
                columns: false,
            });
            filterData["selected"] = list.selected;
            filterData["module"] = list.modul;
            filterData["type"] = list.type;
        }else{
            records.forEach(id => {
                ids.push(id);
            });
        }
        this.load();

        const data = {
            ids: ids,
            filter: filterData,
        }

        this.rest.delete("deleteRecords/" + module, data, returnData => {
            if(returnData.state == true){
                if(returnData.notAccessRecords.length != 0){
                    this.error(this.translate("LBL_MASS_DELETE_ACCESS_ERROR"));
                }
                this.dataSet(paramData.prefix+"/selected",[]);
                this.reloadList(0, 1, paramData.prefix);
            }
        })
        this.popupHide();
    });
}