export default function openDynamicEnum(way, data,callback) {
    const enumId = data.def.get("options");
    const parent = data.def.getIn(["relData","parent"]);
    const fields = this.dataGet(way+"/..");
    var enums = {}
    fields.forEach(field => {
        if(field.def.get("type") == "AcmDynamicEnum"){
            const id = field.def.get("options");
            const value = field.value;
            enums[id] = value;
        }
    });
    const params = {
        enum:enumId,
        parentValue: enums[parent] ,
        enums
    }
    this.rest.post("dynamicEnumList",params,returnData => {

        callback(returnData);
        /*
        this.popup("DynamicEnumPopup",{
            header: this.translate("LBL_SEARCHED"),
            lines: returnData["lines"],
            canAddNew: returnData["canAddNew"],
            callback,
            onClose: () => {
                this.dataSet(way + "/actEdit", false);
            }
        })*/
        
    });
}
