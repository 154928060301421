export default function openNotBase(limit = 10,offset = 0){
    var language = this.dataGet("conf/language")
    const paramData = {
        limit: limit,
        offset: offset,
        lang: language
    }
    this.dsClear();
    this.dsAdd("set","notifications/open",true);
    this.dsAdd("set","notifications/load",true);
    this.dsAdd("set","notifications/notice",0);
    this.dsAdd("set","notifications/list",[]);
    this.rest.post("getNotifications",paramData,data => {
        this.dsClear();
        data.forEach(record => {
            this.dsAdd("add","notifications/list",record,"notificationsRecord");
        }); 
        this.dsAdd("set","notifications/load",false);
        if(data.length < limit){
            this.dsAdd("set","notifications/all",true)
        }
        this.dsProcess();
    });
    this.dsProcess()
}