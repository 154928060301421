export default function emailAddContacts(emails, props) {
  this.popupHide();

  let to_addrs = "";
  let cc_addrs = "";
  let bcc_addrs = "";

  emails.forEach(email => {
    switch (email.type) {
      case "normal":
        to_addrs = to_addrs + ", " + email.email;
        break;
      case "cc":
        cc_addrs = cc_addrs + ", " + email.email;
        break;
      case "bcc":
        bcc_addrs = bcc_addrs + ", " + email.email;
        break;
    }
  });

  this.dsClear();

  to_addrs = joinLists("", to_addrs);

  this.dsAdd("set", props.way + "/to_addrs", to_addrs);

  cc_addrs = joinLists("", cc_addrs);

  this.dsAdd("set", props.way + "/cc_addrs", cc_addrs);
  this.dsAdd("set", props.way + "/ccExpanded", !!cc_addrs);

  bcc_addrs = joinLists("", bcc_addrs);

  this.dsAdd("set", props.way + "/bcc_addrs", bcc_addrs);
  this.dsAdd("set", props.way + "/bccExpanded", !!bcc_addrs);

  this.dsProcess();
}

function joinLists(list1, list2) {
  if (list1 === "") {
    list2 = list2.substr(2);
  }

  return list1 + list2;
}
