import InputField from "../inputFieldClass";
import  sAction  from "sAction";

export default class ApiDIC extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      reg: /^[A-Z]+[A-Z0-9]{8,10}/
    };
  }

  check() {
    let ok = super.check();

    const value = this.getValue();

    if (ok && value && value!==this.props.data.value) {
      if (!this.testDIC(value) && /^(CZ|SK)/.test(value)) {
        sAction.error(
            sAction.translate("ERR_DIC_INVALID","Accounts")
        );
      }
    }

    return ok;
  }

  //nasledujici funkce nemusi fungovat na 100%, ale hodilo by se je pouzit (nenasilnou formou, aby sel formular odeslat!)
  testDIC(x) {
    try {
      if (x.length == 0) return true;
      var id = x.substr(0, 2).toUpperCase();
      x = x.substr(2);
      if (id == "CZ" || id == "SK") {
        if (x.length < 8) return false;
        if (x.length > 11) return false;
        if (x.length == 8) {
          return this.testICO(x);
        } else {
          return this.testRC(x, 18);
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  testRC(x, age) {
    if (!age) age = 0;
    try {
      if (x.length == 0) return true;
      if (x.length < 9) return false;
      var year = parseInt(x.substr(0, 2), 10);
      var month = parseInt(x.substr(2, 2), 10);
      var day = parseInt(x.substr(4, 2), 10);
      if (x.length == 9 && year < 54) return true;
      var c = 0;
      if (x.length == 10) c = parseInt(x.substr(9, 1), 10);
      var m = parseInt(x.substr(0, 9), 10) % 11;
      if (m == 10) m = 0;
      if (m != c) return false;
      year += year < 54 ? 2000 : 1900;
      if (month > 70 && year > 2003) month -= 70;
      else if (month > 50) month -= 50;
      else if (month > 20 && year > 2003) month -= 20;
      var d = new Date();
      if (year + age > d.getFullYear()) return false;
      if (month == 0) return false;
      if (month > 12) return false;
      if (day == 0) return false;
      if (day > 31) return false;
    } catch (e) {
      return false;
    }
    return true;
  }

  testICO(x) {
    try {
      var a = 0;
      if (x.length == 0) return true;
      if (x.length != 8) return false;
      var b = x.split("");
      var c;
      for (var i = 0; i < 7; i++) a += parseInt(b[i], 10) * (8 - i);
      a %= 11;
      c = 11 - a;
      if (a == 1) c = 0;
      if (a == 0) c = 1;
      if (a == 10) c = 1;
      if (parseInt(b[7], 10) != c) return false;
    } catch (e) {
      return false;
    }
    return true;
  }
}
