import detailDefault from "../../detailDefault";
export default class detailqt_quote_text_admin extends detailDefault {
  load(sAction, data) {
    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/description/def/type", "HTMLText");
    sAction.dsProcess();
  }

  update(sAction, data) {
    if (data.field === "description") {
      sAction.dataSet(data.prefix + "/changes/fields/description", sAction.htmlEscape(data.value, true));
    }
  }
}