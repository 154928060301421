export default function defaultListViewEditSetDefault(data){
    this.confrim(this.translate("LBL_LISTVIEW_DEFAULT_CONFRIM"),() => {
        this.load();

        const params = {
            module: data.get("module"),
            listview_type: data.get("listview_type")
        }
        if(params.listview_type == 'subpanel'){
            params["parent"] = data.get("parent")
        }

        this.rest.post("defaultListviewReset",params, ret => {
            if(ret.state == true){
                this.popupHide();
                if (params["listview_type"] === 'popup') {
                    this.selectLook(" ", "conf/popup/data/listView");
                } else {
                    this.route();
                }
            }
        })
    })
}