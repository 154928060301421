export default function subppanelChangeRelate(newRecord,subpanel,prefix,way,deleted = 0){
    var recModule = subpanel.def.getIn(["rel","relName"]);
    let relid = newRecord.id; // kdyz se do subpanelu priradi zaznam v popup okne
    if(recModule == "Activities" || recModule == "History"){
        recModule = newRecord.record.module.toLowerCase();
        relid = newRecord.record.record;
    }
    if (deleted) {
        // kdyz se maze zaznam
        relid = newRecord.record.record;
    }
    if (!relid && typeof newRecord.record == "string") {
        relid = newRecord.record;
    }
    const parentData = this.dataGet(prefix);

    let filterData = [];
    if(relid === "all"){
        //pokud se jedna o list v popupu, je treba vytahnout filtr
        let listPrefix = "conf/popup/data/listView";
        const list = this.dataGet(listPrefix);
        
        if(list){
            const savedSearch = list.actSavedSearch;
            filterData = this.reloadListParams({
                list,
                prefix: listPrefix,
                savedSearch,
                columns: false,
            });
    
            filterData["selected"] = list.selected;
            filterData["module"] = list.modul;
            filterData["type"] = list.type;
        }
    }
    
    var data = {
        module: parentData.module,
        relname: recModule,
        id: parentData.id,
        relid,
        deleted: deleted,
        filter: filterData,
    }
    this.load();
    this.rest.post("rels",data,returnData => {
        if(returnData.status == "ok"){
            const subData = {
                parentId : parentData.id,
                parentModule: parentData.module,
                module: subpanel.module,
                relName: data.relname,
                prefix: prefix,
                way:way,
                subpanelAction: "new_relate"
            }
            this.reloadSubpanel(subData);
        }
        this.popupHide();
        //this.route(); // nove zruseno kvuli #775 a #1339
    });
}