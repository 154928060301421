export default function routeLogin(){
    this.load()
    this.rest.get("trns?language="+this.getLanguage(), data => {

        this.rest.setCookie("sID","",-1);
        this.dataSet("menu/groups",[]);
        clearInterval(this.notificationInterval);

        this.app_strings = data.lang;
        this.dsClear();
        this.dsAdd("set","conf/load",false);
        this.dsAdd("set","conf/front_version",data.softwareInfo.front_version);
        this.dsAdd("set","conf/back_version",data.softwareInfo.back_version);
        this.dsAdd("set","conf/softwareName",data.softwareInfo.softwareName);
        this.dsAdd("set", "conf/view", "login");
        this.dsProcess();
        this.renderReact();
      }, false);
}