/**
 * Vraci int cislo dalsiho popupu nebo prazdny string pokud se zadny popup nezobrazuje
 * @param ignoreView - napr. relateListView, kdyz je otevren listview popup a clovek klikne na vytvoreni noveho zaznamu, premaze se aktualni popup a nevytvori novy
 * @returns {string|number}
 */
export default function getNewPopupId(ignoreView = undefined) {

  const ignoredViews = ["load"];
  if (ignoreView) {
    ignoredViews.push(ignoreView);
  }

  for (let i = 5; i > 0; i--) {
    let popupId = (i === 1) ? "" : i;

    let popup = this.dataGet("conf/popup" + popupId);
    if (popup && popup.show && !ignoredViews.includes(popup.content)) {
      return i + 1;
    }
  }

  return "";

}
