import React from "react";

import sAction from 'sAction'

export default class Image extends React.PureComponent {

    handleClick(e) {
        const target = e.target
        if(target.classList.contains("icon-upload")){
            this.uploadImage()
        } else if (target.classList.contains("icon-deleteIcon")) {
            this.deleteImage();
        }else{
            sAction.popup("image",{url:this.imgUrl()})
        }
    }
    imgUrl() {
        const picture = this.props.picture;

        var value = "img/no_img.png"

        if (picture){
            var value = sAction.param.files+"image/"+ picture
        }

        return value;
    }
    uploadImage() {
        sAction.processFileInput({ multiple: false, way: this.props.way, accept: "image/png,image/jpeg,image/bmp,image/gif" }, file => {
            sAction.uploadImageFile(file, files => {
                const file = files.ids[0];
                const prefix = this.props.way.split("/")[0];
                sAction.dsClear();
                // deleting new image on leave without saving
                sAction.dsAdd('add', prefix+"/imageActions/deleteOnLeave", file["name"]);
                // deleting of overwritten image on save
                if (this.props.picture) {
                    sAction.dsAdd('add', prefix+"/imageActions/deleteOnSave", this.props.picture);
                }
                sAction.dsProcess();
                this.props.onChange(file["name"]);
            });
        });
    }

    deleteImage = () => {
        sAction.dsClear();
        sAction.dsAdd('add', this.props.way.split("/")[0]+"/imageActions/deleteOnSave", this.props.picture);
        sAction.dsAdd('set', this.props.way + "/picture", '');
        sAction.dsProcess();
    }

    render() {
        const imgUrl = this.imgUrl()
        let deleteIcon
        if (imgUrl !== "img/no_img.png") {
            deleteIcon =  <div className="imageFieldIcon icon-deleteIcon"/>
        }

        return (
          <div className="imageContainer">
              <img src={imgUrl} />
              <div className="imageFieldHover" onClick={(e) => this.handleClick(e)}>
                  <div className="imageFieldIcon icon-upload"></div>
                  {deleteIcon}
              </div>
          </div>
        )
    }
}