import React from "react";
import PureComponent from "../pure";

import ListViewTable2 from "./listViewTable2";
import ListViewMenu from "./ListViewMenu";
import ViewPanelMenu from "../viewPanelMenu/viewPanelMenu";
import sAction from "sAction";

class List extends PureComponent {
  render() {
    const data = this.props.data;
    const prefix = this.props.way;
    const vName = sAction.app_strings.moduleList[data.modul];
    const module = this.props.module;
    let actionPanel = null;

    // pokud zmacknou esc, schovat pravy panel
    document.body.addEventListener('keyup', function(evt) {
      if(evt.code === "Escape"){
        sAction.closeRightPanel();
      }
    });
    // obnova dat pri nechtenem / nahlem ukonceni
    sAction.setStorage('stopChanges', "0")
    var uId = sAction.dataGet('conf/user/id');
    var listModule = sAction.dataGet('view/modul');

    var changes = sAction.getStorage('changes-'+listModule+'-'+uId);

    // existuje zaznam k obnove, ktery neni priliz stary?
    if (changes
      &&
      (
        changes.changes.fields && 
        Object.keys(changes.changes.fields).length !== 0
        ||
        (Object.keys(changes.changes.customData).length !== 0 && 
        changes.changes.customData && 
        changes.changes.customData.customLines &&
        Object.keys(changes.changes.customData.customLines).length !== 0)
      )
      &&
      (changes.expiration - Date.now() > 0)
      &&
      sAction.getStorage('stopChanges') !== 1
    ){
      // pokud ano, vyhodit pop-up
      sAction.popup('changesCheck',changes);
    }

    // end obnova

    return (
        <div
        className="pageContainer ListViewContainer"
        ref={this.table}
        onMouseMove={e => sAction.rowResizeMove(e)}
        onMouseUp={() => sAction.rowResizeStop()}
      >
        {data.type != "popup" && <ViewPanelMenu data={data} prefix={prefix} />}
        <div className="ListViewContainerBody">
          <div className="listViewheaderBox">
            <div className="listViewheaderBoxContainer">
            <ListViewMenu data={data} prefix={prefix} />
            </div>
          </div>
          <ListViewTable2 data={data} parent={this} prefix={prefix} />
        </div>
      </div>
    );
  }
}
export default List;
