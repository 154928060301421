import React from "react";
import DefaultRelate from '../../defaultFields/Relate/Relate';
import  sAction  from "sAction";

export default class Relate extends DefaultRelate {

	cancel() {
		const way = this.props.way;
		const rowIndex = this.props.rowIndex;
		const recordIndex = this.props.recordIndex;
		const path = `${way}/rows/${rowIndex}/records/${recordIndex}/actEdit`;
		sAction.dataSet(path, false);
		sAction.afterLoadListView(this.props.way);
	}

	saveField(data) {
		const way = this.props.way;
		const value = data.value.id;
		const fieldName = data.fieldName || null;
		const name = this.props.data.def.get('id_name');
		const module = this.props.data.editModule;
		const id = this.props.data.recordId;
		if (value != this.defaultValue) {
			sAction.saveCellField({ way, value, name, module, id, fieldName });
		} else {
			sAction.blurCellField({ way, id });
		}
		sAction.afterLoadListView(this.props.way);	
	}

}
