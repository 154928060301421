export default function getStorage(name){
    var value = null;
    if (window.localStorage) {
        value = localStorage.getItem(name);
       
    }else{
        value = localStorage.getItem(name);
    }
    try{
        value = JSON.parse(value)
        return value;
    }catch(e){
        return value
    }
}