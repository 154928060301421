import DefaultVarchar from '../../defaultFields/varchar/varchar';
import  sAction  from "sAction";

export default class Varchar extends DefaultVarchar {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}

}
