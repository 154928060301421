import DefaultMultienum from "../../defaultFields/Multienum/Multienum";
import  sAction  from "sAction";

export default class Multienum extends DefaultMultienum {

  saveField(data) {
    sAction.saveField(data);
  }

}
