import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import InputText from "../../formElements/InputText";
import { MenuItem } from "@material-ui/core";
import TabContainer from "../../formElements/TabContainer";

export default class SearchPlaceDropdown extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            filter: '',
            activeTab: 0,
        };
        this.input = React.createRef();
    }
    waitForSearch(e) {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
        var self = this;
        this.interval = setInterval(() => {
            clearInterval(self.interval);
            this.searchAddress();
        }, 300);
    }
    searchByCity() {
        var string = this.input.current.value;
        this.setState({ loaded: false, filter: string });
        if (string.length < 3) {
            return;
        }
        sAction.searchPlaces(string, 'obec', data => {
            this.setState({ loaded: true, options: data });
        });
    }
    searchByPSC() {
        var string = this.input.current.value;
        this.setState({ loaded: false, filter: string });
        if (string.length != 5) {
            return;
        }
        if (string.length != 5) {
            return;
        }    
        sAction.searchPlaces(string, 'psc', data => {
            console.log('DANIEL', data);
            
            if (data && data.lat && data.lon) {
                this.setCoordinates(data.lat, data.lon);
                this.setState({ loaded: true, options: [data] });
            } else {
                this.setState({ loaded: true, options: [] });
            }
        });
    }
    searchAddress(inputValue = null, activeTab = null) {
        var tab = activeTab !== null ? activeTab : this.state.activeTab;
        if (tab == 0) {
            this.searchByCity();
        } else {
            this.searchByPSC();
        }
    }
    getDropDownMenu(options) {
        var items = [];
        options.forEach((address, key) => {
            items.push(
                <MenuItem
                    onClick={e => this.setCoordinates(address.lat, address.lon)}
                    className=''
                    key={key}
                >
                    {address.address + ' '} <span className=''> ({sAction.translate('LBL_PSC')}: {address.psc})</span>
                </MenuItem>
            );
        });
        return items;
    }
    setCoordinates(lat, lon) {
        this.props.onSelect({ y: lat, x: lon });
    }
    openSearch() {
        this.setState({ open: true });
    }

    changeActivetab(value) {
        this.setState({ activeTab: value });
        this.searchAddress(null, value);
    }
    handleClearButton() {
        this.input.current.value = '';
        this.setState({filter: ''});
    }
    componentDidUpdate() {
        this.input.current ? this.input.current.focus() : null;
    }

    render() {
        const filter = this.props.filter;
        var options = null;
        var dropdown = null;
        var tabs = ['Obec', 'PSC']

        if (this.state.activeTab == 0) {
            if (this.state.filter.length >= 3) {
                if (this.state.loaded) {
                    if (this.state.options.length > 0) {
                        dropdown = (<div className='openStreetMapDropDownList'>{this.getDropDownMenu(this.state.options)}</div>);
                    } else {
                        dropdown = (<div className='openStreetMapDropDownEmpty'>{sAction.translate('LBL_ADDRESS_NOT_FOUND')}</div>);
                    }
                } else {
                    dropdown = (<div className='openStreetMapDropDownEmpty'>{sAction.translate('LBL_LOADING')}</div>);
                }
            }
        } else {
            if (this.state.filter.length == 5) {
                if (this.state.loaded) {
                    if (this.state.options && this.state.options.length == 0) {
                        dropdown = (<div className='openStreetMapDropDownEmpty'>{sAction.translate('LBL_ADDRESS_NOT_FOUND')}</div>);
                    }
                } else {
                    dropdown = (<div className='openStreetMapDropDownEmpty'>{sAction.translate('LBL_LOADING')}</div>);
                }
            }
        }
        var searchWindow = (
            <div className='openStreetMapDropDown'>
                <TabContainer
                    tabs={tabs}
                    onChange={(e, value) => this.changeActivetab(value)}
                    value={this.state.activeTab}
                />
                <div className='searchInput'>
                    <InputText
                        onChange={e => this.waitForSearch(e.target.value)}
                        label={sAction.translate('LBL_SEARCH_FOR')}
                        myRef={this.input}
                        autoFocus={true}
                    />
                    <span className="icon-closeIconLight clearButton" onClick={e => this.handleClearButton()}/>
                </div>
                {dropdown}
            </div>
        )

        return (
            <React.Fragment>
                <div className='openStreetMapMenuGroup'>
                    {searchWindow}
                </div>
            </React.Fragment>
        );
    }
}
