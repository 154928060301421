import React from "react"
import PureComponent from "../../pure"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import  sAction  from "sAction";
import EmailAssignField from "../../email/EmailAssignField"
import EmailRelateField from "../../email/EmailRelateField"
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel"

export default class EmailImportPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uid: props.uid,
      ieId: props.ieId,
      mbox: props.mbox,
      user_id: "",
      parent_id: "",
      parent_type: "",
      delete: "",
    };
  }

  handleImport = () => {
    sAction.load();
    sAction.rest.post("email/importEmail", this.state, messages => {
      sAction.popupHide();

      const message = messages.map((message, i) => "<p>"+message+"</p>").join("");
      sAction.alert(message, sAction.translate("LBL_IMPORT_EMAIL","Emails"));

      sAction.unLoad();
    });
  };

  handleAssign = returnData => {
    this.setState({
      user_id: returnData.id
    });
  };

  handleRelate = returnData => {
    this.setState({
      parent_id: returnData.id,
      parent_type: returnData.type
    });
  };

  render() {
    return (
      <div className="email__popup">
        {/*Importovat e-mail*/}
        <div className="acmPopupHeader">{sAction.translate("LBL_IMPORT_EMAIL","Emails")}</div>
        <React.Fragment>
          <EmailPopupActionPanel onSave={this.handleImport} />
          <div className="email__popup__items">
            <DialogContent>
              <DialogContentText />

              <EmailAssignField onChange={this.handleAssign} />
              <EmailRelateField onChange={this.handleRelate} />

            </DialogContent>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
