import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";

import Button from "../formElements/Button";
import TickBox from "../formElements/TickBox";
import Radio from "../formElements/Radio";
import EmailSettingsIePopup from "../popup/email/EmailSettingsIePopup";
import EmailSettingsOePopup from "../popup/email/EmailSettingsOePopup";
import Select from "../formElements/Select";

export default class EmailSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      account_list: [],
      outbound_account_list: [],
      foldersForSettings: {},
      signatures: {},
      signature: ""
    };
  }

  componentDidMount() {
    sAction.load();
    sAction.rest.post("email/getSettingsData", {}, data => {
      this.setState({ account_list: data.account_list });
      this.setState({ outbound_account_list: data.outbound_account_list });
      this.setState({ foldersForSettings: data.foldersForSettings });
      this.setState({ signatures: data.signatures, signature: data.signature });
      sAction.unLoad();
    }, false);
  }

  openIeEdit = id => {
    let smtps = [];
    this.state.outbound_account_list.map(smtp => {
      smtps.push({
        label: smtp.name + " - " + smtp.mail_smtpserver,
        value: smtp.id
      });
    });
    sAction.popup(
      <EmailSettingsIePopup id={id} smtps={smtps} onChange={() => {
        this.rerenderEmailClient();
      }} />
    );
  };

  handleDefaultOutbound = id => {
    sAction.load();
    sAction.rest.post("email/saveDefaultOutbound", { id }, data => {
      this.componentDidMount();
    });
  };

  handleVisibility = (a, checked) => {
    sAction.load();
    //setFolderViewSelection
    const selected = [];

    //TODO: uprava state

    if (!a.update_subscription) {
      //pokud je imap nebo skupina

      this.state.account_list.forEach(account => {
        if (account.is_active) selected.push(account.id);
      });
      const valueId = selected.indexOf(a.id);

      //pokud neni zaskrnuto a je v selected, odebere se
      if (!checked && valueId !== -1) {
        selected.splice(valueId, 1);

        //pokud je zaskrnuto a neni v selected, prida se
      } else if (checked && valueId === -1) {
        selected.push(a.id);
      }

      sAction.rest.post(
        "email/setFolderViewSelection",
        { ieIdShow: selected },
        data => {
          this.rerenderEmailClient();
        }
      );
    } else {
      //pokud je slozka skupina - auto import

      this.state.foldersForSettings.groupFolders.forEach(f => {
        if (f.id && f.id != a.id && f.selected == true) {
          selected.push(f.id);
        }
      });

      this.state.foldersForSettings.userFolders.forEach(f => {
        if (f.id && f.id != a.id) {
          selected.push(f.id);
        }
      });

      if (checked) selected.push(a.id);

      const subscriptions = selected.join("::");

      sAction.rest.post(
        "email/updateSubscriptions",
        { subscriptions },
        data => {
          this.rerenderEmailClient();
        }
      );
    }
  };

  openOeEdit = (id = undefined) => {
    sAction.popup(<EmailSettingsOePopup id={id} onChange={() => {
      this.rerenderEmailClient();
    }} />);
  };

  deleteIe = ie_id => {
    // Opravdu chcete smazat tento e-mailový účet?
    sAction.confrim(sAction.translate("LBL_EMAIL_IE_DELETE_CONFIRM"), () => {
      sAction.popupHide();
      sAction.load();
      sAction.rest.post(
        "email/deleteIeAccount",
        { ie_id, group_id: sAction.dataGet("conf/user/id") },
        data => {
          //TODO: if (data.is_error) alert(data.error_message);
          this.rerenderEmailClient();
        }
      );
    });
  };

  deleteOe = outbound_email => {
    // Opravdu chcete smazat tento e-mailový účet?
    sAction.confrim(sAction.translate("LBL_EMAIL_IE_DELETE_CONFIRM"), () => {
      sAction.popupHide();
      sAction.load();
      sAction.rest.post("email/deleteOutbound", { outbound_email }, data => {
        if (data.is_error) sAction.error(data.error_message);
        this.componentDidMount();
      });
    });
  };

  //ulozeni zmen v popupu
  savePopup = () => {};

  addSignature = () => {
    const data = { add: true, callback: this.updateSignatureList };
    sAction.popup("emailSignaturePopup", data);
  };

  editSignature = () => {
    const id = this.state.signature;

    const data = {
      add: false,
      id,
      name: this.state.signatures[id],
      callback: this.updateSignatureList
    };

    sAction.popup("emailSignaturePopup", data);
  };

  updateSignatureList = () => {
    sAction.rest.post(
      "email/getSignatures",
      {},
      ({ signatures, signature }) => {
        //pokud byl podpis smazan, ale je porad vybran v user preferences
        if (!(signature in signatures)) signature = "";
        this.setState({ signatures, signature });
        sAction.unLoad();
      }
    );
  };

  updateSignature = (id = this.state.signature) => {
    sAction.rest.post("email/updateSignature", { id }, data => {});
  };

  deleteSignature = () => {
    // Jste si jist, že chcete smazat tento podpis?
    sAction.confrim(
      sAction.translate("LBL_EMAIL_CONFIRM_DELETE_SIGNATURE"),
      () => {
        sAction.load();
        sAction.rest.post(
          "email/deleteSignature",
          { id: this.state.signature },
          signatures => {
            sAction.unLoad();
            this.setState({ signatures, signature: "" });
            sAction.popupHide();
          }
        );
      }
    );
  };

  handleSignatureChange = e => {
    const signature = e.target.value;
    this.updateSignature(signature);
    this.setState({ signature });
  };

  goToEmailTemplates = () => {
    window.location.hash = "#list/EmailTemplates";
  }

  rerenderEmailClient = () => {
    sAction.routeEmail();
    sAction.dsClear();
    sAction.dsAdd("set", this.props.way + "/layout", 1);
    sAction.dsAdd("set", this.props.way + "/view1", "settings");
    sAction.dsProcess();
    this.componentDidMount();
  }

  render() {
    const { signature, signatures } = this.state;
    const signaturesToSelect = [];
    signatures.forEachObject((label, value) => {
      signaturesToSelect.push({ label, value });
    });

    return (
      <div className="email__detail__body">
        {/*Podpisy*/}
        <br />
        <h4>{sAction.translate("LBL_EMAIL_SIGNATURES")}</h4>
        <div className="email__compose__field-row" style={{ maxWidth: 400 }}>
          <div className="email__compose__field-row__input">
            <Select
              options={signaturesToSelect}
              onChange={this.handleSignatureChange}
              name="signature"
              value={signature}
              // label="Výchozí podpis"
              label={sAction.translate(
                "LBL_EMAIL_DEFUALT_SIGNATURE",
                "Emails"
              )}
              //label={sAction.translate("LBL_NONE")}
            />
          </div>
          <div className="email__compose__field-row__button">
            {!!signature && (
              <React.Fragment>
                <Button onClick={this.editSignature} className="stillHover">
                  {/*Upravit*/}
                  {sAction.translate("LBL_BUTTON_EDIT", "Emails")}
                </Button>
                <Button onClick={this.deleteSignature} className="stillHover">
                  {/*Smazat*/}
                  {sAction.translate("LBL_DELETE_RECORD")}
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
        <br />
        <Button onClick={this.addSignature} className="stillHover">
          {/*Přidat*/}
          {sAction.translate("LBL_ADD_BUTTON")}
        </Button>

        <br />

        {/* E-mailové šablony */}
        <h4>{sAction.translate("LBL_EMAIL_TEMPLATE_LIST","Emails")}</h4>
        <br />
        <Button onClick={this.goToEmailTemplates} className="stillHover">
          {/* Spravovat e-mailové šablony */}
          {sAction.translate("LBL_EMAIL_TEMPLATE_LIST_MANAGE","Emails")}
        </Button>
        <br />

        <br />
        {/*E-mailové účty*/}
        <h4>{sAction.translate("LBL_EMAIL_SETTINGS_ACCOUNTS")}</h4>

        {/*Nastavte e-mailové účty aby jste mohli vidět příchozí poštu.*/}
        <p>{sAction.translate("LBL_EMAIL_ACCOUNTS_SUBTITLE", "Emails")}</p>

        <table className="email__table">
          <thead>
          <tr>
            {/*Jméno e-mailového účtu*/}
            <th>{sAction.translate("LBL_EMAIL_SETTINGS_NAME").split("(")[0]}</th>
            {/*Adresa e-mailového serveru*/}
            <th>{sAction.translate("LBL_SERVER_URL", "InboundEmail").split("(")[0]}</th>
            {/*Aktivní*/}
            <th>
              {sAction.translate("LBL_STATUS_ACTIVE", "InboundEmail")}
            </th>
            {/*Výchozí*/}
            <th>{sAction.translate("LBL_EMAIL_DEFAULTS","InboundEmail")}</th>
            {/*Typ*/}
            <th colSpan={4}>{sAction.translate("LBL_LIST_TYPE","InboundEmail")}</th>
          </tr>
          </thead>
          <tbody>
          {this.state.account_list.length !== 0 ?
          this.state.account_list.map(a => (
            <tr key={a.id}>
              <td>{a.name}</td>
              <td>{a.server_url}</td>
              <td>
                <TickBox
                  checked={a.is_active}
                  onChange={checked => this.handleVisibility(a, checked)}
                />
              </td>
              <td>
                {!a.is_group && (
                  <Radio
                    checked={a.is_default}
                    onChange={() => this.handleDefaultOutbound(a.id)}
                  />
                )}
              </td>
              <td>{a.type}</td>
              <td>
                {!a.is_group && (
                  <Button className="stillHover" onClick={() => this.openIeEdit(a.id)}>
                    {/*Upravit*/}
                    {sAction.translate("LBL_BUTTON_EDIT", "Emails")}
                  </Button>
                )}
              </td>
              <td>
                {!a.is_group && (
                  <Button className="stillHover" onClick={() => this.deleteIe(a.id)}>
                    {/*Smazat*/}
                    {sAction.translate("LBL_DELETE_RECORD")}
                  </Button>
                )}
              </td>
            </tr>
          )) : (
              <tr><td colSpan="8">{sAction.translate("LBL_NO_DATA")}</td></tr>
            )}
          </tbody>
          <tbody>
          <tr style={{backgroundColor: "#fff"}}>
            <td colSpan={8} style={{ padding: "10px 0 0 0"}}>
              <Button
                onClick={() => this.openIeEdit()}
                className="stillHover"
                data-test="add-ie"
              >
                {/*Vložit*/}
                {sAction.translate("LBL_ADD_INBOUND_ACCOUNT", "Emails")}
              </Button>

              {/*Odchozí SMTP mail servery*/}
              <br />
              <h4>
                {sAction.translate(
                  "LBL_EMAIL_SETTINGS_OUTBOUND_ACCOUNTS",
                  "Emails"
                )}
              </h4>

              <p>
                {sAction.translate("LBL_EMAIL_ACCOUNTS_OUTBOUND_SUBTITLE")}
              </p>
            </td>
          </tr>
          </tbody>

          <thead>
          <tr>
            {/*Jméno*/}
            <th>{sAction.translate("LBL_EMAIL_ACCOUNTS_NAME").split("(")[0]}</th>
            {/*SMTP server*/}
            <th colSpan={7}>
              {sAction.translate("LBL_EMAIL_ACCOUNTS_SMTPSERVER").split("(")[0]}
            </th>
          </tr>
          </thead>
          <tbody>
          {this.state.outbound_account_list.length !== 0 ?
          this.state.outbound_account_list.map(a => (
            <tr key={a.id}>
              <td>{a.name}</td>
              <td colSpan={4}>{a.mail_smtpserver}</td>
              <td>
                {!a.is_group && a.is_editable && (
                  <Button className="stillHover" onClick={() => this.openOeEdit(a.id)}>
                    {/*Upravit*/}
                    {sAction.translate("LBL_BUTTON_EDIT", "Emails")}
                  </Button>
                )}
              </td>
              <td>
                {!a.is_group && a.name !== "system" && (
                  <Button className="stillHover" onClick={() => this.deleteOe(a.id)}>
                    {/*Smazat*/}
                    {sAction.translate("LBL_DELETE_RECORD")}
                  </Button>
                )}
              </td>
              <td>
                {a.errors}
              </td>
            </tr>
          )) : (
            <tr><td colSpan="5">{sAction.translate("LBL_NO_DATA")}</td></tr>
            )}
          </tbody>
        </table>

        {/*Vložit*/}
        <Button onClick={() => this.openOeEdit()} className="stillHover" data-test="add-oe">
          {sAction.translate("LBL_ADD_OUTBOUND_ACCOUNT", "Emails")}
        </Button>
        <br />
        <br />
      </div>
    );
  }
}
