export default function sendMassEmail(params) {
  this.load();
  this.rest.post("email/getEmailByBeanId", { bean_id: params.id, module: params.module, filter: params.filter }, returnData => {
    this.unLoad();
    if (!returnData) {
      // Nepodařilo se spojit se serverem nebo získat data, opakujte prosím akci.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
    } else if (typeof returnData !== "object" || !("emails" in returnData)) {
      // Data nebyla vrácena ve správném formátu. Výstup:
      this.error(this.translate("ERR_SEND_MASS_EMAIL_FORMAT", "Emails") + returnData);
    } else if (!returnData.emails.length) {
      // U vybraných firem nebyly nalezeny žádné e-maily.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_EMAILS", "Emails"));
    } else {
      
      let showEmailPopup = (returnData) => {
        var data = {
          to_addrs: returnData.emails.join(", "),
          send_individually: true,
        };
        this.openEmailPopup(data);
      }
      
      if (!returnData.not_found.length) {
        showEmailPopup(returnData);
        return true;
      }
      
      // prace s nenalezenymi emaily - zobrazeni popupu
      
      var links = "";
  
      returnData.not_found.forEach(f => {
        links += '<a target="_blank" href="#detail/' + f.module + '/' + f.id + '">' + f.name + "</a><br>";
      });
  
      this.confrim(
        // Následující záznamy nemají vyplněn žádný e-mail, chcete pokračovat?
        this.translate("ERR_SEND_MASS_EMAIL_MISSING_EMAILS", "Emails") + "<br><br>" + links,
        () => {
          this.popupHide();
          this.load();
          // zeptame se znovu restu skrz chybejici emaily... je mozne, ze uz byly dovyplneny
          this.rest.post("email/getEmailByBeanId", {bean_id: params.id, module: params.module, filter: params.filter}, returnData2 => {
            this.unLoad();
            showEmailPopup(returnData2);
          });
        }
      );
    }
  });
}
