import React from "react";
import PureComponent from "../pure";
import CustomReport from '../CustomReport/Report'

import  sAction  from "sAction";

export default class DetailDashboard extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {data,id,module,way} = this.props;
    var renderElement = null;
    if(data.get("type") === "customReport"){
        renderElement = <CustomReport widget={true} data={data} way={way} reportId={data.get("reportId")} paramData={{recordId:id,isDashboard:true}} />
    }
    return (
    <div className="detailDashboardContainer"> 
    {renderElement}
    </div>
    );
  }
}
