export default function getRelateFilterOptions() {
    return [
        {
            value: "eq",
            label: this.translate("LBL_EQUALS"),
            disabledInput: false
        },
        {
            value: "neq",
            label: this.translate("LBL_NOT_EQUAL"),
            disabledInput: false
        },
        {
            value: "cont",
            label: this.translate("LBL_CONTAINS"),
            disabledInput: false
        },
        {
            value: "ncont",
            label: this.translate("LBL_NOT_CONTAINS"),
            disabledInput: false
        },
        {
            value: "start",
            label: this.translate("LBL_STARTS"),
            disabledInput: false
        },
        {
            value: "nstart",
            label: this.translate("LBL_NOT_STARTS"),
            disabledInput: false
        },
        {
            value: "end",
            label: this.translate("LBL_ENDS"),
            disabledInput: false
        },
        {
            value: "nend",
            label: this.translate("LBL_NOT_ENDS"),
            disabledInput: false
        },
        {
            value: "null",
            label: this.translate("LBL_FIELD_NULL"),
            disabledInput: true
        },
        {
            value: "nnull",
            label: this.translate("LBL_FIELD_NOT_NULL"),
            disabledInput: true
        }
    ];
}