import React, { Component } from 'react'
import PureComponent from '../pure'

import  sAction  from "sAction";

import ListViewTable2 from "../list/listViewTable2";

class GlobalSearchListContainer extends PureComponent{

    constructor(props) {
        super(props);
        this.listView = React.createRef();
      }

    componentDidUpdate() {
        if(this.props.data.records.size == 0){
            return;
        }
        const listView = this.listView.current;
        const actTable = listView.querySelector(".newListView");
        sAction.repairListHeight(actTable);
      }

    render(){

        const list = this.props.data;
        const way = this.props.way;
        const ind = this.props.ind;

        return (
            <div className="globalSearchListContainer" 
                ref={this.listView}
                key={ind}>
                <div className="moduleTitle">{sAction.translate("LBL_MODULE_NAME", list.modul)}</div>
                <ListViewTable2 data={list} parent={this} prefix={way+"/results/"+ind} />
            </div>
        )
    }

}

export default GlobalSearchListContainer