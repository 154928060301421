import React from "react";
import PureComponent from "../../pure";

import TabContainer from "../../formElements/TabContainer";

import ReportWindowContent from "./ReportWindowContent";
import ReportWindowColumns from "./ReportWindowColumns";
import ReportWindowList from "./ReportWindowList";
import ReportWindowOptions from "./ReportWindowOptions";
import ReportWindowSave from "./ReportWindowSave";
import ReportWindowExport from "./ReportWindowExport";

import  sAction  from "sAction";

class ReportWindow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
    this.changes = {
      changedName: null,
      category: null,
      description: null,
      showValues: null,
      selectedUsers: [],
      selectedRoles: [],
      activeTab: 0
    }
  }
  componentWillUnmount() {
    //  sAction.dsClear();
    sAction.dataSet("reportWindow", {}, "reportWindow");
    //  sAction.dsProcess();
  }
  tabChange(tabIndex) {
    if (tabIndex !== 0) {
      const filter = sAction.reportWindowCreateFilter();
      if (sAction.dataGet("reportWindow/module") != null) {
        if(tabIndex == 3){
          sAction.reportWindowCreate();
        }
        this.setState({
          activeTab: tabIndex
        });
        sAction.dataSet("reportWindow/filterAlert/alert",false)
      }else{
        sAction.dataSet("reportWindow/filterAlert",{alert:true,message:sAction.translate("LBL_REPORT_INVALID_FILTER")})
      }
    } else {
      this.setState({
        activeTab: tabIndex
      });
    }
  }
  save() {}

  render() {
    var data = this.props.data;
    data.changes = this.changes;
    var content = null;
    if (this.state.activeTab == 0) {
      content = <ReportWindowContent way={"selectFields"} data={data} />;
    } else if (this.state.activeTab == 1) {
      content = <ReportWindowColumns data={data} way="reportWindow" />;
    } else if (this.state.activeTab == 2) {
      content = <ReportWindowOptions data={data} />;
    } else if (this.state.activeTab == 3) {
      content = <ReportWindowList data={data} listData={this.props.listData} />;
    } else if (this.state.activeTab == 4) {
      content = <ReportWindowSave data={data} />;
    } else if(this.state.activeTab == 5){
      content = <ReportWindowExport data={data} close={() => this.props.close()} />
    }

    return (
      <div className="reportWindow">
        <div className="reportWindowHeader">
          {/* <div className="reportWindowHeaderButtons">
            <div
              onClick={() => sAction.reportWindowCreate()}
              className="reportWindowHeaderButton"
            >
              <div className="buttonImage resultsIcon" />
              <div>Výsledek</div>
            </div>
            <div
              onClick={() => this.tabChange("save")}
              className="reportWindowHeaderButton"
            >
              <div className="buttonImage saveIcon" /><div>Uložit</div>
            </div>
          </div>
          */}
          <TabContainer
            value={this.state.activeTab === "save" ? 0 : this.state.activeTab}
            tabs={[
              sAction.translate("LBL_REPORT_TAB_FILTER"),
              sAction.translate("LBL_REPORT_TAB_COLUMNS"),
              sAction.translate("LBL_REPORT_TAB_ORDER"),
              sAction.translate("LBL_REPORT_TAB_RESULT"),
              sAction.translate("LBL_REPORT_TAB_DISTRIBUTION"),
              sAction.translate("LBL_REPORT_TAB_EXPORT")
            ]}
            onChange={(e, value) => this.tabChange(value)}
          />
        </div>
        {content}
      </div>
    );
  }
}
export default ReportWindow;
