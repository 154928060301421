export default function saveEditedDetail(prefix,cancelEditMode = true){

    const data = this.dataGet(prefix)
    const tabs = data.get("tabs").toJS()
    const hidden = data.get("hiddenFields").toJS()
    const module = data.get("module")
    const customVnames = data.get("editedFieldsVname")
    const type = data.get("type")
    const params = {
        tabs,
        hidden,
        module,
        customVnames,
        lang: this.dataGet("conf/language"),
        type
    }
    this.load();
    this.rest.post("saveDetailLayout", params, returnData => {
        this.unLoad()
        if (returnData.status) {
            if (cancelEditMode) {
                this.dataSet(prefix + "/mode", "detail")
            }
        } else {
            this.alert(this.translate(returnData.errorMessage.text));
        }
    })
}
