/**
 * Vraci int cislo aktualniho popupu nebo prazdny string, pokud se zadny popup nezobrazuje nebo se zobrazuje jen 1...
 * @returns {string|number}
 */

export default function getPopupId(){
  let maxId = "";

  for (let i = 1; i <= 5; i++) {
    let popupId = (i === 1) ? "" : i;

    let popup = this.dataGet("conf/popup" + popupId + "/show");
    if (popup) {
      maxId = popupId;
    }
  }

  return maxId;
}
