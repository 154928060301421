import $ from 'jquery';
export default function calSaveActivity(record, fields, params, callback, callbackFail = null){

    var req = {
        "record" : record,
        "fields" : fields,
        "params" : params
    }

    var self = this;
    self.rest.post("saveActivity", req, function(data){
        if(data.retCode == "OK"){
            callback(self);
        }
        else{
            self.error(data.errMsg);
            if(callbackFail !== null){
                callbackFail();
            }
        }
        
    });
    
}