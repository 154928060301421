
import React from 'react'
import PureComponent from '../pure'

import SweetSpotItem from './SweetSpotItem'

import  sAction  from "sAction";

class SweetSpotResultContainer extends PureComponent{
    render(){
        const data = this.props.data;
        var results = [];
        const max = this.props.maxRecords;
        let count = 0;
        data.forEach((item, ind) => {
            if(count >= max){
                return;
            }
            let itemClass = "";
            if(ind === this.props.arrowIndex){
                itemClass += " arrowSelect";
              }
              count++;
            results.push(<SweetSpotItem pomClass={itemClass} key={ind} data={item} />);
        });
        return (<div className="SweetSpotResultContainer">
        <div className="SweetSpotResultTitle">
        {sAction.translate("LBL_SWEETSPOT_ACTION")}
        </div>
        {results}
        </div>);
    }
}
export default SweetSpotResultContainer