import { Map, List } from "immutable";
import { DataConstructors } from "../../../DataConstructors";
export default function processCustomData(data){
    var customData = null;
    const module = data.module;
    if (data.customData === null) {
      return customData;
    }

    if(data.customType == "leadConvert"){
        var related_id = null;
        var related_name = null;

        if(data.customData.related){
            related_id = data.customData.related.record,
            related_name = data.customData.related.name
        }
        customData = new DataConstructors["leadConvertCustomData"]({
            beanMark: data.customData.beanMark,
            checked: data.customData.checked,
            required : data.customData.required,
            boundable : data.customData.boundable,
            related_id : related_id,
            related_name : related_name,
            candidates : List(data.customData.candidates),
            allowCopyActivities: data.customData.allowCopyActivities || false,
        });
    } else if(module == 'acm_coripoReports'){
          customData = new DataConstructors['permisisonAccess']({
              roles: data.customData.roles,
              users: data.customData.users,
              allowAll: data.customData.allowAll,
          });
      }
      else if(module == "Quotes"){
        let groups = [];

        data.customData.productData.groups.forEach(function(group) {
          let products = [];
          
          group.lines.forEach(function(line){
            line.cost_price = parseFloat(line.cost_price);
            line.list_price = parseFloat(line.list_price);
            line.discount_price = parseFloat(line.discount_price);
            line.discount_amount = parseFloat(line.discount_amount);
            products.push(new DataConstructors["product"](line));
          });

          group.lines2.forEach(function(line){
            //new DataConstructors["productField"];

          });


          
          groups.push(new DataConstructors["productGroup"]({
            id: group.id,
            name: group.name,
            type: group.type,
            description: group.description,
            bundle_stage: group.bundle_stage,
            cenotvorba: group.cenotvorba,
            cenotvorba_discount: group.cenotvorba_discount,
            cenotvorba_discount_select: group.cenotvorba_discount_select,
            lines: List(products)
          }));
        });

        let dan_sazba = "";
        if(data.fields && data.fields.danova_sazba !== undefined){
          dan_sazba = data.fields.danova_sazba.value;
        }

        customData = new Map({
          productData: new DataConstructors["productData"]({
            groups: List(groups),
            shipping : data.customData.productData.shipping,
            currency_id : data.customData.currency_id,
            cenotvorba : data.customData.productData.cenotvorba,
            dph: dan_sazba
          }),
          productDataBackup: new DataConstructors["productData"]({
            groups: List(groups),
            shipping: data.customData.productData.shipping,
            cenotvorba : data.customData.productData.cenotvorba,
            currency_id : data.customData.currency_id,
            dph: dan_sazba
          }),
        })   
      }
      else if(module == "Opportunities" && data.customData.productData !== undefined){
        let products = [];
        data.customData.productData.lines.forEach(function(line){
          line.discount_price = parseFloat(line.discount_price);
          products.push(new DataConstructors["product"](line));
        });

        customData = new Map({
          productData: new Map({lines : List(products)}),
          productDataBackup: new Map({lines : List(products)}),
        })

      } else if (module == "ACLRoles" || module == "Users") {
        customData = new Map({
          html: "",
          recordId: data.customData.recordId,
          iframeName: data.customData.iframeName
        });
      } else if (data.customData && data.customData.detailViewLines) {
        const detailViewLines = data.customData.detailViewLines;
        detailViewLines.defs = new List(detailViewLines.defs.map(def => new Map(def)));
        detailViewLines.defaults = new Map(detailViewLines.defaults);
        detailViewLines.records = new List(detailViewLines.records.map(record => new Map(record)));
        detailViewLines.sortedBy = new Map({ name: null, desc: false });
        customData = { detailViewLines };
      }
      // Muze byt kdekoli z jakyhokoli duvodu
      if (data.customData && data.customData.customLines) {
          let customLines = data.customData.customLines;
          if (!customLines?.lines) {
              customLines.lines = [];
          }
          customData = { customLines };
      }

      return customData;
}
