import React from "react";
import PureComponent from "../../pure";

import Select from "../../formElements/Select";
import Checkbox from "../../formElements/CheckBox";
import InputText from "../../formElements/InputText";

import  sAction  from "sAction";

export default class ReportWindowOptions extends PureComponent {
  dataChange(type, field, module, key, value, relationship, recordName) {
    const data = this.props.data;
    sAction.dsClear();
    if (data.get(type).get(module + "." + field) == undefined) {
      var newData = {};
      newData[key] = value;
      newData["module"] = module;
      newData["field"] = field;
      newData["relationship"] = relationship;
      sAction.dsAdd(
        "set",
        "reportWindow/" + type + "/" + module + "." + field,
        newData,
        recordName
      );
    } else {
      sAction.dsAdd(
        "set",
        "reportWindow/" + type + "/" + module + "." + field + "/" + key + "",
        value
      );
      sAction.dsAdd(
        "set",
        "reportWindow/" + type + "/" + module + "." + field + "/module",
        module
      );
      sAction.dsAdd(
        "set",
        "reportWindow/" + type + "/" + module + "." + field + "/field",
        field
      );
      sAction.dsAdd(
        "set",
        "reportWindow/" + type + "/" + module + "." + field + "/relationship",
        relationship
      );
    }
    sAction.dsProcess();
  }

  render() {
    const data = this.props.data;

    var lines = [];
    if (data.columns != null) {
      const columns = data.columns.active;

      var prioritaSelect = [{ value: "", label: "" }];
      for (var i = 1; i <= columns.size; i++) {
        prioritaSelect.push({
          value: i,
          label: i
        });
      }

      var functionAllowed = false;
      data.groupBy.valueSeq().forEach(e => {
        if (e.active == true) {
          functionAllowed = true;
        }
      });

      columns.forEach((element, key) => {
        var vname = sAction.translate(
          element.def.get("vname"),
          element.def.get("fieldModule")
        );
        vname = vname.replace(":", "");
        const fieldModule = element.def.get("fieldModule");

        var orderData = data.orderBy.get(fieldModule + "." + element.name);
        var orderDirection = "";
        var orderPriority = "";
        var orderpriorityEdit = false;
        if (orderData != undefined) {
          orderDirection = orderData.direction;
          if (orderDirection != "") {
            orderpriorityEdit = true;
            orderPriority = orderData.priority;
          }
        }
        var group = data.groupBy.get(fieldModule + "." + element.name);
        var groupState = false;
        var groupPriority = "";
        var groupPriorityEdit = false;
        if (group != undefined) {
          groupState = group.active;
          if (groupState == true) {
            groupPriority = group.priority;
            groupPriorityEdit = true;
          }
        }

        var functionValue = "";
        var functionData = data.fieldFunction.get(
          fieldModule + "." + element.name
        );
        if (functionData != undefined) {
          functionValue = functionData.function;
        }

        var alterNameValue = "";
        var alternameData = data.alterName.get(
          fieldModule + "." + element.name
        );
        if (alternameData != undefined) {
          alterNameValue = alternameData.vname;
        }

        lines.push(
          <div key={key} className="reportWindowOptionLine">
            <div className="reportWindowOptionColumn">
              {vname} ({sAction.app_strings.moduleList[fieldModule]})
            </div>
            <div className="reportWindowOptionColumn atribute">
              <InputText
                defaultValue={alterNameValue}
                onBlur={e =>
                  this.dataChange(
                    "alterName",
                    element.name,
                    fieldModule,
                    "vname",
                    e.target.value,
                    element.def.get("relationship"),
                    "reportWindowAlterName"
                  )
                }
                className="withBorder"
              />
            </div>
            <div className="reportWindowOptionColumn atribute">
              <Select
                containerClassName="withBorder"
                value={orderDirection}
                options={[
                  { value: "", label: "" },
                  { value: "ASC", label: sAction.translate("LBL_ORDER_ASC") },
                  { value: "DESC", label: sAction.translate("LBL_ORDER_DESC") }
                ]}
                onChange={e =>
                  this.dataChange(
                    "orderBy",
                    element.name,
                    fieldModule,
                    "direction",
                    e.target.value,
                    element.def.get("relationship"),
                    "reportWindowOrderBy"
                  )
                }
              />
            </div>
            <div className="reportWindowOptionColumn atribute">
              <Select
                disabled={!orderpriorityEdit}
                containerClassName="withBorder"
                value={orderPriority}
                options={prioritaSelect}
                onChange={e =>
                  this.dataChange(
                    "orderBy",
                    element.name,
                    fieldModule,
                    "priority",
                    e.target.value,
                    element.def.get("relationship"),
                    "reportWindowOrderBy"
                  )
                }
              />
            </div>
            <div className="reportWindowOptionColumn atribute">
              <Checkbox
                defaultValue={groupState === true ? 1 : 0}
                onChange={e =>
                  this.dataChange(
                    "groupBy",
                    element.name,
                    fieldModule,
                    "active",
                    e,
                    element.def.get("relationship"),
                    "reportWindowGroupBy"
                  )
                }
              />
            </div>
            <div className="reportWindowOptionColumn atribute">
              <Select
                disabled={!groupPriorityEdit}
                containerClassName="withBorder"
                value={groupPriority}
                options={prioritaSelect}
                onChange={e =>
                  this.dataChange(
                    "groupBy",
                    element.name,
                    fieldModule,
                    "priority",
                    e.target.value,
                    element.def.get("relationship"),
                    "reportWindowGroupBy"
                  )
                }
              />
            </div>
            <div className="reportWindowOptionColumn atribute">
              <Select
                disabled={!functionAllowed}
                containerClassName="withBorder"
                value={functionValue}
                options={sAction.getEnumForSelect("reportFunctionListNumber")}
                onChange={e =>
                  this.dataChange(
                    "fieldFunction",
                    element.name,
                    fieldModule,
                    "function",
                    e.target.value,
                    element.def.get("relationship"),
                    "reportWindowFieldFunction"
                  )
                }
              />
            </div>
          </div>
        );
      });
    }

    return (
      <div className="reportWindowOptionContainer">
        <div className="reportWindowOptionTable">
          <div className="reportWindowOptionLine header">
            <div className="reportWindowOptionColumn">
              {sAction.translate("LBL_REPORT_FIELD")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_ALTER_NAME")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_ORDER")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_ORDER_PRIORITY")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_GROUP_BY")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_ORDER_PRIORITY")}
            </div>
            <div className="reportWindowOptionColumn atribute">
              {sAction.translate("LBL_REPORT_FUNCTION")}
            </div>
          </div>
          {lines}
        </div>
      </div>
    );
  }
}
