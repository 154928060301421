export default function readFile(params) {
  const prefix = params.prefix;
  const encoding = params.encoding ?? 'UTF-8';
  const file = this.temporaryData.file;
  const reader = new FileReader();
  reader.onload = (e) => {
    if (reader.result !== null) {
      this.dsAdd('set', prefix + '/fileContents', reader.result);
      this.dsProcess();
      this.parseFile({prefix: prefix});
    }
  }
  reader.readAsText(file[0], encoding);
}