import $ from 'jquery';


export default function calBulkActions(cssSel, action, param, reload = true){

    let ret = $(cssSel).fullCalendar(action, param);

    if(action == "getView" || action == "getDate"){
        return ret;
    }

    if(reload){
        let curDate = $(cssSel+":first").fullCalendar('getDate');

        let newStart = curDate.clone().subtract(40, "day").format("YYYY-MM-DD");

        if(newStart != this.calEvents.date_range.date_start){
            this.calEvents.date_range.date_start = newStart;
            this.calEvents.date_range.date_end = curDate.clone().add(40, "day").format("YYYY-MM-DD");
            this.calGetEvents(true);
        }
    }

}