import detailDefault from "../../detailDefault";

export default class detailEmailTemplates extends detailDefault {
  load(sAction, data) {
    //je admin? exit
    if (sAction.dataGet("conf/user/is_admin") == 1) {
      return;
    }

    //typ sablony == Admin
    if (['admin', 'system'].includes(sAction.dataGet(data.prefix+"/fields/type/value"))) {
      //zaznam nejde editovat
      sAction.dataSet(data.prefix+"/acl/edit", false);
    } else {
      //nezobrazuj v ciselniku polozku Admin...
      sAction.dataSet(data.prefix+"/fields/type/def/options", "emailTemplates_type_list_noadmin");
    }
  }

  update(sAction, data) {

  }

}
