import React from "react";
import  sAction  from "sAction";
export default function Relate(props) {

  const value = props.value.get("value");
  const id = props.value.get("id");
  const module = props.value.get("module");

  var element = null;
  if(sAction.hasAccess(module,"detail")){
    element = <a title={name} href={"#detail/" + module + "/" + id}>
    {value}
  </a>;
  }else{
    element = <span title={name}>
    {value}
  </span>
  }
  return (
    <div className={"newListViewLine " + props.extraClass} 
      onClick={(ev) => {props.onClick(ev)}}>
      {id == undefined || id == "" || id == null ? (
        value
      ) : (
        element
      )}
    </div>
  );
}
