export default function cenotvorbaChange(type, params){
    let invalidDiscounts = 0;
    
    this.dsClear();
    if(type == "quote"){
        const productData = this.dataGet(params.way+"/customData/productData");
        productData.groups.forEach(((group, gInd) => {
            this.dsAdd("set", params.way+"/customData/productData/groups/"+gInd+"/cenotvorba_discount", params.value);
            this.dsAdd("set", params.way+"/customData/productData/groups/"+gInd+"/cenotvorba_discount_select", params.discount_select);
            params.way = params.way+"/customData/productData/groups/"+gInd;
            invalidDiscounts += cenotvorbaChangeGroup(this, group, params);
        }));
    }
    else if(type == "group"){
        this.dsAdd("set", params.way+"/cenotvorba_discount", params.value);
        this.dsAdd("set", params.way+"/cenotvorba_discount_select", params.discount_select);

        const group = this.dataGet(params.way);
        invalidDiscounts += cenotvorbaChangeGroup(this, group, params);
    }
    
    this.dsAdd("set","view/changes/forceChange", true);
    this.dsProcess();
    
    if (invalidDiscounts) {
        let polozek = invalidDiscounts === 1 ? "položky" : "položek";
        this.error("U " + invalidDiscounts + " " + polozek + " nemohla být nastavena vyšší sleva než maximální povolená a byla tedy snížena na maximální možnou.");
    }
    
    return invalidDiscounts;
}

function cenotvorbaChangeGroup(sAction, group, params) {
    let invalidDiscounts = 0;
    group.lines.forEach((line, lInd) => {
        let max = parseFloat(line.max_discount);
        let maxReached = false;
        let maxDiscountPrice = line.list_price / 100 * max;
        
        if (params.checkMaxDiscountsOnly) {
            params.discount_select = line.discount_select;
            params.value = line.discount_amount;
        }
        
        let value = parseFloat(params.value) || 0;
        let discount_select = parseInt(params.discount_select) || 0;
        
        if (discount_select) { // zadava procenta
            maxReached = value > max;
        } else if (!discount_select) { // zadava ceny
            maxReached = value > maxDiscountPrice;
            max = maxDiscountPrice
        }
        
        if (maxReached) {
            invalidDiscounts++;
            sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_amount", 0);
            sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_amount", max);
            // sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_select", -1);
            sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_select", discount_select);
        } else if (!params.checkMaxDiscountsOnly) {
            // sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_amount", 0);
            sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_amount", value);
            // sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_select", -1);
            sAction.dsAdd("set", params.way + "/lines/" + lInd + "/discount_select", discount_select);
        }
    });
    
    return invalidDiscounts;
}