import DragAndDrop from "./DragAndDrop";
import  sAction  from "sAction";

export default class DragAndDropCoripo extends DragAndDrop {
  componentDidMount() {
    const dragHolderClass = this.props.containersClass.replace(".", "");
    const holders = document.getElementsByClassName(dragHolderClass);
    holders.forEachObject(holder => {
      if (holder.dataset.way == undefined) {
        console.log(
          "DRAG AND DROP WARNING \n Holder nemá nastavený atribut data-way",
          holder
        );
        this.dragAllow = false;
      } else {
        if (this.props.wayPrefix != undefined) {
        }
      }
    });
    const elementClass = this.props.elementsClass.replace(".", "");
    const elements = document.getElementsByClassName(elementClass);
    elements.forEachObject(holder => {
      if (holder.dataset.way == undefined) {
        console.log(
          "DRAG AND DROP WARNING \n Element nemá nastavený atribut data-way",
          holder
        );
        this.dragAllow = false;
      }
    });
  }
  moveStart(data) {
    var way = data.element.dataset.way;
    if (this.props.wayPrefix != undefined) {
      way = this.props.wayPrefix + way;
    }
    const test = sAction.dataGet(way);
    console.log(way)
    if (typeof test != "object") {
      if (holder.dataset.way == undefined) {
        console.log(
          "DRAG AND DROP WARNING \n Pravpěpodobně jsou špatně nastaveny atributy data-way",
          holder
        );
      }
    }
    sAction.dataSet(way + "/def/show", false);
  }
  moveEnd(dragData) {
    if (dragData.resize == true) {

      const element = dragData.element;
      const newWidth = element.clientWidth
      const parent = document.querySelector(".columnsLine");
      const parentWidth = parent.clientWidth;
      var percent = (newWidth/parentWidth)*100;
      var way = element.dataset.way;
      if (this.props.wayPrefix != undefined) {
      way = this.props.wayPrefix + way;
      }
      sAction.dataSet( way + "/def/width", percent+"%");


    } else {
      var data = {
        dragData: dragData,
        wayPrefix: this.props.wayPrefix
      };
      sAction.dragAndDropSave(data);
      this.afterEnd(data);
    }
  }
}
