import React, {Component, PureComponent} from 'react'
import ReactDOM from "react-dom"

export default class DropDown extends PureComponent {

  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
    this.state = {
      open: false,
    }

    // this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleDropDown(tthis) {
    if (tthis.state.open === false) {
      tthis.setState({open: true})
    } else {
      tthis.setState({open: false})
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.state.open) {
      const domNode = ReactDOM.findDOMNode(this)

      if (!domNode || !domNode.contains(event.target)) {
        this.setState({
          open: false,
        })
      }
    }
  }

  render() {
    let buttons = []
    let icon = ''

    this.props.options.buttons.forEachObject((val, key) => {
      var iconClass = ''
      if (val.text) {
        iconClass = 'dropDownIconSpacer'
      }
      icon = <i className={"icon-" + (val.icon ?? 'play' + " " + val.classes) + " " + iconClass}/>

      buttons.push(
        <li key={key}>
          <span onClick={val.function} title={val.title} className={'dropdown-content-row'}>
              {icon}
            <span>
              {val.text}
            </span>
          </span>
        </li>
      )
    })

    let open = ''
    if (this.state.open) {
      open = 'open'
    }

    let classesUL = 'dropdown-content ' + open
    return (
      <a className={'dropDownComponent icon-play'} onClick={e => this.toggleDropDown(this)}>
        <ul className={classesUL}>
          {buttons}
        </ul>
      </a>
    )
  }
}
