export default function customDataSortBy(way, def, module, desc = false) {
	const changesWay = way.replace("/customData", "/changes/customData");
	const recordsWay = way + "/records";
	const rows = this.dataGet(recordsWay);
	this.dsClear();
	rows.sort((a, b) => {
		const field = def.get("name");
		const type = def.get("type");
		const valA = ["decimal", "currency", "float"].includes(type) ? parseFloat(a.get(field)) : a.get(field);
		const valB = ["decimal", "currency", "float"].includes(type) ? parseFloat(b.get(field)) : b.get(field);
		if (desc) {
			return valA < valB ? 1 : -1;
		} else {
			return valA >= valB ? 1 : -1;
		}
	}).map((row) => row.get("id")).forEach((id, order_index) => {
		rows.forEach((row, index) => {
			if (row.get("id") == id) {
				this.dsAdd('set', changesWay + "/module", module);
				this.dsAdd('set', recordsWay + "/" + index + "/order_index_c", order_index);
				this.dsAdd('set', changesWay + "/records/" + index + "/id", id);
				this.dsAdd('set', changesWay + "/records/" + index + "/order_index_c", order_index);
			}
		})
	});
	this.dsAdd('set', way + "/sortedBy/name", def.get("name"));
	this.dsAdd('set', way + "/sortedBy/desc", desc);
	this.dsProcess();
}