export default function mergeSetAsDefault(way,index){
    
    const record = this.dataGet(way+"/records/"+index);
    
    this.dsClear();
    record.entrySeq().forEach(e => {
        if(e[1] != ""){
            this.dsAdd("set",way+"/newRecord/"+e[0],e[1]);
        }
    });
    this.dsProcess();

}