export default function removeChangesOnRecord(prefix) {
  this.dataSet(prefix + "/changes/fields", {});
  const recordData = this.dataGet(prefix);
  const module = recordData.module;
  if (module === "Quotes" || module === "Opportunities") {

    this.dataSet(
      prefix + "/customData/productData",
      this.dataGet(prefix + "/customData/productDataBackup")
    );
  }
  else if(module === "Meetings" || module === "Calls"){
    this.dataSet(
      prefix + "/customData/inviteesData",
      this.dataGet(prefix + "/customData/inviteesData")
    );
  }
  this.dsClear();
  this.load();
  const data = {
    id: recordData.id,
    module,
    prefix
  };

  this.routeDetailView(data);
}
