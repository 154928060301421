import detailDefault from "../../detailDefault";
import {translate} from "../../../action/exportConfig";

export default class detailacm_contracts_file_templates extends detailDefault {
  load(sAction, data) {

    const defaultText = sAction.translate('LBL_DEFAULT_TEXT','acm_contracts_file_templates')

    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/description/def/type", "HTMLText");
    sAction.dsAdd("set", data.prefix+"/fields/description/def/readonly", true);
    sAction.dsAdd("set", data.prefix+"/fields/description/value", defaultText);
    sAction.dsProcess();

  }

  update(sAction, data) {


  }

}

