export default function activitiesTimeLineParams(data){

    var params = {
        id:data.id,
        relModule:data.module,
        order:data.order,
        offset:0,
        limit:data.limit,
        filter:[],
    }

    var hiddenModules = [];
    data.activities.entrySeq().forEach(e => {
        if(e[1].get("show") == false){
            hiddenModules.push(e[0]); 
        }
    });

    let filter = [];

    if (data.status != null && data.status != "") {
        filter.push({ field: "status", type: "eq", value: data.status });
    }

    if (hiddenModules.length != 0) {
        filter.push({ field: "hiddenModules", value: hiddenModules });
    }

    if (filter) {
        params["filter"].push({ operator: "and", operands: [{ operator: "and", operands: filter }] });
    }

    return params;
}