import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import IconButton from "@material-ui/core/IconButton";

import Add from "@material-ui/icons/AddCircleOutline";
import Done from "@material-ui/icons/Done";
import TabContainer from "../../formElements/TabContainer";
import  sAction  from "sAction";
import PureComponent from "../../pure";
import Loader from "../../loader";
import InputText from "../../formElements/InputText";
import { EmailPopupButtons } from "../email/shared/EmailPopupButtons";

// defaultni material styly, pozdeji vymazu
const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    "&:before": {
      display: "none"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    display: "block"
  }
}))(MuiExpansionPanelDetails);

// parent src\components\home\HomeTabsSubmenu.js
export default class HomeWidgetAddPopup extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 0,
      activeModules: [],
      existing_views: [],
      loaded: false
    };
    this.nameRef = React.createRef();
    this.urlRef = React.createRef();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    this.findData();

    const existing_views = [];
    const data = sAction.dataGet("view");
    data.tabs.forEach((tab, i1) => {
      tab.cols.forEach((col, i2) => {
        col.rows.forEach((row, i3) => {
          // console.log("TAB " + i1, tab, "\nCOL " + i2, col, "\nROW " + i3, row);
          if (!row || !row.def) {
            return;
          }
          if (row.def.get("type") == "view") {
            existing_views.push(row.data.get("viewId"));
          }
        });
      });
    });
    this.setState({ existing_views });
  }

  findData = () => {
    this.setState({ loaded: false });

    sAction.rest.get("home/getwidgets", data => {
      if (!this._isMounted) return;

      this.setState({ data, loaded: true });
    });
  };

  changeActiveTab = newTab => {
    if (newTab == this.state.activeTab) {
      return;
    }

    this.setState({
      activeTab: newTab,
      activeModules: []
    });
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  setActiveModule = id => {
    let a = [...this.state.activeModules];
    if (a.indexOf(id) == -1) {
      a.push(id);
    } else {
      a = a.filter(a => a !== id);
    }
    this.setState({ activeModules: a });
  };

  addWidget = data => {
    const ev = this.state.existing_views;

    sAction.homeWidgetAdd(data);
    const existing_views = [...ev, data.id];
    this.setState({ existing_views });
  };

  deleteWidget = viewId => {
    const existing_views = [];
    const data = sAction.dataGet("view");
    data.tabs.forEach((tab, i1) => {
      tab.cols.forEach((col, i2) => {
        col.rows.forEach((row, i3) => {
          // console.log("TAB " + i1, tab, "\nCOL " + i2, col, "\nROW " + i3, row);
          if (!row || !row.def) {
            return;
          }
          if (["view", "customReport", "calendar"].includes(row.def.get("type"))) {
            if (row.data.get("viewId") == viewId) {
              sAction.dataDelete(
                "view/tabs/" + i1 + "/cols/" + i2 + "/rows",
                i3
              );
            } else {
              existing_views.push(row.data.get("viewId"));
            }
          }
        });
      });
    });
    this.setState({ existing_views });
  };

  savePopup = () => {
    const url = this.urlRef.current.value;
    if (!url) {
      return;
    }
    let name = this.nameRef.current.value;
    if (!name) {
      name = url.trim().replace(/(https?:\/\/(www\.)?)|(\/.*)/g, "");
      name = name.charAt(0).toUpperCase() + name.slice(1);
    }

    const data = {
      name,
      url,
      type: "iframe"
    }
    if (url) {
      sAction.homeWidgetAdd(data);
    }

  }

  getIframeContent = () => {

    return (
      <React.Fragment>
      <table className="w100">
        <tbody>
        <tr>
          <th>{sAction.translate("LBL_DASHLET_OPT_TITLE", "Home")}</th>
          <td>
            <InputText
              autoFocus
              inputRef={this.nameRef}
              id="name"
              className="withBorder"
              type="text"
              defaultValue={this.props.name}
              fullWidth
              onKeyUp={(ev) => {
                if (ev.key === "Enter") {
                  this.savePopup();
                  ev.preventDefault();
                }
              }}
            />
          </td>
        </tr>
        <tr>
          <th>URL</th>
          <td>
            <InputText
              autoFocus
              inputRef={this.urlRef}
              id="url"
              className="withBorder"
              type="text"
              defaultValue={this.props.url}
              fullWidth
              onKeyUp={(ev) => {
                if (ev.key === "Enter") {
                  this.savePopup();
                  ev.preventDefault();
                }
              }}
            />
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <EmailPopupButtons onSave={this.savePopup} />
      </React.Fragment>
    );
  }

  render() {
    const { data, activeTab, loaded, existing_views } = this.state;
    if (!loaded) return <Loader />;

    const tabs = [
      sAction.translate("LBL_REPORTS", "Home"), // Reporty
      sAction.translate("LBL_CHARTS", "Home"), // Grafy
      sAction.translate("LBL_CUSTOM_REPORTS"), // Reporty na míru
      "Web" // iframe na libovlnou adresu
    ];

    const renderMenu = (
      <TabContainer
        onChange={(e, value) => this.changeActiveTab(value)}
        value={activeTab}
        tabs={tabs}
      />
    );

    let content = "";
    if (loaded) {
      let category;
      if (activeTab == 0) category = "report";
      else if (activeTab == 1) category = "chart";
      else if (activeTab == 2) category = "custom";
      else if (activeTab == 3) category = "iframe";

      // const data = data[category];

      if (category === "iframe") {
        content = this.getIframeContent();
      } else if (data[category] === undefined) {
        // Nenalezena žádná data.
        content = sAction.translate("LBL_NO_DATA_FOUND", "Home");
      } else {
        content = Object.keys(data[category]).map(module_name => (
          <div key={module_name}>
            {/* nazev modulu - Campaigns, Leads... */}
            <ExpansionPanel
              expanded={this.state.activeModules.indexOf(module_name) !== -1}
              onChange={() => this.setActiveModule(module_name)}
            >
              <ExpansionPanelSummary>
                <strong>
                  {sAction.app_strings.moduleList[module_name]} (
                  {data[category][module_name].length})
                </strong>
              </ExpansionPanelSummary>

              {/* skryvaci obsah modulu */}
              <ExpansionPanelDetails>
                {/* klikatelne nazvy ulozenych hledani + popis */}

                {data[category][module_name].map(saved_search => {
                  // podminka, jestli uz je budik vlozen / muzeme vlozit
                  const can_add =
                    existing_views.indexOf(saved_search.id) === -1;
                  let link;
                  if (!can_add) {
                    link = (
                      <IconButton
                        onClick={() => this.deleteWidget(saved_search.id)}
                      >
                        <Done />
                      </IconButton>
                    );
                  } else {
                    link = (
                      <IconButton onClick={() => this.addWidget(saved_search)}>
                        <Add />
                      </IconButton>
                    );
                  }
                  return (
                    <div className="homeSavedSearch" key={saved_search.id}>
                      <div className="grid-container">
                        <div className="item1">{link}</div>

                        <span className="item2 summary">
                          {saved_search.name}
                        </span>
                        <div className="item3 description">
                          {saved_search.description || <em>Bez popisu</em>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        ));
      }
    }

    return (
      <React.Fragment>
        <div className="detailView">{renderMenu}</div>

        <div className="homeSavedSearchContent">{content}</div>
      </React.Fragment>
    );
  }

}
