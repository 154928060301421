import React from "react";
import PureComponent from "../../pure";
import Select from "../../formElements/Select";
import  sAction  from "sAction";

/**
 * parent = components/list/NewListViewHeaderField
 * @param props targetModule: modul na ktery se zvtahuje multirelate pole, defaultValue:, type:, label, name,
 * @return {JSX.Element}
 * @constructor
 */
export default class MultirelateField extends PureComponent {
    constructor(props) {
        super(props);
        this.options = []

        this.state = {
            loaded: false,
            accountOptions: [],
        }

        //Musi se sestavit filter podle modulu na ktery ukazuje pole
        sAction.rest.post("createMultirelateFilter", {module: this.props.targetModule.toLowerCase(), filter: this.props.filter}, returnData => {
            if(!returnData.status){
                console.log(returnData.errorMessage)
                return
            }

            returnData.message.data.forEachObject((account, accountId) => {
                this.options.push({value: accountId, label: account});
            })
        }, false)

    }

    render() {
        const colors = this.props.colors ? sAction.app_strings[this.props.colors] : null;
        return (
            <Select
                label = {this.props.label}
                options = {this.options}
                colors={colors}
                value={this.props.defaultValue}
                myRef={this.headerInput}
                onChange={e => this.props.onChange(e.target.value)}
            />
        );
    }
}
