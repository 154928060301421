export default function createMultipleCallsPopup(params) {
    const records = this.dataGet(params.prefix + "/selected");

    const data = params;
    data.prefix = 'conf/popup/data';
    data['records'] = records;
    this.popup('createMultipleCalls', data);

    // console.log(data);
    // console.log(records);
}