export default function findTypeTranslation(type) {
    const typesToTranslate = ["datetime", "bool", "varchar", "text", "relate", "date", "currency", "currency_id", "Ss",
        "ss_search", "enum", "multienum", "AcmDynamicEnum", "phone", "assigned_user_name", "int", "datetimecombo",
        "parent_type", "name", "file"]


    const found = typesToTranslate.find(element => element === type)

    if (found === "date") {
        return this.translate("LBL_DATE_REPORT")
    } else if (found === "currency") {
        return this.translate("LBL_CURRENCY_REPORT")
    } else if (found === "name") {
        return this.translate("LBL_NAME_REPORT")
    } else if (found) {
        return this.translate("LBL_" + found.toUpperCase())
    }

    return type


}