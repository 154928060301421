

import React from 'react'

import FormField from './../formField.js'
import InputText from '../../formElements/InputText';
import Select from '../../formElements/Select';
import  sAction  from "sAction";

export default class HoursMinutes extends FormField {
  constructor(props){
    super(props);
    this.state = {
      hoursOk: true,
      minutesOk: true,
      minutesValue: props.data.def.get("minutesValue"),
      selectOpen: false,
      reg: /^[0-9]+$/,
    };
    this.hour = React.createRef();
  }

  componentDidMount(){
    super.componentDidMount()
    const newRecord = this.props.newRecord
    this.hour.current.focus();
  }
  save(expressData = {}){
    if(this.check()){
        var hours = this.hour.current.value
        var minutes = this.state.minutesValue
        const data = Object.assign({
            way: this.props.way,
            name: this.props.data.name,
            value: hours,
            valueMinutes: minutes,
            type:"hourseMinutes",
        }, expressData);
        sAction.saveField(data, true)
    }
  }
  check(){
    var required = this.props.data.def.get("required");
    if(required == undefined){
        required = false
    }
    const hours = this.hour.current.value
    const minutes = parseInt(this.state.minutesValue)
    const reg = this.state.reg
    var ok = true;

        var newState = {};

        if((hours != "" && !reg.test(hours)) || (required == true && hours == "")){
            newState["hoursOk"] = false;
            ok = false
        }else{
            newState["hoursOk"] = true;
        }

        if(required == true && minutes === ""){
            newState["minutesOk"] = false;
            ok = false
        }else{
            newState["minutesOk"] = true; 
        }
        this.setState(newState);
    return ok;
  }
  getValue(){
  }
  cancel(){
    var newRecord = this.props.newRecord;
    if (newRecord) {
      return false;
    }
    sAction.dataSet(this.props.way+"/actEdit",false);
  }
  minutesChange(e){
      const val = e.target.value;
      this.setState({
          minutesValue: val,
          selectOpen: false,
      })
      this.save({valueMinutes: val});
  }
  hoursBlur(e){
    const relTarget = e.relatedTarget;
    if(relTarget == null){
        if(this.state.selectOpen == false){
            this.save();
        }
    }else{
      var parent = relTarget.closest("div[data-fieldname='"+this.props.data.name+"']")
      console.log(parent);
      if(parent == null){
        this.save();
      }
    }
  }
  selectOpen(e){
     this.setState({
         selectOpen : true,
     })
  }
  render(){
        const data = this.props.data;
        const containerClass = "hourseDate";

        var hoursError = false;
        if (this.state.hoursOk == false) {
        //  inputClass += " wrongInput";
        hoursError = true;
        }
        var minutesError = false;
        if(this.state.minutesOk == false){
            minutesError = true;
        }


        return (
        <div className={containerClass} data-fieldname={data.name}>
            <InputText defaultValue={data.value}
             error={hoursError} 
             myRef={this.hour}  
             type="number" 
             onBlur={(e) => this.hoursBlur(e)}
             onKeyDown={(e) => this.onKeyDown(e,"first")}
            />
            :
            <Select error={minutesError}
            onChange={(e) => this.minutesChange(e)}
            defaultValue={this.state.minutesValue}
            onKeyDown={(e) => this.onKeyDown(e,"last")}
            options={[
                {value:0,label:0},
                {value:15,label:15},
                {value:30,label:30},
                {value:45,label:45}
                ]}  
            />
        </div>
      );
    }
}
