import React from "react";
import PureComponent from "../../pure";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import Button from "../../formElements/Button";

import sAction from "sAction";
import SearchPlaceDropdown from "./SearchPlaceDropdown";

class OpenStreetMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fromState: false,
      item: null,
      x: null,
      y: null,
      sx: null,
      sy: null,
      zoom: false,
      fromSearch: false,
      sidebar: props.data.get('sidebar') !== undefined ? props.data.get('sidebar') : true, // celkove zobrazit postrani panel s moznosti vyhledavani a popisky zobrazenych bodu
      sidebarSearch: props.data.get('sidebarSearch') !== undefined ? props.data.get('sidebarSearch') : true, // moznost skryt vyhledavani v postranim panelu
      clickable: props.data.get('clickable') !== undefined ? props.data.get('clickable') : true, // jestli se pri kliknuti bude automaticky vyhledavat adresa
    };
    this.mapRef = React.createRef();

  }

  handleClick(e) {
    if (this.state.clickable){
      this.setCoordinates(e.latlng.lng, e.latlng.lat);
    }
  }

  setCoordinates(x, y) {
    var data = this.props.data;
    const paramData = { x, y };
    sAction.load();
    sAction.searchAddressByGPS(paramData, item => {
      sAction.unLoad();
      this.setState({
        item: item,
        x,
        y,
        fromState: true
      });
    });
  }
  returnAddress() {
    const item = this.state.item;
    if (item == null) {
      sAction.popupHide();
    } else {
      if (this.props.data.get("callback") != undefined) {
        this.props.data.get("callback")(item);
      }
    }
  }
  returnCoordinates() {
    const x = this.state.x;
    const y = this.state.y;
    const item = {
      cast_obce: "",
      cislo_dom: "",
      cislo_orient: "",
      kraj: "",
      obec: "",
      psc: this.state.item.psc,
      ulice: "",
      x: x,
      y: y
    };
    if (this.props.data.get("callback") != undefined) {
      this.props.data.get("callback")(item);
    }
  }
  changePosition(coordinates) {
    this.setState({sx: coordinates.x, sy: coordinates.y, fromSearch: true, zoom: 12});
  }
  render() {
    var data = this.props.data;

    const name = data.get("name");
    const item = this.state.item;
    let readonly = data.get("readonly");
    readonly = readonly == true || readonly === 'true';
    const x = this.state.fromState ? item.x : data.get("item").get("gpsx");
    const y = this.state.fromState ? item.y : data.get("item").get("gpsy");
    
    const latlng = {
      lat: y == undefined ? 49.836 : y,
      lng: x == undefined ? 15.549 : x
    };

    if (latlng['lat'] === '') {
      latlng['lat'] = 49.836;
    }
    if (latlng['lng'] === '') {
      latlng['lng'] = 15.549;
    }

    var actLatlng = null;
    if (this.state.fromState) {
      actLatlng = {
        lat: this.state.y,
        lng: this.state.x
      };
    }
    var adresa = null;
    if (this.state.fromState) {
      adresa =  sAction.getStreetlabelAcmAddress(item)

      if(adresa){
       adresa +=  ", " +
         item.obec +
        " " +
        item.psc +
        " " +
        item.kraj;
      }
    } else {
      const item = data.get("item");
      adresa = name !== " " ? name : "";
        if(adresa){
        adresa += ", " +
        item.get("obec") +
        " " +
        item.get("psc") +
        " " +
        item.get("kraj");
        }
    }

    const markerIcon = new L.Icon({
      iconUrl: "img/marker.png",
      iconRetinaUrl: "img/marker.png",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(20, 35)
    });
    const markerIconRed = new L.Icon({
      iconUrl: "img/markerRed.png",
      iconRetinaUrl: "img/markerRed.png",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(20, 35)
    });
    var marker =  null;
    if (x && y) {
      marker = <Marker position={latlng} icon={markerIcon} />;
    }
    const secondMarker = this.state.fromState ? (
      <Marker position={actLatlng} icon={markerIconRed} />
    ) : null;

    var zoom = 12;
    if (this.state.zoom) {
      zoom = this.state.zoom;
      this.state.zoom = false;
    } else if (x == '' || (x == undefined && !actLatlng)) {
      zoom = 7;
    }
    var selectDropDown = (
      <SearchPlaceDropdown
        filter={this.state.filter}
        onSelect={gps => this.changePosition(gps)}
      />
    );

    var center = actLatlng ? actLatlng : latlng;
    if (this.state.fromSearch) {
      center = {
        lat: this.state.sy,
        lng: this.state.sx
      };
      this.state.fromSearch = false;
    }


    return (
      <div className="openStreetMapContainer">
        <Map
          center={center}
          zoom={zoom}
          style={{ height: "100%", width: "100%" }}
          onClick={e => this.handleClick(e)}
          ref={this.mapRef}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {marker}
          {secondMarker}
        </Map>
        {this.state.sidebar && (
          <div className="openStreetMapMenu">
          {this.state.sidebarSearch && (selectDropDown)}
            {x && y && (
            <div className="openStreetMapMenuGroup">
            <div className="markerIcon">
              <img src="img/marker.png" />
            </div>
            <div className="openStreetMapMenuGroupInfo">
              <div className="openStreetMapMenuLine">
                <label>Existující adresa</label>
                <div>{adresa}</div>
              </div>
              <div className="openStreetMapMenuLine">
                <label>Lat</label>
                <div>{y}</div>
              </div>
              <div className="openStreetMapMenuLine">
                <label>Lng</label>
                <div>{x}</div>
              </div>
              <div className="openStreetMapMenuLine">
              {!readonly && this.state.fromState &&
                <Button
                  className="stillHover"
                  onClick={() => this.returnAddress()}
                >
                  <div className="actionPanelButtonIcon icon-saveIcon" />
                  Použít adresu
                </Button>
              }
              </div>
              </div>
            </div>
            )}
            {this.state.fromState && (
              <div className="openStreetMapMenuGroup">
              <div className="markerIcon">
                <img src="img/markerRed.png" />
              </div>
                <div className="openStreetMapMenuGroupInfo">
                <div className="openStreetMapMenuLine">
                  <label>Přesné souřadnice</label>
                </div>
                <div className="openStreetMapMenuLine">
                  <label>Lat</label>
                  <div>{actLatlng.lat}</div>
                </div>
                <div className="openStreetMapMenuLine">
                  <label>Lng</label>
                  <div>{actLatlng.lng}</div>
                </div>
                <div className="openStreetMapMenuLine">
                  {!readonly && (
                    <Button
                      className="stillHover"
                      onClick={() => this.returnCoordinates()}
                    >
                      <div className="actionPanelButtonIcon icon-saveIcon" />
                      Použít souřadnice
                    </Button>
                  )}
                </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}


export default OpenStreetMap;
