export default function overwriteStyle(){
    var css = "";
    const data = this.getStorage("style");
    if(data != null){
        data.forEachObject((styles, selector) => {
            css += selector + " {";
            styles.forEachObject((value, key) => {
                css += key + " : " + value + ";";
            })
            css += "}";
        })
    }
    const overwriteStyle = this.param.overwriteStyle
    if(overwriteStyle != undefined){
        overwriteStyle.forEachObject((styles, selector) => {
            css += selector + " {";
            styles.forEachObject((value, key) => {
                css += key + " : " + value + ";";
            })
            css += "}";
        })
    }

    const head = document.querySelector("head");
    var style = document.querySelector("style[data-meta='coripo']");
    if (style == null) {
        style = document.createElement("style")
        style.setAttribute('data-meta', "coripo");
        head.appendChild(style);
    }
    style.innerHTML = "";
    style.type = 'text/css';
    if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
}