

export default function openFilter(def,offset,prefix,key, fieldRel = null){
    console.log(def.toJS());
    var field = def.get("name");
    var filterWindow = this.dataGet(prefix+"/openFilterData");

    this.dsClear();
    if(filterWindow != null && filterWindow.field == field && filterWindow.open == true){
        this.dsAdd("set",prefix+"/openFilterData",{},"openFilterData");
    }else{
        var data = {
            open:true,
            field,
            def,
            offset,
            fieldRel,
        }
        this.dsAdd("set",prefix+"/openFilterData",data,"openFilterData");
        if(key){
            this.dsAdd("set",prefix+"/openFilterData/def/fieldWithPath",key);
        }
    }
    this.dsProcess();

    /*
    this.dataSet(prefix+"/openFilterData/def",def);

   
    const akce = {
        type: "OPENFILTER",
        listViewPrefix: prefix,
        filter: filter,
      }
    this.dataSetCustom(akce);
      */
}