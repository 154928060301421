import React from "react";

import Enum from "../Enum/Enum";
import Select from "../../formElements/Select";
import  sAction  from "sAction";

export default class Currency_id extends Enum {
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;

    var optionsToSelect = [];
    sAction.currencies.forEachObject((value, key) => {
      optionsToSelect.push({
        value: key,
        label: value["name"] + " (" + value["symbol"] + ")"
      });
    });
    const forceOpen = data.def.get("forceOpen") || this.props.listField

    return (
      <Select
        ref="input"
        options={optionsToSelect}
        onKeyDown={e => this.onKeyDown(e,null,false)}
        open={forceOpen === true ? forceOpen : false}
        autoFocus={forceOpen !== true ? true : forceOpen}
        defaultValue={data.value}
        onChange={e => this.save(e)}
      />
    );
  }
}
