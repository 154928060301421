import React from "react";
import PureComponent from "../../pure";

import EditFieldsNewField from "./EditFieldsNewField";
import EditFieldsEnum from "./EditFieldsEnum";

import sAction from "sAction";

export default class EditFieldsPopup extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;

    const view = data.get("view")
    

    return (
      <div>
        <div style={view == "enum" ? {display:"none"}:null}>
            <EditFieldsNewField data={data} />
        </div>
        <div style={view == "newField" ? {display:"none"}:null}>
            <EditFieldsEnum data={data} />
        </div>
      </div>
    );
  }
}
