import React from "react";
import PureComponent from "../../pure";

import ExportList from "../exportList/ExportList";
import  sAction  from "sAction";

class ReportWindowExport extends PureComponent {
  constructor(props) {
    super(props);

    this.exportNameInput = React.createRef();
  }
  createExport(data) {
    sAction.reportWindowExport(data);
  }
  fieldFunction(value,key){
    const fieldFunctions = this.state.fieldFunctions
    fieldFunctions[key] = value

    this.setState({
      fieldFunctions:fieldFunctions,
    })
  }

  render() {
    var data = this.props.data;
    
    var renderRows = [];
    const columns = data.columns.active;

    
    return (
      <div className="reportWindowSaveContainer flexCenter">
        <ExportList columns={columns} onCreate={(data) => this.createExport(data)} close={() => this.props.close()} />
      </div>
    );
  }
}
export default ReportWindowExport;
