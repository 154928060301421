

export default function toList(data){

    var view = this.getViewName();
    if(view == "list"){
      this.reload();
    }else{

      location.href = "#list/"+data;

    }


}
