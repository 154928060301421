export default function dragAndDropSave(data){

    var dragData = data.dragData;

    if(data.wayPrefix == undefined){
        data.wayPrefix = "";
    }

    var saveAs = "";
    if(data.saveAs != undefined){
        saveAs = data.saveAs;
    }

    const holders = dragData.holders;
    const target = dragData.element;
    var ways = {
      prev: data.wayPrefix+target.dataset.way,
    }
    var placeHolderIndex = null
    var checkContainers = [];
    holders.forEachObject(holder => {
      if (checkContainers.indexOf(holder.dataset.way) == -1) {
        holder.children.forEachObject(child => {
          if (child.dataset.way != target.dataset.way) {
            if (
              child.classList.contains("reportWindowMovePlaceholder")
            ) {
              var index = Array.from(child.parentNode.children).indexOf(child);

              placeHolderIndex = index

            
              if(index >= 1){
                var indexWay = child.parentNode.children[index -1];
                ways["new"] = data.wayPrefix+indexWay.dataset.way
              }else{
                ways["new"] = data.wayPrefix+holder.dataset.way+"/0";
              }
              ways["newParent"] = data.wayPrefix+holder.dataset.way;
            }
          }else{
            ways["prevParent"] = data.wayPrefix+holder.dataset.way
          }
        
        });
        checkContainers.push(holder.dataset.way);
      }
    });
    var data = ways;
    
        const moveElement = this.dataGet(data.prev).setIn(["def","show"],true);
        moveElement.get("def").show = true;
        const elementWay = data.prev.split("/")
        const elementIndex = parseInt(elementWay[elementWay.length-1]);
        const newElementWay = data.new.split("/")
        var newElementIndex = parseInt(newElementWay[newElementWay.length-1]);


        const oldList = this.dataGet(data.prevParent);
        var oldListRepare = [];
        oldList.forEach((element,key) => {
            if(key != elementIndex){
                oldListRepare.push(element);
            }
        });
        this.dataDelete(data.prevParent,elementIndex);
        
        
        const newList = this.dataGet(data.newParent);
        var newListRepare = [];
        var add = false;


        if(data.prevParent == data.newParent && elementIndex < placeHolderIndex){
            placeHolderIndex--
        }



        newList.forEach((element,key) => {
            if(key == placeHolderIndex){
                add = true;
                newListRepare.push(moveElement);
            }
          newListRepare.push(element);
        })
        if(add == false){
            newListRepare.push(moveElement);
        }
        const returnData={
          data: newListRepare,
          way: data.newParent,
        }
        this.dataSet(returnData.way,returnData.data,"");
}