export default function rightPanelProduct(params){
    
    this.openRightPanel("load");

    //console.log("rightPanelProduct", params);

    this.dsClear();

    var self = this;

    this.rest.post("list/ProductTemplates",{prefix: 'e156f6b6-e3a7-5508-726d-564470388312'},function(data){

        var akceRP = {
            type: 'QUOTERPDATAINIT',
            content: {records : data.specRecords},
            prefix: "rightPanel/data"
        }

        self.dsAddCustom(akceRP);

        var akceTree = {
            type: 'PRODUCTCATEGORIESTREE',
            content : data.productCatogories,
            prefix: "rightPanel/data/categories"
        }
        self.dsAddCustom(akceTree);
        
        var content = self.listContent(data);

        var akce ={
            type: 'PAGELOADLIST',
            page: "ProductTemplates",
            content: content,
            buttons: [],
            function: self,
            listViewPrefix: "rightPanel/data/listView"
        }
        self.dsAddCustom(akce);
        self.dsAdd("set","rightPanel/data/groupWay",params.way);
        self.dsAdd("set","rightPanel/data/listView/type","rightPanelQuotes");

        self.dsAdd("set","rightPanel/content","productView");
        self.dsProcess();
      });

}