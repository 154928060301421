import React from "react";
import AcmDateTime from "../../formElements/AcmDatetime.js";
import FormField from "./../formField.js";
import sAction from "sAction";

export default class Datetime2 extends FormField {

    save(date) {
        if (this.check(date)) {
            const data = {
                way: this.props.way,
                name: this.props.data.name,
                value: date,
                cancelEdit:false,
                type: "datetime"
            };
            this.saveField(data);
        }
    }
    check(date) {
        var required = this.props.data.def.get("required");
        if (required == undefined) {
            required = false;
        }
        var ok = true;
        var state = {};

        if (date == "" && required == true) {
            state.dateOk = false;
            ok = false;
        } else {
            state.dateOk = true;
        }

        this.setState(state);

        return ok;
    }
    render() {
        const data = this.props.data;
        
        return (
            <AcmDateTime  autoOpen={true} autoFocus={true} onChange={(value) => this.save(value)} value={data.value} />
        )
    }
}
