import InputField from "../inputFieldClass";
import  sAction  from "sAction";

export default class ApiICO extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      reg: /^[0-9]{8}$/
    };
  }

  getValue() {
    let value = this.input.current.value;
    if (!value) return "";

    //doplneni 0 na zacatek (pro starsi IC)
    value = value.padStart(8, "0");
    return value;
  }

  check() {
    let ok = super.check();

    const value = this.getValue();

    if (ok && value && value !== this.props.data.value) {
      if (!this.testICO(value)) {
        sAction.error(sAction.translate("ERR_IC_INVALID", "Accounts"), sAction.translate("LBL_CHECK_ICO", "Accounts"));
      }
      sAction.checkICO(value);
    }
    return ok;
  }

  testICO(x) {
    try {
      var a = 0;
      if (x.length == 0) return true;
      if (x.length != 8) return false;
      var b = x.split("");
      var c;
      for (var i = 0; i < 7; i++) a += parseInt(b[i], 10) * (8 - i);
      a %= 11;
      c = 11 - a;
      if (a == 1) c = 0;
      if (a == 0) c = 1;
      if (a == 10) c = 1;
      if (parseInt(b[7], 10) != c) return false;
    } catch (e) {
      //alert('Zadaný údaj není korektní!');
      return false;
    }
    return true;
  }
}
