import React from "react";
import Checkbox from "../../formElements/TickBox";
import PureComponent from "../../pure";

import  sAction  from "sAction";
import InputText from "../../formElements/InputText";

export default class RolesTable extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      checkedAll: false,
      data: [],
      loaded: false,
      name: ""
    };
    this.interval = null;
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.interval);
  }

  componentDidMount = () => {
    this._isMounted = true;

    this.findData();
  };

  // kdyz admin vyhledava konkretni jmeno
  handleChange = e => {
    let name = e.target.value;
    this.setState({ name });

    name = name.trim();
    if (!name) name = "%";

    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.findData(name);
    }, 1000);
  };

  findData = (name = "%") => {
    this.setState({ loaded: false });

    const searchData = {
      module: this.props.module,
      fields: ["name"],
      getFields: ["name", "id"],
      value: name,
      limit: 999999,
    };

    sAction.quickSearch(searchData, data => {
      if (!this._isMounted) return;

      if (this.props.module === "Users" && this.props.keepSelf !== true) {
        const userId = sAction.dataGet("conf/user/id");
        data = data.filter(a => a.id !== userId);
      }
      this.setState({ data, loaded: true });
    });
  };

  handleCheckboxAll = ch => {
    const selectedData = [...this.props.selectedData];
    //const ch = event.target.checked; // hodnota checkboxu

    this.state.data.forEach(data => {
      // zjisti zda mame v selectedData id role (a podle checkbox checked ho pridej nebo odeber)
      var index = selectedData.indexOf(data.id);

      if (ch === true && index === -1) {
        // pridej do array
        selectedData.push(data.id);
      } else if (ch === false && index > -1) {
        // nalezeno - smazat
        selectedData.splice(index, 1);
      }
    });

    this.setState({ checkedAll: ch });
    this.props.onChange(selectedData);
  };

  // kdyz admin odklikne nejaky checkbox
  handleCheckboxChange = (ch, u) => {
    //const u = event.target.value; // vybrany uzivatel id
    //const ch = event.target.checked; // hodnota checkboxu

    const dataId = this.state.data[u].id;

    const selectedData = [...this.props.selectedData];

    // zjisti zda mame v selectedData id vybraneho radku (a podle checkbox checked ho bud pridej nebo odeber)
    var index = selectedData.indexOf(dataId);
    if (index > -1) {
      // ano
      if (ch === false) {
        // nalezeno - smazat
        selectedData.splice(index, 1);
      }
    } else if (ch === true) {
      // pridej do array
      selectedData.push(dataId);
    }

    this.props.onChange(selectedData);
  };

  render() {
    const { data, loaded } = this.state;
    const { selectedData, module } = this.props;
    let results;

    // kdyz se nacitaji vysledky vyhledavani
    if (loaded === false) {
      results = (
          <div className='rolesTableRow'>
            <div className='rolesTableLabel'>
              {sAction.translate("LBL_LOADING", "Home")}
              {/* Načítání... */}
            </div>
          </div>
      );

      // kdyz se nasly nejake vysledky
    } else if (data.length) {
      results = data.map((u, i) => (
          <div className='rolesTableRow' key={i}>
            <div className='rolesTableLabel'>
              {u.name}
            </div>
            <div className='rolesTableCheckbox'>
              <Checkbox
                  checked={selectedData.indexOf(u.id) > -1}
                  onChange={checked => this.handleCheckboxChange(checked, i)}
                  //value={i}
                  //color="primary"
              />
            </div>
          </div>
      ));

      // kdyz se nenaslo nic
    } else {
      results = (
          <div className='rolesTableRow'>
            <div className='rolesTableLabel'>
              {sAction.translate("LBL_NO_DATA_FOUND", "Home")}
              {/* Nenalezena žádná data. */}
            </div>
          </div>
      );
    }


    return (
        <React.Fragment>
          <div className="homeTableContainer">
            <div className='rolesTableHeader'>
              <div className='rolesTableRow'>
                <div className='rolesTableLabel'>
                  <InputText
                      margin="dense"
                      id="name"
                      label={sAction.translate(
                          module === "Users" ? "LBL_NAME" : "LBL_DASHLET_OPT_TITLE",
                          "Home"
                      )} // Jméno || Název
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                  />
                </div>
                <div className='rolesTableCheckbox'>
                  <Checkbox
                      checked={this.state.checkedAll}
                      onChange={checked => this.handleCheckboxAll(checked)}
                  />
                </div>
              </div>
            </div>
            <div className='rolesTableContent'>
              {results}
            </div>
          </div>
        </React.Fragment>
    );
  }
}
