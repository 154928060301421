

export default function translateSelect(select,key){

    const ciselnik = this.app_strings[select];

    if(ciselnik != undefined){
        return ciselnik[key];
    }else{
        console.log("Chybí čísleník "+select);
        return key;
    }

}