export default function reloadActivitiesPanel(prefix,init = false){

    const data = this.dataGet(prefix);
    const params = this.activitiesTimeLineParams(data);
    this.load();
    this.rest.post("getListActivities", params, response => {
        response.forEachObject((value,key) => {
            this.dsAdd("set", prefix+"/"+key, value);
        })
        this.dsAdd("set", "rightPanel/content", "activitiesTimelineView");
        this.dsAdd("set","conf/load",false);
        this.dsProcess();
      });
}