export default function blurCellField(params) {
	this.dsClear();
	const rows = this.dataGet(`${params.way}/rows`);
	const records = this.dataGet(`${params.way}/records`);
	const lineIndex = records.indexOf(params.id);
	this.dsClear();
	rows.forEach((row, rowKey) => {
		const way = `${params.way}/rows/${rowKey}/records/${lineIndex}`;
		this.dsAdd('set', `${way}/actEdit`, false);
	});
	this.dsProcess();
	this.afterLoadListView(params.way);
}