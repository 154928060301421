export default function setCustomDetailLayout(prefix,data){
    if(data.tabs){
        this.dsAdd("set",prefix+"/tabs",[]);
        data.tabs.forEach((tab,index) => {
            if(index == 0){
                tab.active = true
            }else{
                tab.active = false
            }
            this.dsAdd("add",prefix+"/tabs",tab,"Tab");
        })
    }
    if(data.hidden){
        this.dsAdd("set",prefix+"/hiddenFields",data.hidden)
    }
}