import moment from "moment";

export default function dateFormat(date) {
    if (date) {
        if (date === "0000-00-00") {
            return "";
        }
        let format = this.getDateFormat("moment") + (date.includes(' ') ? ' ' + this.getTimeFormat("moment") : '');
        return moment(date).format(format);
    }
    return '';
}
