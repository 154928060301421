import detailDefault from "../../detailDefault";
export default class detailacm_templateinvoicing extends detailDefault {
  load(sAction, data) {}

  newSubRecord(sAction, data) {}
    beforeSave(sAction, data) {
        if (data.prefix === 'view') {
            if ((!sAction.dataGet("view/customData/customLines")
                || sAction.dataGet("view/customData/customLines").has('lines') === false)
            ) {
                return this.noRowsErrorMsg(sAction);
            }
            let lines = sAction.dataGet("view/customData/customLines/lines", null, true)
            let linLen = 0
            if (lines !== undefined) {
                linLen = Object.keys(lines).length

                lines.forEachObject((data, key) => {
                    if (data.deleted == 1) {
                        linLen--;
                    }
                })
            }

            if (lines !== undefined) {
                if (Object.keys(lines).length === 0 || linLen === 0) {
                    return this.noRowsErrorMsg(sAction);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }

        return true;
    }

  noRowsErrorMsg(sAction) {
    sAction.alert(sAction.translate("LBL_NO_ROWS_MSG", "acm_invoices"));
    return false;
  }
}
