export default function createTag(data, way, prefix) {
    this.rest.post("tags/createTag/", { data: data}, returnData => {
        if (returnData['status']) {
            this.addTagRelate(returnData['beanID'], way, prefix);
        } else {
            this.dsClear();
            this.dsAdd("set", "conf/popup/data/message", returnData['message']);
            this.dsProcess();
        }
    });
}
