import React, { PureComponent } from "react";
import  sAction  from "sAction";
import Select from "../formElements/SelectAcm";
import Button from "../formElements/Button";
import EmailUploadFile from "./EmailUploadFile";

export class EmailComposeFormBottom extends PureComponent {
  constructor(props) {
    super(props);

    this.actionButtons = (
      <div className="email__compose__attachments viewActionPanelButtons">
        <Button onClick={this.addAttachment} className="hoverBlue">
          <div className="actionPanelButtonIcon icon-attachment" />{" "}
          {/*Přidat přílohu z PC*/}
          {/*Přidat soubor*/}
          {sAction.translate("LBL_ADD_FILE", "Emails")}
        </Button>

        <div className="viewPanelButtonsDelimiter" />

        <Button onClick={this.addSugarDocument} className="hoverBlue">
          <div className="actionPanelButtonIcon icon-attachment2" />{" "}
          {/*Přidat Sugar Dokument*/}
          {sAction.translate("LBL_ADD_DOCUMENT", "Emails")}
        </Button>
      </div>
    );
  }

  addAttachment = () => {
    document.querySelector("input[type=file]").click();
  };

  addSugarDocument = () => {
    document.querySelector("input[class=addSugarDocument]").click();
  };

  render() {
    let {
      //fce
      getEmailTemplateConfirm,
      getSignature,

      //vars
      data: {
        emailTemplate,
        emailTemplates,

        attachments,

        signatures,
        signatureId
      }
    } = this.props;

    return (
      <React.Fragment>
        <EmailUploadFile
          way={this.props.way + "/attachments"}
          attachments={attachments}
        />

        <div className="email__compose__table-form-footer">
          <div>
            {/*Šablony*/}
            {sAction.translate("LBL_EMAIL_TEMPLATES")}
          </div>

          <div className="email__compose__table-form-footer__select">
            <Select
              options={emailTemplates}
              name="emailTemplates"
              value={emailTemplate}
              onChange={getEmailTemplateConfirm}
            />
          </div>

          <div>
            {/*Podpis */}
            {sAction.translate("LBL_EMAIL_SIGNATURES")}
          </div>

          <div className="email__compose__table-form-footer__select">
            <Select
              options={signatures}
              name="signatures"
              value={signatureId}
              onChange={getSignature}
            />
          </div>

          {this.actionButtons}
        </div>
      </React.Fragment>
    );
  }
}
