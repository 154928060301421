export default function duplicateRecord(param) {
  const modul = param.module;
  // console.log(param);

  const detail = this.dataGet(param.prefix);
  if (param.type === "rightPanel") {
    this.dataSet("rightPanel/closeOnSave", true);
  }
  this.dsClear();
  detail.fields.entrySeq().forEach((e) => {
    const name = e[0];
    const field = e[1];
    const value = field.value;

    const type = field.def.get("type");
    if (name !== "id") {
      if (type == "relate" || type == "link" || type == "parent") {
        this.dsAdd(
          "set",
          param.prefix + "/changes/fields/" + field.def.get("id_name"),
          field.def.get("id_value")
        );

        if (type == "parent") {
          this.dsAdd(
            "set",
            param.prefix + "/changes/fields/parent_type",
            field.def.get("parent_type_value")
          );
        }
      } else {
        this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
      }
      this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
    }
  });
  this.dsAdd("set", param.prefix + "/id", null);

  if (this.dataGet(param.prefix + "/customData")) {
    this.dsAdd("set", param.prefix + "/customData/duplicateId", param.id);
  }
  if (
    detail.customData &&
    detail.customData.get &&
    detail.customData.get("customLines") &&
    detail.customData.get("customLines").get("lines")
  ) {
    detail.customData
      .get("customLines")
      .get("lines")
      .toJS()
      .forEachObject((line, key) => {
        this.dsAdd(
          "set",
          param.prefix + "/customData/customLines/lines/" + key + "/id",
          null
        );
      });
  }
  this.dsProcess();
}
