export default function doHeliosu(data){
    this.load();
    this.rest.post("doHeliosu", data, (returnData) => {
        this.unLoad();
        if(returnData.status !== "ok") {
            this.error("Nastala neočekávaná chyba");
            return;
        }
        this.alert('OK');
    });
}