export default function displayQuickEdit(params){
    
  const module = params.module;
  const record = params.record;
  const prefix = params.prefix;

    var self = this;

    this.rest.get("quickform/" + module + "/" + record,
        function(data) {
          
          const invitees = data.invitees;

          let actionButtons = [];

          
          var content = {
            id: record, 
            module: module,
            actionButtons : actionButtons
          };

          self.detailContent(data, content);
          content.subpanels = [];

          var action = {
            type: "PAGELOADDETAIL",
            content: content,
            prefix: prefix+"/detailView",
            function: self
          };
    
          self.dsClear();
          self.dsAddCustom(action);
          self.dsProcess();

          content = {
            invitees : invitees
          }

          action = {
            type : "CALENDARLOADQUICKFORM",
            content: content,
            prefix: prefix+"/invitees",
            prefixBackup: prefix+"/inviteesBackup",
          }

          /*data.calQuickCreate.toJS().fields["name"].value */
          self.dsClear();
          self.dsAddCustom(action);
          self.dsProcess();
          self.popup("calQuickEdit", {module : module, onClose : self.clearCalDetailStore, prefix : prefix, header:data.record.name, color:module+"CalTitle", icon:"icon-"+module});

        }
      );
}

