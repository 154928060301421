import React from "react";

import ReactQuill, { Quill } from "react-quill";
import sAction from "sAction";

// import { Editor } from "@tinymce/tinymce-react";

let propsRef = "";

const refreshVariables = () => {
  if (!propsRef.refreshVariables) {
    // Tuto funkci nelze použít.
    sAction.error(sAction.translate("ERR_CANNOT_USE", "Emails"));
    return;
  }
  propsRef.refreshVariables();
};

export default class RichText extends React.PureComponent {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    propsRef = props;
  }

  //quill editor focus
  componentDidMount() {
    this.getQuillEditorRefs();
  }

  componentDidUpdate() {
    this.getQuillEditorRefs();
  }

  //ziska refy na quill editor
  getQuillEditorRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    if (this.quillRef != null) return;

    const input = this.reactQuillRef.getEditor();
    if (input != null) this.quillRef = input;
  };

  //ziska html kod z quill editoru
  getValue() {
    return this.reactQuillRef.state.value;
  }

  /*
   * https://quilljs.com/docs/modules/
   */
  static modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        refreshVariables: refreshVariables
      }
    },
    clipboard: {
      // toggle to addParam extra line breaks when pasting HTML:
      matchVisual: false
    }
  };
  /*
   * https://quilljs.com/docs/formats/
   */
  static formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
    "signature"
  ];

  onBlur = (e) => {
    if (e.target.className !== "ql-editor") return;
    if (this.props.onBlur != undefined) {
      this.props.onBlur();
    }
  };

  render() {
    const { value } = this.props;

    //todo preklad
    return (
      <div
        className={this.props.className != undefined ? this.props.className + " quill" : "quill"}
        onBlur={this.onBlur}
        tabIndex={0}
      >
        <div id="toolbar">
          <button
            type="button"
            className="ql-header"
            value="1"
            title="Nadpis H1"
          />
          <button
            type="button"
            className="ql-header"
            value="2"
            title="Nadpis H2"
          />
          <select className="ql-font" defaultValue="arial" title="Změnit písmo">
            <option value="arial">Arial</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>
          </select>
          <select className="ql-size" defaultValue="" title="Velikost písma">
            <option value="small">Malé</option>
            <option value="">Normální</option>
            <option value="large">Velké</option>
            <option value="huge">Obrovské</option>
          </select>
          <button className="ql-bold" title="Tučné" />
          <button className="ql-italic" title="Kurzíva" />
          <button className="ql-underline" title="Podtržené" />
          <button className="ql-blockquote" title="Citace" />
          <select className="ql-color" title="Barva písma" />
          <select className="ql-background" title="Barva pozadí" />
          <select className="ql-align" title="Zarování textu" />
          <button
            type="button"
            className="ql-list"
            value="ordered"
            title="Číslovaný seznam"
          />
          <button
            type="button"
            className="ql-list"
            value="bullet"
            title="Nečíslovaný seznam"
          />
          <button
            type="button"
            className="ql-indent"
            value="-1"
            title="Zmenšit odsazení"
          />
          <button
            type="button"
            className="ql-indent"
            value="+1"
            title="Zvětšit odsazení"
          />
          <button className="ql-link" title="Přidat odkaz" />
          <button className="ql-image" title="Přidat obrázek" />
          <button className="ql-clean" title="Zrušit formátování" />
          {this.props.variables !== false &&
            <button className="ql-refreshVariables" title="Aktualizovat proměnné">
              <strong>$_</strong>
            </button>
          }
        </div>
        <ReactQuill
          theme="snow"
          ref={i => {
            this.reactQuillRef = i;
          }}
          defaultValue={value}
          modules={RichText.modules}
          formats={RichText.formats}
          bounds={".app"}
          //onBlur={this.onBlur}
          //onChange={this.onChange}
          tabindex="0"
        //placeholder="..."
        />
        {/*<Editor*/}
        {/*initialValue="<p>This is the initial content of the editor</p>"*/}
        {/*init={{*/}
        {/*plugins: "link image code",*/}
        {/*toolbar:*/}
        {/*"undo redo | bold italic | alignleft aligncenter alignright | code"*/}
        {/*}}*/}
        {/*//onChange={this.handleEditorChange}*/}
        {/*/>*/}
      </div>
    );
  }
}

// Signatures
let Inline = Quill.import("blots/inline");
class Signature extends Inline { }
Signature.blotName = "signature";
Signature.tagName = "signature";
Quill.register("formats/signature", Signature);

// Font sizes
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large", "huge"];
Quill.register(Size, true);

// Fonts
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);
