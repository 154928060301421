

export default function listGlobalSearch(val,prefix){

    var actFilter = null
    if(val != "" && val != null && val != undefined){
    actFilter = {
        "field" : "*",
        "operator": "and",
        "operands": [
          {
            "field": "*",
            "type": "cont",
            "value": val
          }
        ]
      }
    }

    var akce = {
      type: "SETFILTER",
      listViewPrefix: prefix,
      filter: actFilter,
    }
    this.dataSetCustom(akce);
    this.dsClear();
    this.dsAdd('set', prefix + '/actFiltering', false);
    this.dsProcess();

    this.reloadList(0,1,prefix);

  
}