import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";

class SubMenuLines extends PureComponent {

  showLabel(e, vname) {
    console.log("Ted");
    if (this.props.menuOpen == false) {
      const target = e.target;
      const top = target.getBoundingClientRect().top;

      var label = document.querySelector(".menuHoverLabel");
      label.style.display = "flex";
      label.style.top = top + "px";
      label.innerHTML = vname;
    }
  }

  render() {
    const data = this.props.data;
    const activeModule = this.props.activeModule;
    var buttonsRender = [];
    data.forEach((button,index) => {
      const type = button.get("type");
      if (type == "module") {
        const module = button.get("value");
        const vname = sAction.app_strings.moduleList[module];
        buttonsRender.push(
          <a key={index} className={activeModule == module ? "subMenuButton select" : "subMenuButton"}
          href={"#list/"+module}
          onMouseEnter={(e) => this.showLabel(e, vname)}
          >
            <div className={"icon-" + module+" subMenuIcon"} />
            {vname}
          </a>
        );
      }else if(type == "href"){
        const href = button.get("href");
        const iconClass = button.get("icon");
        const vname = sAction.translate(button.get("vname"));
        buttonsRender.push(
          <a key={index} className={activeModule == module ? "subMenuButton select" : "subMenuButton"}
          href={href}
          title={vname}
          onMouseEnter={(e) => this.showLabel(e, vname)}
          >
            <div className={iconClass} />
            {vname} 
          </a>
        );
      }
    });

    return <div className="subMenuButtonsContainer">{buttonsRender}</div>;
  }
}
export default SubMenuLines;
