export default function getStreetlabelAcmAddress(item){

  if(item.ulice == undefined){
    return "";
  }

    var label = item.ulice;
    if (item.cislo_dom != "" && (item.cislo_orient != "" && item.cislo_orient != 0)) {
        label += " " + item.cislo_dom + "/" + item.cislo_orient;
      } else label += " "+item.cislo_dom;
      return label;
}
