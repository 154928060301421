import React from "react";
import sAction from "sAction";

export default function DetailViewSpanButtons(props){

  const type = props.data.def.get("type");
  const item = props.data.def.get("item");
  const readonly = props.data.def.get("readonly");
  const name = props.data.value
    var buttons = [];
    if (type == "AcmAddress") {
        buttons.push(
          <a key={0} className="detailViewSpanButton"
             onClick={() => sAction.popup("openStreetMap", {
                 item: item,
                 name: name,
                 header: sAction.translate("LBL_MAP"),
                 readonly,
                 callback: data => {
                     sAction.saveAcmAddressField(data, props.data, props.prefix, props.way)
                 }
             })}>
              <span className="icon-Location"/>
          </a>);
    }
    return(
        <div className="detailViewSpanButtonsContainer">
            {buttons}
        </div>
    )
}
