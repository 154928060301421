export default function seekyAllActivities(data){
    this.load()
    var paramData = {
        recID:data.id,
        module:data.module,
        seeky_visitor_id:this.dataGet(data.prefix + "/fields/seeky_visitor_id/value"),
    }

    this.rest.post("seekyGetAllActivities",paramData,retData => {
        if (retData.status) {
            this.unLoad()
            this.popup('seekyAllActivities',{data:retData.message, header:this.translate('LBL_SEEKY_ACTIVITY_BTN')});
        }
        else{
            this.unLoad()
            this.alert('Chyba při získávání dat.')
        }
    })
}
