export default function HomeWidgetRefresh(type, way) {
  this.dsClear();

  if (type === "customReport") {
    this.dsAdd("set", way + "/data/viewData", null);
    this.dsProcess();
  } else if (type === "view") {
    this.load();
    const sendData = this.dataGet(way+"/data");
    const id = sendData.get("viewId");
    this.rest.post(
      "home/getwidget",
      { id, search_module: sendData.get("module") },
      (data) => {
        this.unLoad();
        var content = this.listContent(data);
        var akce = {
          type: "PAGELOADLIST",
          page: sendData.get("module"),
          content: content,
          listViewPrefix: way + "/data/viewData",
          function: this,
          savedSearch: id
        };
        this.dsAdd("set", akce.listViewPrefix, {});
        this.dsAddCustom(akce);
        this.dsProcess();
      }
    );
  } else if(type == "calendar"){
    var self = this;
    const dashletId = self.dataGet(way+"/data/viewData/dashletId");
    this.dsAdd("set", way + "/data/viewData", null);
    this.dsProcess();
    
    this.load();
    this.rest.post(
      "home/getwidget",
      {search_module: "Calendar", dashletId : dashletId},
      (data) => {
        self.unLoad();
        self.dsClear();
        self.routeCalendarDahlet(way+"/data", data, dashletId);
        self.dsProcess();
      }
    );

  }
}
