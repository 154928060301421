import React from "react";
import  sAction  from "sAction";
import DefaultDatetime from '../../defaultFields/Datetime/Datetime';

export default class Datetime extends DefaultDatetime {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}

}
