export default function editFieldEditEnum(option){
    
    this.dsClear();
    this.dsAdd("set","conf/popup/show",true)
    this.dsAdd("set","conf/popup/content","editFieldsNewField")
    this.dsAdd("set","conf/popup/data/header",this.translate("LBL_CREATE_NEW_FIELD"))
    this.dsAdd("set","conf/popup/data/view","enum")
    this.dsAdd("set","conf/popup/data/optionList",[])
    this.dsAdd("set","conf/popup/data/selectOption",option)
    this.dsAdd("set","conf/popup/data/forceClose",true)
    this.dsAdd("set","conf/popup/data/lockOptionKey",true)
    const options = this.app_strings[option]
    options.forEachObject((value,key) => {

      const line = {
        label: value,
        value: key,
      };
      this.dsAdd("add","conf/popup/data/optionList", line);

    })
    this.dsAdd("set","conf/popup/data/view","enum")
    this.dsProcess();

}