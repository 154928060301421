export default function reportWindowCreateBasic(module) {
  var filter = this.reportWindowCreateFilter();
  if (filter !== false) {
    const columns = this.dataGet("reportWindow/columns/active");
    var columnsToServer = [];
    columns.forEach(column => {
      if (column.def != undefined) {
        var relationship = column.def.get("relationship");
        if (relationship != module) {
          if (
            relationship != undefined &&
            relationship != null &&
            relationship != ""
          ) {
            relationship = relationship.split("/*");
          }
        } else {
          relationship = undefined;
        }

        columnsToServer.push({
          field: column.name,
          module: column.def.get("fieldModule"),
          width: column.def.get("width"),
          relationship: relationship
        });
      }
    });

    // ________________________________________________________ Seřazení order podle priority
    const order = this.dataGet("reportWindow/orderBy");
    var orderList = [];
    var number = 0;
    order.entrySeq().forEach(e => {
      const data = e[1];
      if (data.direction != "") {
        var priority = data.priority;
        if (priority == null) {
          priority = 1;
        }

        var relationship = data.relationship;
        if (relationship != module) {
          if (relationship !== undefined && relationship !== null) {
            relationship = relationship.split("/*");
          }
        } else {
          relationship = undefined;
        }
        orderList[priority + "" + number] = {
          field: data.field,
          sort: data.direction,
          module: data.module,
          relationship: relationship
        };
      }
      number += 1;
    });
    orderList = orderList.filter(val => val);
    // ________________________________________________________ Seřazení order podle priority END

    // ________________________________________________________ Seřazení group podle priority
    this.clearNonValidGroupBy()
    const group = this.dataGet("reportWindow/groupBy");
    var groupList = [];
    number = 0;
    group.entrySeq().forEach(e => {
      const data = e[1];
      if (data.active == true) {
        var priority = data.priority;
        if (priority == null) {
          priority = 1;
        }

        var relationship = data.relationship;
        if (relationship != module) {
          if (relationship !== undefined && relationship !== null) {
            relationship = relationship.split("/*");
          }
        } else {
          relationship = undefined;
        }

        groupList[priority + "" + number] = {
          field: data.field,
          module: data.module,
          relationship: relationship
        };
      }
      number += 1;
    });
    groupList = groupList.filter(val => val);
    // ________________________________________________________ Seřazení group podle priority END

    // ________________________________________________________ Nastavení funkcí pro jednotlivé pole
    var functionList = {};
    if (groupList.length !== 0) {
      const fieldFunction = this.dataGet("reportWindow/fieldFunction");
      fieldFunction.entrySeq().forEach(e => {
        const data = e[1];
        functionList[data.field] = data;
      });
    }
    // ________________________________________________________ Nastavení funkcí pro jednotlivé pole

    // ________________________________________________________ Nastavení funkcí pro jednotlivé pole
    var alterNameList = {};
    const alterNames = this.dataGet("reportWindow/alterName");
    alterNames.entrySeq().forEach(e => {
        const data = e[1];
        alterNameList[e[0]] = data.vname;
      });
    // ________________________________________________________ Nastavení funkcí pro jednotlivé pole

    columnsToServer.forEach(column => {
      if (
        functionList[column.field] != undefined &&
        functionList[column.field].function != ""
      ) {
        column.function = functionList[column.field].function;
      } else {
        column.function = null;
      }
    });

    const searchData = {
      limit: 25,
      offset: 0,
      columns: columnsToServer,
      order: orderList,
      groupBy: groupList,
      function: functionList,
      alterName: alterNameList,
      filter
    };
    return searchData;
  } else {
    return false;
  }
}
