export default function printPrepare(params, hidePopup = false) {
  if (!this.requiredFieldsCheckMsg(params.prefix)) {
    return;
  }

  if (this.getViewName() === "detail") {
    this.printFile(params);
  } else if (this.getViewName() === "list") {
    const list = this.dataGet(params.prefix);
    var selected = list.selected;

    selected.forEach((id) => {
      // ziskame jmeno
      var name = list.rows.find((r) => r.get("key") === "NAME");
      name = name.records.find((r) => r.get("id") === id).get("value");

      this.printFile({ ...params, name, id, filetype: "pdf" });
    });
  }

  if (hidePopup) this.popupHide();
}