export default function setCustomTranslate(params,callback){
    this.load();
    this.rest.post("saveCustomTranslate",params,ret => {
        this.unLoad();
        this.popupHide();
        if(ret.status === true){
            this.moduleTranslate[params["module"]][params["vname"]] = params["translate"]
            if(callback != undefined){
                callback()
            }
        }
    })
}
