
import $ from "jquery";
import moment from "moment";
import detailDefault from "../../detailDefault";
export default class detailMeetings extends detailDefault {
  load(sAction, data) {
    var prefixInv = data.prefix + "/customData/invitees";

    if (data.view == "calendar" || data.view == "home") {
      prefixInv = data.prefix.replace("/detailView", "");
      prefixInv += "/invitees";
    }

    /*
    if(data.view == "calendar"){
      prefixInv = "calendar/invitees"
    }
    */
    this.searchInvitees(sAction, data.prefix, prefixInv);

    if (data.view != "calendar" && data.view != "home") {
      const id = sAction.dataGet(data.prefix + "/id");
      if (!id) {
        const now = new Date();
        let mins = now.getMinutes();
        let rounded = Math.round(mins / 30) * 30;
        // Dvojty zapor aby se nepricitalo - JS u pricitani stringu nefunguje
        const today = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded)));
        // - 30 kvuli defaultni delce 30 minut. Zapor kvuli tomu, ze se odcita 2x - vyhnu se pricitani stringu
        const end = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded - 30)));

        //Kontrola zda nejsou ulozene hodiny a minuty kvuli duplikaci.

        const savedHours = sAction.dataGet(data.prefix+"/fields/duration_hours/value")

        let innitValueHours = 0;
        let innitValuesMinutes = 30;

        if(savedHours) {
            innitValueHours = savedHours
            innitValuesMinutes = sAction.dataGet(data.prefix+"/fields/duration_hours/def/minutesValue")
        }

        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/date_start/value", today)
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", today)
        sAction.dsAdd("set", data.prefix + "/fields/date_end/value", end);
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", end);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", innitValueHours);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", innitValuesMinutes);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", innitValueHours);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", innitValuesMinutes);
        sAction.dsProcess();
      }
    }
    // this.locationLink(sAction, data.prefix);

    if(sAction.dataGet(data.prefix + "/fields/all_day/value") == "1"){
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", true);
      sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", true);
      sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", true);
      sAction.dsProcess();
    }
  
    this.skryvani_poli(sAction, data);

    if(sAction.dataGet(data.prefix + "/fields/parent_name/value") == null){
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/parent_name/def/parent_type_value", "");
      sAction.dsAdd("set", data.prefix + "/fields/parent_name/def/id_value", "");
      sAction.dsProcess();
    }
  
    this.changeNameBasedOnParentType(sAction, data, true);
  }

  update(sAction, data) {
    // massUpdate = return, aby to nepadalo kvuli chybejicim fieldum
    if (data.prefix === 'rightPanel/data' && sAction.dataGet("rightPanel/content") === 'massUpdateView') {
      return;
    }

    if (data.field == "parent_id") {
      // console.log("UPDATE", data.prefix);
      var prefixInv = data.prefix + "/customData/invitees";
      if (data.view == "calendar" || data.view == "home") {
        var prefixInv = data.prefix.replace("/detailView", "").replace("/meetingView", "");
        prefixInv += "/invitees";
      }
      else if (data.view != "detail") {
        return;
      }
      
      this.changeNameBasedOnParentType(sAction, data);
      
      this.searchInvitees(sAction, data.prefix, prefixInv);
      //checkni prez store(spesl funkce)


            // kontrola adresy, pokud uz je vyplnena, tak neprepisovat
            let location = sAction.dataGet(data.prefix + '/fields/location/value');
            if (!location){
                this.getAddress(sAction, data);
            }
    } else if (data.field === 'date_end') {
        let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
        start = new Date(start);
        let end = new Date(data.value);
        if (!this.checkValidDate(start, end, data, sAction)) {
            end = new Date(start.getTime());
        }
        let diff = Math.abs(end - start);
        let mins = (Math.floor(diff / (1000 * 60))) % 60;
        let hours = Math.floor(diff / (1000 * 60 * 60));
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", hours);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", mins);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", hours);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", mins);
        sAction.dsProcess();
    } else if (data.field === 'duration_hours' || data.field === 'date_start') {
        let hours = sAction.dataGet(data.prefix + "/fields/duration_hours/value");
        let mins = sAction.dataGet(data.prefix + "/fields/duration_hours/def/minutesValue");
        let start = sAction.dataGet(data.prefix + '/fields/date_start/value');

        start = new Date(start);
        let endDate = this.getFormatedDate(start.getTime() + (1000 * 60 * ((mins * 1) + (hours * 60))));

        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
        sAction.dsProcess();
    }
    else if (data.field === 'all_day') {
      if(data.value == "1"){
        let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
        let pomStart = moment(new Date(start));
        let end = sAction.dataGet(data.prefix + '/fields/date_end/value');
        if(!end){
          end = start;
        }
        let pomEnd = moment(new Date(end));
        if(pomStart.format("YYYY-MM-DD") == pomEnd.format("YYYY-MM-DD")){
          pomEnd.add(1, 'days');
        }
        pomStart.set({hour: 0, minute: 0, second: 0});
        pomEnd.set({hour: 0, minute: 0, second: 0});

        sAction.dsClear();
        let strEnd = pomEnd.format("YYYY-MM-DD HH:mm:ss");
        let strStart = pomStart.format("YYYY-MM-DD HH:mm:ss");
        
        sAction.dsAdd("set", data.prefix + "/fields/date_start/value", strStart);
        sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", true);
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", strStart);
        
        sAction.dsAdd("set", data.prefix + "/fields/date_end/value", strEnd);
        sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", true);
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", strEnd);
        
        let duration_hours = pomEnd.diff(pomStart, 'hours');
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", duration_hours);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", 0);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", duration_hours);
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", 0);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", true);


        sAction.dsProcess();
        
      }
      else{
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", false);
        sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", false);
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", false);
        sAction.dsProcess();
      }
    } else if (data.field === 'status') {
      this.skryvani_poli(sAction, data);
    } else if (data.field === 'location') {
      // this.locationLink(sAction, data.prefix);
    } else if (data.field === 'typ_schuzky') {
      this.skryvani_poli(sAction, data);
    } else if (data.field === 'osobni_c') {
      this.skryvani_poli(sAction, data);
    }
  }
  
  skryvani_poli(sAction, data) {
    sAction.dsClear();
    
    let typ_schuzky = sAction.dataGet(data.prefix + "/fields/typ_schuzky/value");
    let je_osobni = sAction.dataGet(data.prefix + "/fields/osobni_c/value");
    let status = sAction.dataGet(data.prefix + "/fields/status/value");
  
    let osobni = 0;
    let vyplnovat_segmentaci = true;
    let segmentace_povinna = status === 'Held';
  
    if (['dovolena', 'nemoc', 'lekar'].includes(typ_schuzky)) {
      osobni = 1;
  
      if (je_osobni == 0) {
        sAction.dsAdd("set", data.prefix + "/fields/osobni_c/value", osobni);
        sAction.dsAdd("set", data.prefix + "/changes/fields/osobni_c", osobni);
      }
      
    } else if (data.field === "typ_schuzky") {
      sAction.dsAdd("set", data.prefix + "/fields/osobni_c/value", osobni);
      sAction.dsAdd("set", data.prefix + "/changes/fields/osobni_c", osobni);
    }
  
    if (data.field === "osobni_c") {
      sAction.dsAdd("set", data.prefix + "/fields/osobni_c/value", data.value);
      sAction.dsAdd("set", data.prefix + "/changes/fields/osobni_c", data.value);
    }
  
    if (osobni || ['dovolena', 'nemoc', 'lekar', 'administrativa'].includes(typ_schuzky)) {
      vyplnovat_segmentaci = false;
    }
    
    sAction.dsAdd("set", data.prefix + "/fields/segmentace/def/visible", vyplnovat_segmentaci);
    sAction.dsAdd("set", data.prefix + "/fields/segmentace/def/required", vyplnovat_segmentaci && segmentace_povinna);
  
    sAction.dsAdd("set", data.prefix + "/fields/description/def/visible", vyplnovat_segmentaci);
    sAction.dsAdd("set", data.prefix + "/fields/description/def/required", vyplnovat_segmentaci && segmentace_povinna);
  
    sAction.dsAdd("set", data.prefix + "/fields/meetcust/def/visible", vyplnovat_segmentaci);
    sAction.dsAdd("set", data.prefix + "/fields/meetcust/def/required", vyplnovat_segmentaci && segmentace_povinna);
    sAction.dsProcess();
    
  }

  detailViewLoad(sAction, data) {
    console.log("VIEW LOAD", data);
  }
  detailViewUpdate(sAction, data) {
    console.log("update Detail");
  }

  /*locationLink(sAction, prefix) {

    const location = sAction.dataGet(prefix + "/fields/location");
    const value = location?.value;
    if (value != null) {
      if (value.startsWith("http") || value.startsWith("www")) {
        sAction.dataSet(prefix + "/fields/location/def/type", "url")
      }
    }
  }*/
  
  searchInvitees(sAction, prefix, prefixInv) {
    // console.log("PARENT", prefix);
    const parent = sAction.dataGet(prefix + "/fields/parent_name");
    if (parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != "") {
      sAction.searchInvitees({ parent: parent.toJS() }, prefixInv);
    }
  }

  getFormatedDate(timeStamp) {
    const date = new Date(timeStamp);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month
    }
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (day < 10) {
      day = "0" + day;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }

    return (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00");
  }

    checkValidDate(start, end, data, sAction) {
        const startStamp = start.getTime();
        const endStamp = end.getTime();

        if (endStamp < startStamp) {
            sAction.dsClear();
            const endDate = this.getFormatedDate(startStamp);
            sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
            sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
            sAction.dsProcess();
            sAction.error(sAction.translate('LBL_END_DATE_IS_HIGHER_THAN_START'));

            return false;
        } else {
            return true;
        }
    }
    
    getAddress(sAction, data) {
        const parent_id = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value");
        if (!parent_id){
            return
        }
        
        const postData = {
            recordId: parent_id,
        }
        // zeptame se na centralu firmy z tyka se
        sAction.rest.post("getAccountsGPS", postData, returnData => {
            if (returnData.status) { // ma centralu, tak vytvorime jmeno z centraly ... centrala se pak doplni v logic hooku
                sAction.dsClear();
                
                sAction.dsAdd("set", data.prefix + "/fields/location/value", returnData.message.data.address);
                sAction.dsAdd("set", data.prefix + "/fields/location_gpsx/value", returnData.message.data.address_gpsx);
                sAction.dsAdd("set", data.prefix + "/fields/location_gpsy/value", returnData.message.data.address_gpsy);
                sAction.dsAdd("set", data.prefix + "/fields/location_kraj/value", returnData.message.data.address_kraj);
                sAction.dsAdd("set", data.prefix + "/fields/location_obec/value", returnData.message.data.address_obec);
                sAction.dsAdd("set", data.prefix + "/fields/location_okres/value", returnData.message.data.address_okres);
                sAction.dsAdd("set", data.prefix + "/fields/location_psc/value", returnData.message.data.address_psc);
                sAction.dsAdd("set", data.prefix + "/fields/location_street/value", returnData.message.data.address_street);
                
                let locationItem = { // pro zobrazeni pred ulozenim
                    gpsx: returnData.message.data.address_gpsx,
                    gpsy: returnData.message.data.address_gpsy,
                    kraj: returnData.message.data.address_kraj,
                    obec: returnData.message.data.address_obec,
                    okres: returnData.message.data.address_okres,
                    psc: returnData.message.data.address_psc,
                }
                sAction.dsAdd("set", data.prefix + "/fields/location/def/item", locationItem);
                
                sAction.dsAdd("set", data.prefix + "/changes/fields/location", returnData.message.data.address);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_gpsx", returnData.message.data.address_gpsx);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_gpsy", returnData.message.data.address_gpsy);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_kraj", returnData.message.data.address_kraj);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_obec", returnData.message.data.address_obec);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_okres", returnData.message.data.address_okres);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_psc", returnData.message.data.address_psc);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_street", returnData.message.data.address_street);
                
                sAction.dsProcess();
            }
            else{ // nema centralu, tak pouzijeme nazev vybrane firmy z tyka se
                sAction.dsAdd("set", data.prefix + "/fields/name/value", typeTranslate + ' - ' + parent_name);
                sAction.dsAdd("set", data.prefix + "/changes/fields/name", typeTranslate + ' - ' + parent_name);
            }
        }, false)
    }
  
  changeNameBasedOnParentType(sAction, data, onLoad = false) {
    const name = sAction.dataGet(data.prefix + "/fields/name/value");
    const parent_name = sAction.dataGet(data.prefix + "/fields/parent_name/value") || "";
    let changeName = () => {
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/name/value", parent_name);
      sAction.dsAdd("set", data.prefix + "/changes/fields/name", parent_name);
      sAction.dsProcess();
    }
    if (!name) {
      changeName();
    } else if (parent_name && !onLoad) {
      if (window.confirm("Chcete změnit i název záznamu z '" + name + "' na '" + parent_name + "'?")) {
        changeName();
      }
    }
  }
}
