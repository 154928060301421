import React from "react";
import FilterBasic from "./filterBasic";

import sAction from "sAction";
import Select from "../../formElements/Select";
import InputText from "../../formElements/InputText";

export default class FilterParent extends FilterBasic {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      parent_type: null,
    };
    this.customOperator = React.createRef();
  }
  componentDidMount() {
    //this.props.parent.advanced();
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    if (prefix == way) {
      sAction.dsClear();
      sAction.dsAdd(
        "add",
        prefix + "/filter",
        { field: data.get("name"), operator: "and", actCustom: true },
        "filterGroup"
      );
      sAction.dsAdd(
        "add",
        prefix + "/filter/" + index + "/filters",
        { type: null, value: "" },
        "filterParameter"
      );
      sAction.dsProcess();
    }

    sAction.load();
    sAction.getParentFieldOptions(data.get("fieldModule"), (returnData) => {
      sAction.unLoad();
      var options = [{ value: "", label: "" }];
      returnData.forEach((option) => {
        options.push({
          value: option,
          label: sAction.app_strings["moduleList"][option],
        });
      });
      this.setState({
        options: options,
      });
    });

    var parent_type = null
    const actFilter = this.props.actFilter;
    if (actFilter != null) {
      actFilter.filters.forEach((line, key) => {
       parent_type = line.get("parent_type")
      });
    }
    if(parent_type != null){
      this.setState({
        parent_type: parent_type
      })
    }
  }

  addCustomFilterLineToView() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    if (prefix == way) {
      sAction.dsClear();
      sAction.dsAdd(
        "add",
        prefix + "/filter",
        { field: data.get("name"), operator: "and", actCustom: true },
        "filterGroup"
      );
      sAction.dsAdd(
        "add",
        prefix + "/filter/" + index + "/filters",
        { type: null, value: "" },
        "filterParameter"
      );
      sAction.dsProcess();
    } else {
      sAction.dsClear();
      sAction.dsAdd(
        "add",
        way + "/filters",
        { type: null, value: "" },
        "filterParameter"
      );
      sAction.dsProcess();
    }
  }
  deleteCustomFilterLineFromView(key) {
    const way = this.props.way;
    sAction.dataDelete(way + "/filters", key);
  }

  customOption() {
    this.props.parent.advanced();
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    if (prefix == way) {
      sAction.dsClear();
      sAction.dsAdd(
        "add",
        prefix + "/filter",
        { field: data.get("name"), operator: "and", actCustom: true },
        "filterGroup"
      );
      sAction.dsAdd(
        "add",
        prefix + "/filter/" + index + "/filters",
        { type: null, value: "" },
        "filterParameter"
      );
      sAction.dsProcess();
    }
  }

  addCustomFilter(field) {
    const prefix = this.props.prefix;
    var filters = new Array();
    var lines = this.getLines();
    lines.forEach((line) => {
      const select = line.select;
      const text = line.input;
      var filter = {
        field: field,
        type: select,
        value: text,
        parent_type: this.state.parent_type
      };
      filters.push(filter);
    });
    const operator = this.customOperator.current.value;
    const data = {
      field,
      filters,
      operator,
      prefix,
      reload: true,
    };
    sAction.addFilter(data);
  }

  render() {
    const data = this.props.data;
    const actFilter = this.props.actFilter;
    console.log(actFilter);
    var custom = null;
    var customLines = [];
    if (actFilter != null) {
      actFilter.filters.forEach((line, key) => {
        customLines.push(
          <div className="filterLine" key={key}>
            <Select
              key={actFilter.field + "_sekect"}
              defaultValue={line.type}
              options={sAction.getVarcharFilterOptions()}
              onChange={(e) => this.updateLines(key, e.target.value, "select")}
            />
            <InputText
              key={actFilter.field + "_input"}
              onKeyUp={(e) => this.customInputKeyUp(e)}
              type="text"
              defaultValue={line.value}
              onChange={(e) => this.updateLines(key, e.target.value, "input")}
            />
            {customLines.length != 0 && (
              <div
                className="customFilterDeleteRow iconCancel"
                onClick={() => this.deleteCustomFilterLineFromView(key)}
              />
            )}
          </div>
        );
      });

      custom = (
        <>
          <div className="CustomFilterLinesContent">
            <div className="CustomFilterLines">
              <div ref="customLines">{customLines}</div>
              <div
                className="filterLine customFilterAddLineButton"
                onClick={() => this.addCustomFilterLineToView()}
              >
                +
              </div>
            </div>
            <div className="customFilterLinesOperator">
              <Select
                key={actFilter.field + "_groupSelect"}
                defaultValue={actFilter.operator}
                myRef={this.customOperator}
                options={[{value: 'and', label: sAction.translate("LBL_AND")},{value: 'or', label: sAction.translate("LBL_OR")}]}
              />
            </div>
          </div>

          <div
            tabIndex="2"
            onClick={() => this.addCustomFilter(data.get("name"))}
            className="filterCustomFilterButton"
          >
            {sAction.translate("LBL_LIST_FILTER")}
          </div>
        </>
      );
    }

    var removeFilterButton = null;
    if (actFilter != null) {
      removeFilterButton = (
        <div
          onClick={() => this.removeFilter(data.get("name"))}
          className="filterButton cancelFilterButton"
        >
          {sAction.translate("LBL_DELETE_FILTER")}
        </div>
      );
    }

    return (
      <div>
        <div className="customFilter">
          <div className="customFilterheader">
            {sAction.translate("LBL_CUSTOM_FILTER")}
          </div>
          <div className="customFilterParentModuleSelectContainer">
            <div >{sAction.translate("LBL_LIST_RELATED_TO",data.get("fieldModule"))}</div>
            <Select options={this.state.options}
            value={this.state.parent_type}
            onChange={(e) => this.setState({parent_type:e.target.value})}
             />
          </div>
          {custom}
        </div>
        {removeFilterButton}
      </div>
    );
  }
}
