import detailDefault from "../../detailDefault";

export default class detailUsers extends detailDefault {
  load(sAction, data) {
  }

  update(sAction, data) {
    switch (data.field) {
      case "user_name":
        let sendData = {
          id: sAction.dataGet(data.prefix + "/id"),
          user_name: data.value
        };
        sAction.rest.post("checkUsername", sendData, (returnData) => {
          if (returnData && returnData.found) {
            let text = sAction.translate("ERR_USER_NAME_EXISTS_1", "Users") + " '" + data.value + "' " + sAction.translate("ERR_USER_NAME_EXISTS_2", "Users");
            sAction.error(text);
            sAction.saveField({
              name: "user_name",
              value: "",
              way: data.prefix + "/fields/user_name"
            });
          }
        });
        break;
    }
  }
}
