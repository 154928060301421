import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";

import  sAction  from "sAction";

export default class DynamicEnumPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      key: true,
      value: true
    };

    this.key = React.createRef();
    this.value = React.createRef();
  }
  addNewLine() {
    var data = this.props.data;
    const actLang = sAction.dataGet("conf/language");
    const keyInput = this.key.current;
    const valueInput = this.value.current;
    const parent = data.get("parent");
    const key = keyInput.value;
    const value = valueInput.value;
    var newState = {};
    var patt = new RegExp(/^[a-z_0-9]+$/);
    if (!patt.test(key)) {
      newState["key"] = false;
    } else {
      newState["key"] = true;
    }

    if (value == "") {
      newState["value"] = false;
    } else {
      newState["value"] = true;
    }
    this.setState(newState);

    if (newState["value"] == true && newState["key"] == true) {
      var language = {};
      language[actLang] = value;
      const param = {
        key: key,
        language: language,
        parent: parent,
        returnJson: false
      };
      sAction.load();
      sAction.adminXHR("DynamicEnum/newLine", param, returnData => {
        const rData = sAction.fromJSON(returnData);
        if (rData.state) {
          sAction.reloadList(0, 1, this.props.way + "/data/listView");
          keyInput.value = "";
          valueInput.value = "";
          sAction.app_strings["dynamicEnum"][parent][key] = value;
        }
      });
    }
  }
  createKey() {
    const keyInput = this.key.current;
    var key = keyInput.value;

    key = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    key = key.replace(/ /g, "_");
    key = key.replace(/-/g, "_");
    key = key.toLowerCase();

    keyInput.value = key;
  }
  selectValue(value) {
    const data = this.props.data;
    var callback = data.get("callback");

    if (callback != undefined) {
      callback(value);
    }else{
      console.log("není definován callback");
    }
  }
  render() {
    const data = this.props.data;
    const lines = data.get("lines");
    const actLang = sAction.dataGet("conf/language");
    var linesRender = [];
    lines.forEach((line,index) => {
      const json = line.get("language_json");
      const lang = sAction.fromJSON(sAction.htmlDecode(json));
      var value = lang[actLang];
      if (value == undefined || value == "" || value == null) {
        value = line.get("value");
      }
      linesRender.push(
        <div onClick={() => this.selectValue(line.get("value"))} className="dynamicEnumPopupLine" key={index}>
          {value}
        </div>
      );
    });
    return (
      <div className="dynamicEnumPopupContainer">
        <div className="dynamicEnumPopupLineContainer">{linesRender}</div>
        {data.get("canAddNew") &&
        <div className="dynamicEnumPopupForm">
          <InputText
            onBlur={() => this.createKey()}
            error={!this.state.key}
            myRef={this.key}
            placeholder="Klíč"
          />
          <div className="delimiterForm">{"=>"}</div>
          <InputText
            error={!this.state.value}
            myRef={this.value}
            placeholder="Hodnota"
          />
          <Button className="stillHover" onClick={() => this.addNewLine()}>
            {"Přidat"}
          </Button>
        </div>
        }
      </div>
    );
  }
}
