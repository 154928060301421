export default function confrim(message, callback) {
  const data = {
    // Potvrzení
    header: this.translate("LBL_CONFIRM"),
    //  color:"red",
    buttons: [
      // Zrušit
      { label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide() },

      // Pokračovat
      { label: this.translate("LBL_CONTINUE", "Home"), callback: callback },
    ],
  };

  this.popup(message, data);
}
