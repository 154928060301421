import React from "react"
import PureComponent from "../../pure"
import  sAction  from "sAction";


import InputText from "../../formElements/InputText"
import { EmailPopupButtons } from "./shared/EmailPopupButtons";

// import InputText from "../formElements/InputText";

export default class EmailFolderRename extends PureComponent {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
  }

  //ulozeni zmen v popupu
  savePopup = () => {
    const name = this.nameRef.current.value;

    if (!name) {
      // alert("Nebyl zadán žádný název");
      sAction.error(sAction.translate("ERR_NO_SUBJECT","Emails"));
      return;
    }

    sAction.popupHide();

    let {id, ieId, mbox} = this.props.data;

    if (!mbox) mbox = "";

    let folderData;
    if (ieId !== "folder") {
      folderData = {
        ieId,
        oldFolderName: mbox,
        newFolderName: mbox.replace(/\.[^\.]+$/,".")+name
      }
    } else {
      folderData = {
        folderId: id,
        newFolderName: name
      }
    }

    sAction.load();
    sAction.rest.post("email/renameFolder", folderData, (data) => {
      sAction.routeEmail();
    });
  }

  render() {
    return (
      <React.Fragment>
        {/* Přejmenovat složku */}
        <div className="acmPopupHeader">{sAction.translate("LBL_EMAIL_FOLDERS_RENAME_DIALOG_TITLE")}</div>

        <div className="acmPopupContent">
          <div className="w100">
          <InputText
            autoFocus
            margin="dense"
            id="name"
            label={sAction.translate("LBL_ENTER_NAME","Home")} // Zadejte název
            type="text"
            inputRef={this.nameRef}
            // defaultValue={this.props.name}
            fullWidth
            onKeyUp={ev => {
              if (ev.key === "Enter") {
                ev.preventDefault()
                this.savePopup();
              }
            }}
          />
        </div>
        </div>
        <EmailPopupButtons onSave={this.savePopup} />

      </React.Fragment>
    );
  }
}
