import React from "react";
import  sAction  from "sAction";
export default function Name(props) {
  var lineRender = null;
  let showEyeIcon = true;

  const value = sAction.decodeHTMLEntities(props.value);

  if (props.module != "acm_coripoReports") {
    if (sAction.hasAccess(props.module, "detail") && props.disabled !== true) {
      lineRender = (
        <a
          className="strong"
          data-test="name"
          title={value}
          onClick={(ev) => props.callback(ev)}
          href={"#detail/" + props.module + "/" + props.id}
        >
          {value}
        </a>
      );

      if (props.inRightPanel) {
        showEyeIcon = false;
      }

    } else {
      lineRender = <span title={value}>{value}</span>;
      showEyeIcon = false;
    }
  } else {
    lineRender = (
      <a 
        onClick={(e) =>{e.stopPropagation();}}
        className="strong"
        title={value}
        href={"#customReports/" + props.id}
      >
        {value}
      </a>
    );
  }
  return (
    <div
      className={"newListViewLine " + props.extraClass}
      onClick={props.onClick}
    >
      {showEyeIcon && (
        <div
          onClick={() => props.eyeCallBack()}
          className="icon-eye listViewEyeIcon"
        />
      )}
      {lineRender}
    </div>
  );
}
