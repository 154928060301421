
import $ from 'jquery'

export default function fixPositionAdvancedFilter(content,container){

    let newWidth = 380;
    var container = $(container);
    var parent = container.closest(".listViewInner");
    parent = container.closest(".newListView");
    var parentOffset = parent.offset().left;
    var containerOffset = container.offset().left;
    var containerLeft = parseInt(container.css("left"));
    if(containerLeft < 0){
        containerLeft = -(containerLeft);
    }
    var newOffset = (containerOffset + containerLeft) - (newWidth/2)
    if(newOffset > parentOffset){
        container.css("width",newWidth+"px");
        container.css("left",-((newWidth/2)-7)+"px")
    }else{
        $(content).css("width",newWidth+"px");
    }

    containerOffset = container.offset().left;
    var icon = container.closest(".listViewHeadericonContainer");
    var iconOffset = icon.offset().left


    containerLeft = iconOffset - parentOffset - newWidth/2;
    var triangle = container.children(".triangle");
    var triangleLeft = newWidth/2 - 13;

    if(containerLeft < 10){
        triangleLeft += containerLeft;
        triangleLeft -= 13;
        containerLeft = 10;
    }

    
    triangle.css("left",triangleLeft+"px");

    containerLeft = iconOffset - parentOffset - newWidth/2;
    if(containerLeft < 10){
        containerLeft = 10;
    }
    container.css("left",containerLeft +"px");
   // container.style.width = newWidth+"px";
   // container.style.left ="-"+((newWidth/2)-7)+"px";
}