import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailacm_salesplan extends detailDefault{
    load(sAction,data){
        $("#whole_year").closest(".detailViwRow").css("visibility", "hidden");
    }
    update(sAction,data){
        if(data.field == "month"){
            const record = sAction.dataGet(data.prefix+"/id");
            if(record){
                return;
            }
            if(data.value === "1"){
                $("#whole_year").closest(".detailViwRow").css("visibility", "visible");
            }
            else{
                $("#whole_year").closest(".detailViwRow").css("visibility", "hidden");
                sAction.dataSet(data.prefix+"/fields/whole_year/value", 0);
                sAction.dataSet(data.prefix+"/changes/fields/whole_year", 0);
                $("#whole_year_warning_msg").remove();
            }
        }
        if(data.field == "whole_year"){
            if(data.value == 1){
                $("#whole_year").after($("<span>", {id: "whole_year_warning_msg", class : "required", text : sAction.translate("LBL_WHOLE_YEAR_WARNING", "acm_salesplan")}));
            }
            else{
                $("#whole_year_warning_msg").remove();
            }
        }
    }
}