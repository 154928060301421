import DefaultInputField from '../defaultFields/inputFieldClass';
import  sAction  from "sAction";

export default class InputField extends DefaultInputField {

  saveField(data) {
    sAction.saveField(data);
  }

}
