import React from "react";
import moment from "moment";
import  sAction  from "sAction";
import DefaultDatetime from '../../defaultFields/Datetime/Datetime';

export default class Datetime extends DefaultDatetime {

  saveField(data) {
    sAction.saveField(data);
  }

}
