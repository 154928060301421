import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";

import sAction from "sAction";

export default class ParentField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      parent_type: null,
    };
    sAction.load();
    sAction.getParentFieldOptions(this.props.module, (returnData) => {
      sAction.unLoad();
      var options = [{ value: "", label: "" }];
      returnData.forEach((option) => {
        options.push({
          value: option,
          label: sAction.app_strings["moduleList"][option],
        });
      });
      this.setState({
        options: options,
      });
    });
    this.headerInput = React.createRef();
  }

  waitForSearch(e) {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
    var self = this;
    this.interval = setInterval(() => {
      this.props.onChange(e, { parent_type: this.state.parent_type });
      clearInterval(self.interval);
    }, 300);
  }
  changeParentType(value){
    this.setState({ parent_type: value })
    console.log(this.headerInput);
    this.props.onChange(this.headerInput.current.value, { parent_type: value });
  }

  render() {
    const type = this.props.type ? this.props.type : "text";

    const defaultValue = this.props.defaultValue ?? {};
    console.log(defaultValue)
    return (
      <>
        <Select
          label={this.props.label}
          options={this.state.options}
          onChange={(e) => this.changeParentType(e.target.value)}
          defaultValue={defaultValue.parent_type ?? ""}
        />
        <InputText
          type={type}
          myRef={this.headerInput}
          label={sAction.translate("LBL_NAME")}
          onChange={(e) => this.waitForSearch(e.target.value)}
          defaultValue={defaultValue.string ?? ""}
        />
      </>
    );
  }
}
