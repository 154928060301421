import React from 'react';
import PureComponent from '../pure'
import ViewPanelMenu from '../viewPanelMenu/viewPanelMenu'
import CalHeader from './calHeader'
import CalFilters from './calFilters'
import moment from 'moment';

import AcmDate from '../formElements/AcmDate';
import Button from "../formElements/Button";
import  sAction  from "sAction";


class CalPageContainer extends PureComponent{
    constructor(){
      super();

      this.state = {
        cssSelector :"acmFullCalendar",
        openPicker: false
      }
    }

    componentDidMount() {
      sAction.calInit(this.state.cssSelector, this.props.language);
    }

    componentDidUpdate(prevProps){
      //kontroluj, zda se zmenil sharedView - pokud ano, prenacti eventy....
    }

    componentWillUnmount(){
      sAction.calBulkActions("."+this.state.cssSelector, "destroy", null, false);        
    }
    
    fncGoLeft(){
      sAction.calBulkActions("."+this.state.cssSelector, "prev", null, true);
    }

    fncGoRight(){
      sAction.calBulkActions("."+this.state.cssSelector, "next", null, true);
    }

    fncToday(){
      sAction.calBulkActions("."+this.state.cssSelector, "today", null, true);
    }

    toggleDatePicker(){
      this.setState({openPicker : !this.state.openPicker});
    }

    hidePicker(){
      this.setState({openPicker : false});
    }

    DatePickerHandle(date){
      this.setState({openPicker : false});
      sAction.calBulkActions("."+this.state.cssSelector, "gotoDate", date, true);
    }

    render(){
      const data = this.props.data;
      const sharedView = data.toJS().sharedView;

      let classBoard = " calFiltWidth";
      /*
      const filt = localStorage.getItem('acmCalFiltersVisibility');
      if(filt == 'hidden'){
        classBoard = " calFullWidth";
      }
      */
      
      if(!data.filters.filtersDisplayed){
        classBoard = " calFullWidth";
      }
      
      let classPom = "";
      if(sharedView){
        classPom = "sharedViewContainer";
      }
      else{
        classPom = "notSharedViewContainer";
      }

      var picker = <AcmDate
      autoOpen={true}
            value={new Date()}
            onChange={(d) => this.DatePickerHandle(d)}
            />

        if(this.state.openPicker == false){
          picker = null;
        }
      return(
        <div>
          <div className="calPageContainer detailCard">
              <CalHeader calCssSelector={"."+this.state.cssSelector} fncGoToDate={this.callGoToDate} sharedView={sharedView} title={data.title} setting={data.setting}/>
              <CalFilters data={data.filters} />
            <div className={"calBoard"+classBoard}>
              <div className="calBoardHeader">
                <div className="calBoardHeaderLeft calBoardHeaderThird">
                  <div className="icon-calendarIcon calHeaderIcon cursor-pointer" onClick={() => this.toggleDatePicker()}></div>
                    <div className="calDatePickerContainer" >{picker}</div>
                    <Button variant="text" className="calendarButton calHeaderIcon" onClick={() => this.fncToday()}>{sAction.translate("LNK_VIEW_CALENDAR", "Calendar")}</Button>
                </div>
                <div className="calBoardHeaderCenter calBoardHeaderThird">
                  <div className="icon-CalArrowRightIcon calHeaderIcon" onClick={() => this.fncGoLeft()}></div>
                  <div className="calTitle">
                    {data.title}
                  </div>
                  <div className="icon-CalArrowLeftIcon calHeaderIcon" onClick={() => this.fncGoRight()}></div>
                </div>
                <div className="calBoardHeaderThird">
                </div>
              </div>
              <div id="calPageContainer" className={classPom}>
              </div>
            </div>
          </div>
        </div>)
      }
  }
  export default CalPageContainer
