import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";
import sAction from "sAction";

export default class CustomModuleTranslate extends PureComponent {
  constructor(props) {
    super(props);
    this.valueInput = React.createRef();

    this.state = {
      valueError: false,
    };
  }
  keyUp(e){
    if(e.keyCode == 13){
      this.save();
    }
  }
  save() {
    const data = this.props.data;

    const translate = this.valueInput.current.value;
    const vname = data.get("vname");
    const module = data.get("module");

    if(data.get("callback") != undefined){
      data.get("callback")({translate,vname,module})
    }
  }

  render() {
    const data = this.props.data;

    return (
      <div className="exportListPopupContent">
        <div className="simpleForm">
          <table>
            <tbody>
              <tr>
                <td>{sAction.translate("LBL_NEW_FIELD_NAME")}</td>
                <td>
                  <InputText
                    onKeyUp={(e) => this.keyUp(e)}
                    myRef={this.valueInput}
                    autoFocus={true}
                    defaultValue={sAction.translate(
                      data.get("vname"),
                      data.get("module")
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="flexInLine flexRight">
                    <Button onClick={() => this.save()}>{sAction.translate("LBL_SAVE_RECORD")}</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
