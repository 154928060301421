export default function customDataSortByDnD(way, module) {
	const recordsWay = way + "/records";
	const changesWay = way.replace("/customData", "/changes/customData");
	const records = this.dataGet(recordsWay);
	this.dsClear();
	this.dsAdd('set', changesWay + "/module", module);
	this.dsAdd('set', way + "/sortedBy/name", null);
	this.dsAdd('set', way + "/sortedBy/desc", false);
	records.forEach((record, index) => {
		this.dsAdd('set', recordsWay + "/" + index + "/order_index", index);
		this.dsAdd('set', changesWay + "/records/" + index + "/order_index_c", index);
		this.dsAdd('set', changesWay + "/records/" + index + "/id", record.get("id"));
	});
	this.dsProcess();
}