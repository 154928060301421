import React from "react";
import PureComponent from "../../pure";
import Select from '../../formElements/Select';
import Button from "../../formElements/Button";
import loginAs from "../../../action/Base/loginAs";

import  sAction  from "sAction";

export default class LoginAsRegUserPopup extends PureComponent {
    constructor(props){
        super(props);
        var defValue = "";
        const users = props.data.get("users");
        
        if(users.size > 0){
            defValue = users.get(0).get("id");
        }

        this.state = {
            user: defValue
        }
    }

    handleLoginIn(){
        const id = this.state.user;
        if(!id){
            sAction.error("Invalid data.");
            return;
        }
        sAction.popupHide();
        sAction.loginAs({id : id});
        
    }

    render(){
        const data = this.props.data;
        const users = data.get("users");
        var userOptions = [];
        users.forEach(user =>{
            userOptions.push({value: user.get("id"), label: user.get("name")});
        });

        return <div>
        <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={() => this.handleLoginIn()}>
                <div className={"icon-saveIcon calHeaderIcon"} ></div>
                {sAction.translate("LBL_CONFIRM")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
        <div className="templateCreatorFormContainer">
            <div className="quoteTemplNameLbl">
            {sAction.translate("LBL_NAME", "Users")}:
            </div>
        <Select 
            open={false} 
            name="user_name"
            options={userOptions} 
            defaultValue={this.state.user}
            onChange={(ev) => this.setState({user : ev.target.value})} 
        />
        </div>
    </div>;
    }
}