export default function deleteRecordSubpanel(newRecord, subpanel) {
  var recModule = subpanel.module
  var relid = newRecord.record.record
  if (recModule == "Activities" || recModule == "History") {
    recModule = newRecord.record.module;
  }

  this.confrim(this.translate("LBL_BUTTON_CONFIRM_DELETE_RECORD"), () => {
    this.rest.delete(
      "delete/" + recModule + "/" + relid,
      null,
      (returnData) => {
        if (!returnData.status) {
          this.reloadSubpanelByWay("view", subpanel.module);
          this.popupHide();
        } else {
          this.error("Špatný návrat ze serveru");
        }
      }
    );
  });
}
