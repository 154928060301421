export default function htmlEscape(text, real = false) {
  if (text == null) return "";

  let map;
  if (real) {
    map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
  } else {
    map = {
      '&': '&amp;',
      '<': '(',
      '>': ')',
      '"': '&quot;',
      "'": '&#039;'
    };
  }

  return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}
