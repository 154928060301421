import detailDefault from "../../detailDefault";

export default class detailacm_orders_lines extends detailDefault {
    load(sAction, data) {
        const type = sAction.dataGet(data.prefix + "/fields/type/value");
        if (type === "skupina") {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/category_sale/def/required", true);
            sAction.dsAdd("set", data.prefix + "/fields/productcategories_acm_pricelist_1_name/def/required", true);
            sAction.dsProcess();
        }
    }
    
    update(sAction, data) {
        switch (data.field) {
            case 'type':
                let required = data.value === "skupina";
                sAction.dsAdd("set", data.prefix + "/fields/category_sale/def/required", required);
                sAction.dsAdd("set", data.prefix + "/fields/productcategories_acm_pricelist_1_name/def/required", required);
                sAction.dsProcess();
                break;
        }
    }
}

