import React from "react";
import ChartJS from "./chartjs/ChartJS";

var delays = 80;
var durations = 500;

class GenerateChart extends React.PureComponent {
  render() {
    if (this.props.data.get("viewData") == null) {
      return <div>Špatná data pro graf</div>;
    }
    var data = this.props.data.get("viewData").toJS();
    data = data.data;
    const chartData = data.chartData;
    const subtype = data.chartType;
    const source = data.library;
    const options = data.options;

    //TODO: neanimovat, pokud uz stranka byla 1 nactena... (viz Drag & Drop)

    switch (source) {
    /*  case "chartist":
        const animation = {
          draw: data => {
            if (data.type === "line") {
              // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
              data.element.animate({
                opacity: {
                  // The delay when we like to start the animation
                  begin: data.index * delays + 1000,
                  // Duration of the animation
                  dur: durations,
                  // The value where the animation should start
                  from: 0,
                  // The value where it should end
                  to: 1
                }
              });
            } else if (data.type === "label" && data.axis === "x") {
              data.element.animate({
                y: {
                  begin: data.index * delays,
                  dur: durations,
                  from: data.y + 100,
                  to: data.y,
                  // We can specify an easing function from Chartist.Svg.Easing
                  easing: "easeOutQuart"
                }
              });
            } else if (data.type === "label" && data.axis === "y") {
              data.element.animate({
                x: {
                  begin: data.index * delays,
                  dur: durations,
                  from: data.x - 100,
                  to: data.x,
                  easing: "easeOutQuart"
                }
              });
            } else if (data.type === "point") {
              data.element.animate({
                x1: {
                  begin: data.index * delays,
                  dur: durations,
                  from: data.x - 10,
                  to: data.x,
                  easing: "easeOutQuart"
                },
                x2: {
                  begin: data.index * delays,
                  dur: durations,
                  from: data.x - 10,
                  to: data.x,
                  easing: "easeOutQuart"
                },
                opacity: {
                  begin: data.index * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: "easeOutQuart"
                }
              });
            } else if (data.type === "grid") {
              // Using data.axis we get x or y which we can use to construct our animation definition objects
              var pos1Animation = {
                begin: data.index * delays,
                dur: durations,
                from: data[data.axis.units.pos + "1"] - 30,
                to: data[data.axis.units.pos + "1"],
                easing: "easeOutQuart"
              };

              var pos2Animation = {
                begin: data.index * delays,
                dur: durations,
                from: data[data.axis.units.pos + "2"] - 100,
                to: data[data.axis.units.pos + "2"],
                easing: "easeOutQuart"
              };

              var animations = {};
              animations[data.axis.units.pos + "1"] = pos1Animation;
              animations[data.axis.units.pos + "2"] = pos2Animation;
              animations["opacity"] = {
                begin: data.index * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: "easeOutQuart"
              };

              data.element.animate(animations);
            }

            /*
          
          if (data.type === "bar") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * 50,
                dur: 500,
                from: 0,
                to: 1,
                easing: "ease"
              }
            });
          } else if (data.type === "line" || data.type === "area") {
            data.element.animate({
              d: {
                begin: 500,
                dur: 500,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: "ease"
              }
            });
          } else if (data.type === "point") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * 50,
                dur: 500,
                from: 0,
                to: 1,
                easing: "ease"
              }
            });
          }




         
          }
        };

        var newPlugins = [];
        if (options.plugins != undefined) {
          options.plugins.forEach((name, key) => {
            if (name == "tooltip") {
              newPlugins.push(tooltip());
            }
          });
        }
        options.plugins = newPlugins;

        return (
          <Chartist
            key={Math.random()}
            data={chartData}
            type={subtype}
            options={options}
            listener={animation}
          />
        );
 */
      case "chartjs":
        return (
          <ChartJS
            key={Math.random()}
            data={chartData}
            type={subtype}
            options={options}
          />
        );

      default:
        return "Chyba: U grafu chybí source";
    }
  }
}

export default GenerateChart;
