

export default function dataArrayAdd(data){

    const akce = {
        type: "DATAADD",
        data: data
    }
    this.dataDispatch(akce)
    

}