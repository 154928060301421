export default function findDuplicates(way, mapping, additionalMapping) {
    let duplicateMappings = false;
    let mappingValues = {};

    mapping.forEachObject((value, key) => {
        if (mappingValues.hasOwnProperty(value)) {
            this.dsAdd('set', way + '/table/header/mapping/duplicates/' + key, true);
            this.dsAdd('set', way + '/table/header/mapping/duplicates/' + mappingValues[value], true);

            duplicateMappings = true;
        }

        mappingValues[value] = key;
    })

    let additionalMappingValues = {};
    additionalMapping.forEach((value, key) => {
        if (additionalMappingValues.hasOwnProperty(value.field)) {
            this.dsAdd('set', way + '/additionalData/' + key + '/duplicate', true);
            this.dsAdd('set', way + '/additionalData/' + additionalMappingValues[value.field] + '/duplicate', true);

            duplicateMappings = true;
        }

        if (mappingValues.hasOwnProperty(value.field)) {
            this.dsAdd('set', way + '/additionalData/' + key + '/duplicate', true);
            this.dsAdd('set', way + '/table/header/mapping/duplicates/' + mappingValues[value.field], true);

            duplicateMappings = true;
        }

        additionalMappingValues[value.field] = key;
    })

    return duplicateMappings;
}