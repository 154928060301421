export default function editDetailDeleteTab(e,tab,way,index){
    e.stopPropagation()

    this.dsClear();
    tab.rows.forEach(row => {
        if(row.get("type") != "headerPanel"){
            row.entrySeq().forEach(e => {
                const field = e[1]
                if(field != "empty_row"){
                    console.log(field)
                    this.dsAdd("add",way+"/hiddenFields",field)
                }
            })
        }
    })
    this.dsAdd("delete",way+"/tabs",index)
    this.dsProcess();

}