export default function setNotification(id,data,index){
    this.rest.post("set/acm_coripoNotify/"+id,{fields:data},returnData => {
        if(returnData.record == id){
            data.forEachObject((value,key) => {
                if(key == "seen"){
                    this.dataSet("notifications/list/"+index+"/seen",value);
                }
                if(key == "deleted"){
                    this.dataDelete("notifications/list",index);
                }
            })
        }
    });
}