import React, { Component } from "react";
import MaterialButton from '@material-ui/core/Button'
export default class Button extends React.PureComponent{
    onClick(e){
        if(this.props.onClick != undefined){
            this.props.onClick(e);
        }
    }
    render(){

    const {className, ...props} = this.props;
    return(
        <MaterialButton
        onClick={(e) => this.onClick(e)}
        variant="contained" className={"acmButton "+className} {...props}>
            {this.props.children}
        </MaterialButton>
    )
    }
}