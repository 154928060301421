export default function HomeTabOrderChange(way, order) {
  if (order < 0) {
    order = 0;
  }
  
  let tabs = way.replace(/\/tabs.*/, "/tabs");
  
  let oldIndex = way.slice(way.lastIndexOf("/") + 1);
  
  let state = this.dataGet(tabs);
  if (!state) {
    return;
  }
  
  state = state.delete(oldIndex).insert(order, state.get(oldIndex));
  
  this.dsClear();
  this.dsAdd("set", way.substr(0, way.indexOf("/")) + "/activeId", order);
  this.dsAdd("set", tabs, state);
  this.dsProcess();
  this.setStorage("homeActiveTab", order);
}
