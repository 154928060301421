import React, { PureComponent } from "react"
import Button from "../../../formElements/Button"
import  sAction  from "sAction";

export class EmailPopupActionPanel extends PureComponent {
  hidePopup = () => {
    sAction.popupHide();
  }
  render() {
    return (
      <div className="viewActionPanel borderBottom">
        <div className="viewActionPanelButtons">
          <Button className="hoverGreen" onClick={this.props.onSave}>
            <div className="actionPanelButtonIcon icon-saveIcon" /> {sAction.translate('LBL_SAVE', 'Home')} {/* Uložit */}
          </Button>
          <div className="viewPanelButtonsDelimiter"/>
          <Button className="hoverRed" onClick={this.hidePopup}>
            <div className="actionPanelButtonIcon icon-deleteIcon" /> {sAction.translate('LBL_CANCEL', 'Home')} {/* Zrušit */}
          </Button>
        </div>
      </div>
    )
  }
}