import React from 'react'
import Loader from "ROOT/src/components/loader";

export default class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }
  
  onLoad = () => {
    this.setState({loaded: true})
  }
  
  render() {
    const data = this.props.data;
    return (
      <React.Fragment>
        {!this.state.loaded && <Loader/>}
        
        <img className="imgContainer" onLoad={this.onLoad} onError={this.onLoad} src={data.get("url")}/>
      </React.Fragment>
    )
  }
}