import React from "react"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import DraftsIcon from "@material-ui/icons/Drafts"
import SendIcon from "@material-ui/icons/Send"
import EmailIcon from "@material-ui/icons/Email"
import ArchiveIcon from "@material-ui/icons/Archive"
import  sAction  from "sAction";
import PlusIcon from "@material-ui/icons/AddCircleOutline"
import MinusIcon from "@material-ui/icons/RemoveCircleOutline"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"
import EmailFolderCreate from "../popup/email/EmailFolderCreate"
import EmailFolderRename from "../popup/email/EmailFolderRename"
import { EmailMenuContextMenu } from "./EmailMenuContextMenu"

export default class EmailMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      contextMenuData: ""
    };
    this.icons = {
      inbound: InboxIcon,
      draft: DraftsIcon,
      sent: SendIcon,
      archived: ArchiveIcon,
      email: SendIcon,
      plus: PlusIcon,
      minus: MinusIcon,
      open: FolderOpenIcon
    };
    this.lastWay = "";
    this.lastBool = "";
  }

  //otevreni kontext. menu
  openContextMenu = (event, data) => {
    event.preventDefault();
    // this.handleCheckboxChange(true, email);
    this.setState({ anchorEl: event.currentTarget, contextMenuData: data });
  };

  //zavreni kontext. menu
  closeContextMenu = () => {
    this.setState({ anchorEl: null });
  };

  changeHover = (way, bool) => {
    // hover byl jiz zmenen - neukladej data znovu do storu
    if (this.lastWay === way && bool === this.lastBool) {
      return;
    }
    sAction.dataSet(way + "/hover", bool);
    this.lastWay = way;
    this.lastBool = bool;
  };

  onDrop = dropData => {
    let data = sAction.dataGet(this.props.way+"/move");
    const action = data.get("action");

    switch (action) {
      //presouvaji se slozky
      case "folders":
        // imap check
        if (dropData.isImap) {
          sAction.error(sAction.translate("LBL_EMAIL_ERROR_FOLDER_MOVE"));
          return;
        }

        const folderId = data.get("folderId");
        const newParentId = dropData.destinationFolder;

        // zdrojova a cilova slozka je stejna - nic nedelej
        if (folderId === newParentId) {
          return;
        }

        // presun slozky
        sAction.load();
        sAction.rest.post(
          "email/moveFolder",
          {
            folderId,
            newParentId
          },
          () => {
            sAction.routeEmail();
          }
        );
        break;

      //presouvaji se emaily
      case "emails":
        data = data.merge(dropData);
        if (data.get("isImap") && data.get("sourceIeId") !== data.get("destinationIeId")) {
          sAction.error(sAction.translate("LBL_EMAIL_ERROR_MOVE"));
          return;
        }
        sAction.load();
        sAction.rest.post("email/moveEmails", data, () => {
          sAction.routeEmail(true);
        });
        break;
    }
  };

  onDragStart = folderId => {
    if (!folderId) {
      sAction.error(sAction.translate("LBL_EMAIL_ERROR_FOLDER_MOVE"));
      return;
    }

    sAction.dsClear();
    sAction.dsAdd("set", this.props.way+"/move/action", "folders");
    sAction.dsAdd("set", this.props.way+"/move/folderId", folderId);
    sAction.dsProcess();
  };

  renderMenu = (data, paddingLeft, isImap) => {
    const { selectedFolder, way } = this.props;

    return data.map(folder => {
      // viz DataConstructors.EmailFolders

      if (!folder) return "";

      let menu = "";

      /* let FolderIcon = folder.icon; */

      let Icon;
      // if (folder.folder_type) {
      //   Icon = this.icons[folder.folder_type];
      // } else {
      //   Icon = EmailIcon;
      // }

      if (folder.expanded) {
        Icon = this.icons["minus"];
      } else {
        Icon = this.icons["plus"];
      }
      if (folder.children && folder.children.size > 0) {
        menu = this.renderMenu(folder.children, paddingLeft + 15, isImap);
      } else {
        Icon = this.icons["open"];
      }

      let active = "";

      if (folder.way === selectedFolder.get("way"))
        active = " email__menu__button--selected";

      // const display = (isImap === true && folder.labelStyle === null) ? " hidden" : "";
      const display = "";
      const hover = folder.hover ? " email__menu__button--hover" : "";
      let unseen = folder.unseen ? (
        <span className="email__menu__button__badge">{folder.unseen}</span>
      ) : (
        ""
      );

      return (
        <div
          key={folder.get("text")}
          className={
            folder.get("expanded")
              ? "email__menu__folder--visible"
              : "email__menu__folder--hidden"
          }
        >
          <a
            draggable
            onContextMenu={e => this.openContextMenu(e, folder)}
            onDragStart={() => this.onDragStart(isImap ? "" : folder.id)}
            onClick={() => {
              if (!folder.get("expanded") || (folder.children && !folder.children.size)) {

                // zabrani otevreni imap root slozky, kde nic neni
                if (folder.mbox) {

                  let newList = {
                    isImap,
                    ieId: isImap ? folder.ieId : folder.id,
                    mbox: folder.mbox,
                    emailUIAction: isImap
                      ? "getMessageList"
                      : "getMessageListSugarFolders",
                    page: 1,
                    start: 0,
                    way: folder.way
                  };
                  newList = this.props.search.merge(newList);
                  sAction.emailGetList(newList, way);
                }
              }
              this.expandFolder(folder);
            }}
            style={{ paddingLeft }}
            onDrop={() => {
              this.changeHover(folder.way, false);
              this.onDrop({
                isImap,
                destinationFolder: isImap ? folder.mbox : folder.id,
                destinationIeId: folder.ieId
              });
            }}
            onDragOver={event => {
              event.preventDefault();
              this.changeHover(folder.way, true);
            }}
            // onDragEnter={() => this.changeHover(folder.way, true)}
            onDragLeave={() => this.changeHover(folder.way, false)}
            className={"email__menu__button" + hover + display + active}
            tabIndex={0}
          >
            <Icon
              style={{ fontSize: 20 }}
              onClick={e => {
                // e.preventDefault();
                e.stopPropagation();
                this.expandFolder(folder);
              }}
            />

            <span className="email__menu__button__title">
              {sAction.moduleTranslate["Emails"]["LBL_FOLDER_"+(folder.get("text")||"").toUpperCase()] || folder.get("text")}
            </span>
            {unseen}
          </a>

          {menu}
        </div>
      );
    });
  };

  openCreateFolderPopup = () => {
    this.closeContextMenu();
    sAction.popup(<EmailFolderCreate data={this.state.contextMenuData} />);
  };

  openRenameFolderPopup = () => {
    this.closeContextMenu();

    const { contextMenuData } = this.state;

    sAction.popup(<EmailFolderRename data={contextMenuData} />);
  };

  openDeleteFolderPopup = () => {
    this.closeContextMenu();

    //Opravdu chcete tuto složku vymazat?
    sAction.confrim(
      sAction.translate("LBL_EMAIL_FOLDERS_DELETE_CONFIRM"),
      () => {
        const data = this.state.contextMenuData;

        let folderData;
        if (data.ieId !== "folder") {
          folderData = {
            folderType: "imap",
            ieId: data.ieId,
            mbox: data.mbox
          };
        } else {
          folderData = {
            folderType: "sugar",
            folder_id: data.id
          };
        }

        sAction.load();
        sAction.rest.post("email/deleteFolder", folderData, data => {
          if (data.status === true) {
            sAction.routeEmail(true);
          } else {
            sAction.unLoad();
            sAction.error(data.errorMessage);
          }
        });

        sAction.popupHide();
      }
    );
  };

  goToCompose = () => {
    sAction.openEmailPopup();
  }

  expandFolder = (folder) => {
    sAction.dataSet(
      folder.way + "/expanded",
      !folder.get("expanded")
    );
    var email_folders = sAction.getStorage("email_folders") || {};
    email_folders[folder.way] = !folder.get("expanded");
    sAction.setStorage('email_folders', email_folders);
    sAction.setStorage('email_selectedFolder_way', folder.way);
  }

  render() {
    // console.log("renderMenu()");

    const { folders, foldersIMAP } = this.props;

    return (
      <div className="email__menu">
        <a
          onClick={this.goToCompose}
          draggable="false"
          className="email__menu__button email__menu__button--compose"
          tabIndex={0}
        >
          <EmailIcon style={{ fontSize: 20 }} />

          <span className="email__menu__button__title">
            {/*Napsat e-mail*/}
            {sAction.translate("LBL_COMPOSE_EMAIL_BUTTON_LABEL")}
          </span>
        </a>

        {this.renderMenu(foldersIMAP, 10, true)}

        {this.renderMenu(folders, 10, false)}

        <EmailMenuContextMenu
          data={this.state}
          openCreateFolderPopup={this.openCreateFolderPopup}
          openRenameFolderPopup={this.openRenameFolderPopup}
          openDeleteFolderPopup={this.openDeleteFolderPopup}
          closeContextMenu={this.closeContextMenu}
        />
      </div>
    );
  }
}
