import React from "react";
import PureComponent from "../pure";

import WaveEffect from "../formElements/WaveEffect";
import SubMenuLines from "./SubMenuLines";

import sAction from "sAction";

class MenuButton extends PureComponent {
  showLabel(e, vname) {
    if (this.props.menuOpen == false) {
      const target = e.target;
      const top = target.getBoundingClientRect().top;

      var label = document.querySelector(".menuHoverLabel");
      label.style.display = "flex";
      label.style.top = top + "px";
      label.innerHTML = vname;
    }
  }


  render() {
    const data = this.props.button;
    const way = this.props.way;
    const activeModule = this.props.activeModule;
    const vname = sAction.app_strings.moduleList[data.get("name")];
    const iconColor = this.props.iconColor;
    const subMenuLines = data.subMenuLines;
    var renderClass = "menuButton";
    var showSubmenu = false;
    var url = "#list/" + data.name;
    if (data.name.toLowerCase() == activeModule.toLowerCase()) {
      renderClass += " select";
      showSubmenu = true;
    } else if (subMenuLines != null) {
      subMenuLines.forEach((line) => {
        if (line.get("value") == activeModule) {
          renderClass += " select";
          showSubmenu = true;
        }
      });
    }

    if (data.name == "Home") {
      if (!activeModule) {
        renderClass += " select";
      }
      url = "#home";
    } else if (data.name == "Calendar") {
      url = "#calendar";
    }

    var subMenuLinesRender = null;
    if (subMenuLines != null && showSubmenu) {
      subMenuLinesRender = (
        <SubMenuLines menuOpen={this.props.menuOpen} activeModule={activeModule} data={subMenuLines} />
      );
    }

    return (
      <React.Fragment>
        <WaveEffect className="menuButtonContainer">
          <a
            id={data.name}
            href={url}
            className={renderClass}
            title={vname}
            onMouseEnter={(e) => this.showLabel(e, vname)}
          >
            <div
              className={"icon-" + data.name + " menuButtonIcon"}
              style={{ color: iconColor }}
            />
            <span>{vname}</span>
          </a>
        </WaveEffect>
        {subMenuLinesRender}
      </React.Fragment>
    );
  }
}
export default MenuButton;
