export default function toBase64(value){
    console.log(value);
    var returnValue = null;
    if(typeof value == "object"){
        returnValue = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    }else{
        returnValue = btoa(unescape(encodeURIComponent(value)));
    }

    return returnValue;
}