

export default function  getRecordId() {
      var url = window.location.hash
      url = url.replace("#", "");
      url = url.split("/");
      if (url[2]) url[2] = url[2].replace(/\?.*/,"");

      return url[2] || undefined;
    }
