export default function subpanelGenerateDefaultFilter(prefix, data){

    if(data){
        const parModule = data.def.getIn(["rel", "module"]);
        if(data.module == "Contacts" && parModule == "Opportunities"){
            let acc = this.dataGet(prefix+"/fields/account_name/def/id_value");
            if(acc){
                return {
                    parent: {
                        operandType: "relate",
                        parentId: acc,
                        module: "Accounts",
                        relationship: ["accounts"],
                        name: "account_name",
                        relName: "contacts",
                        type: "eq",
                        canDelete: true,
                    }
                }
            }
        }
    }
    return [];
}