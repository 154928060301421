export default function detailOnKeyDown(e,props){
    this.detailTempData[props.way]
    this.baseOnKeyDown(e);
    const keyCode = e.keyCode;

    if (e.ctrlKey && keyCode === 83) {
      e.preventDefault();
      const button = document.getElementById("save_record");
      button.click();
    }
    if(e.ctrlKey && e.altKey && keyCode == 78){
      e.preventDefault();
      this.detailNewRecord();
    }
    if (e.ctrlKey && (keyCode == 39 || keyCode == 37)) {
      if (this.detailTempData[props.way].tabChange == true) {
        const actIndex = props.data.activeTab;
        var newIndex = null;
        if (keyCode === 37) {
          newIndex = actIndex - 1;
          if (newIndex <= -1) {
            newIndex = props.data.tabs.size - 1;
          }
        } else if (keyCode === 39) {
          newIndex = actIndex + 1;
          if (newIndex >= props.data.tabs.size) {
            newIndex = 0;
          }
        }
        if (newIndex != null) {
        this.changeDetailTab(newIndex,props.way,props.data.activeTab);
        this.detailTempData[props.way].tabChange = false;
          setTimeout(() => {
            this.detailTempData[props.way].tabChange = true
          },300)
        }
      }
    }
    if(e.ctrlKey === true && keyCode === 13){
        const field = this.dataGet(props.way+"/tabs/"+props.data.activeTab+"/rows/0/0");
        this.fieldToEdit({name:field,prefix:props.way,way:props.way+"/fields/"+field});
        e.stopPropagation();
    }
}