import React from "react";
import PureComponent from "../../pure";
import RichEditor from "../../formElements/RichText"
import sAction from "sAction";

export default class DetailTextEditor extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      edit: props.data.get("value") != null ? false : true
    }
    this.textRef = React.createRef();
  }
  getEditorContent = () => {
    if (!this.textRef.current) return "";

    const reactQuillRef = this.textRef.current.reactQuillRef;

    if (!reactQuillRef) return "";

    return reactQuillRef.getEditorContents();
  };
  saveField() {
    const data = this.props.data
    const field = data.get("field");
    const value = this.getEditorContent();
    const prefix = this.props.prefix
    const way = this.props.way
    sAction.dsClear();
    sAction.dsAdd("set", prefix + "/changes/fields/" + field, value);
    sAction.dsAdd("set", way + "/value", value);
    sAction.dsProcess();
  }
  render() {
    const data = this.props.data
    const value = data.get("value") != null ? sAction.htmlDecode(data.get("value")) : ""
    const module = this.props.module
    return (
      this.state.edit ?
        <RichEditor
          className="detailTextEditor"
          ref={this.textRef}
          variables={false}
          value={value}
          onBlur={(() => this.saveField())}
        />
        :
        <div className="detailTextEditorContainer">
          <div className="detailTextEditor noEdit quill" >
            <div className="ql-snow">
              <div className="ql-editor" dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          </div>
          {sAction.hasAccess(module, "edit") &&
            <div className="detailViewSpanEdit icon-EditField" onClick={() => this.setState({ edit: true })}></div>
          }
        </div>
    );
  }
}
