import detailDefault from "../../detailDefault";
import React from "react";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);

    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/type", "HTMLText");
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/readonly", true);
    sAction.dsProcess();
  }

  update(sAction, data) {
    switch (data.field) {
      case "billing_address_country":
          this.updateDetailApiVisibility(sAction, data)
          break;
      case 'acm_address':
        this.getNearAccounts(sAction, data);
        break;
    }

    this.addressesHelper(sAction, data, data)
  }

  addressesHelper(sAction, data, field){
    // const street = sAction.dataGet(data.prefix + "/fields/billing_address_street/value");
    // const city = sAction.dataGet(data.prefix + "/fields/billing_address_city/value");


    if (field.field === 'acm_address') {
      sAction.dsClear();

      const acm_address_street = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/street");
      const acm_address_city = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/obec");
      const acm_address_psc = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/psc");
      const acm_address_kraj = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/kraj");
      const acm_address_okres = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/okres");

      if (acm_address_street){
        sAction.dsAdd("set", data.prefix + "/fields/billing_address_street/value", acm_address_street);
        sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_street", acm_address_street);
      }
      if (acm_address_city){
        sAction.dsAdd("set", data.prefix + "/fields/billing_address_city/value", acm_address_city);
        sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_city", acm_address_city);
      }
      if (acm_address_psc){
        sAction.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/value", acm_address_psc);
        sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_postalcode", acm_address_psc);
      }
      if (acm_address_kraj){
        sAction.dsAdd("set", data.prefix + "/fields/billing_address_kraj/value", acm_address_kraj);
        sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_kraj", acm_address_kraj);
      }
      if (acm_address_okres){
        sAction.dsAdd("set", data.prefix + "/fields/billing_address_state/value", acm_address_okres);
        sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_state", acm_address_okres);
      }

      sAction.dsProcess();
    }
  }

  getNearAccounts(sAction, data) {
    let item = sAction.dataGet(data.prefix + '/fields/acm_address/def/item');
    let id = sAction.dataGet(data.prefix + '/id');
    const address = {
      x: item.get('gpsX'),
      y: item.get('gpsY'),
      cast_obce: item.get('cast_obce'),
      kraj: item.get('kraj'),
      obec: item.get('obec'),
      okres: item.get('okres'),
      psc: item.get('psc'),
      id: id,
    };

    sAction.rest.post('getNearAddresses', address, (returnData) => {
      if (returnData.status) {
        if (returnData.message.data.length > 0) {
          let message = sAction.translate('LBL_NEAR_ACCOUNT_FOUND', 'Accounts');
          let accounts = [];
          returnData.message.data.forEachObject((value) => {
            accounts.push(
              (
                <div style={{margin: 'auto'}}>
                  <a href={'#detail/Accounts/' + value.id} target='_blank'>{value.name} | IČ: {value.ticker_symbol}</a>
                </div>
              )
            );
          });
          message = (
            <div style={{margin: 'auto'}}>
              <div>
                {message}
              </div>
              <br />
              {accounts}
            </div>
          );
          const data = {
            // Potvrzení
            header: sAction.translate("LBL_CONFIRM"),
            buttons: [
              // Pokračovat
              { label: sAction.translate("LBL_CONTINUE", "Home"), callback: () => sAction.popupHide() },
            ],
          };

          sAction.popup(message, data);
        }
      } else {
        sAction.error(sAction.translate(returnData.errorMessage.text));
      }
    });
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("name", "ApiName");
      updateFieldApiValue("account_name", "ApiName");
      updateFieldApiValue("ticker_symbol", "ApiICO");
      updateFieldApiValue("sic_code", "ApiDIC");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else if(address === "SLOVAKIA"){
      //skryje ikony
      updateFieldApiValue("name", "ApiNameSK");
      updateFieldApiValue("account_name", "ApiNameSK");
      updateFieldApiValue("ticker_symbol", "ApiICOSlovakia");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else{
      //skryje ikony
      updateFieldApiValue("name", "ApiNameOthers");
      updateFieldApiValue("account_name", "");
      updateFieldApiValue("ticker_symbol", "");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "");
      updateFieldApiValue("shipping_address_street", "");
      updateFieldApiValue("billing_address_postalcode", "");
      updateFieldApiValue("shipping_address_postalcode", "");
    }

    //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
    const tabs = sAction.dataGet(data.prefix + "/tabs");
    if (tabs) {
      const finstatIndex = tabs.findIndex(i => i.name === "LBL_DETAILVIEW_PANEL_FINSTAT");
      if (finstatIndex !== -1) {
        if (address !== "SLOVAKIA") {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", true);
        } else {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", false);
        }
      }
    }
    sAction.dsProcess();
  }
}
