export default function parseNumber(str, decimal = this.userPreferences.dec_sep,thousands = this.userPreferences.num_grp_sep){
    try{
    if(typeof(str) == "number"){
        if (!isFinite(str)) {
          return 0;
        }
        return str;
    }
    if(!str){
        return 0;
    }
    
    var re;
    
    if(thousands){
        re = new RegExp("\\"+thousands,"g");
        str = str.replace(re, "");
    }

    re = new RegExp("\\"+decimal,"g");
    str = str.replace(re, ".");
    return parseFloat(str);
    } catch (e) {
    console.log(e)
    console.log(str);
  }
}