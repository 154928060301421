import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import InputText from "./InputText";
import Button from "./Button";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import TickBox from "./TickBox";
import AcmDate from "./AcmDate";
import AcmDateTime from "./AcmDatetime";
import Relate from "./Relate";

/**
 * MANUAL https://gitlab.acmark.cz/-/snippets/84
 */
export default class SimpleFormFromArray extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            optionsSelected: [],
            errors: {},
        };
        this.generateState(props);
    }

    generateState(props) {
        if (Object.prototype.toString.call(props.fields) === '[object Object]') {
            props.fields.forEachObject((fieldDef, fieldName) => {
                if (fieldDef.type === 'header') {
                    return;
                }
                if (!this[fieldName]) {
                    this[fieldName] = fieldDef?.myRef || React.createRef();
                }
            });
        } else if (Object.prototype.toString.call(props.fields) === '[object Array]') {
            props.fields.forEachObject((fieldsBundle) => {
                fieldsBundle.forEachObject((fieldDef, fieldName) => {
                    switch (fieldDef.type) {
                        case 'header':
                            return;
                        // case 'relate':
                        //     this[fieldName] = fieldDef?.myRef || React.createRef();
                        //     break;
                        default:
                            if (!this[fieldName]) {
                                this[fieldName] = fieldDef?.myRef || React.createRef();
                            }
                            break;
                    }
                });
            });
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        this.generateState(nextProps);
    }

    getFieldType(fieldName = null, fieldDef = null) {

        switch (fieldDef.type) {
            case 'input':
            case 'varchar':
                return (
                    <InputText
                        containerClassName={fieldDef.className}
                        containerStyle={fieldDef.style}
                        key={fieldName}
                        myRef={this[fieldName]}
                        onChange={e => fieldDef.onChange?.(e.target.value)}
                        defaultValue={fieldDef.defaultValue}
                        error={fieldDef.error ? fieldDef.error : this.state.errors?.[fieldName]}
                    />
                );
            case 'number':
            case 'int':
                return (
                    <InputText
                        type={'number'}
                        containerClassName={fieldDef.className}
                        containerStyle={fieldDef.style}
                        key={fieldName}
                        myRef={this[fieldName]}
                        onChange={e => fieldDef.onChange?.(e.target.value)}
                        defaultValue={fieldDef.defaultValue}
                        error={fieldDef.error ? fieldDef.error : this.state.errors?.[fieldName]}
                    />
                );
            case 'select':
            case 'enum':
                var options = [];
                if (fieldDef.customOptions) {
                    options = fieldDef.customOptions;
                } else {
                    let optionsToSelect = sAction.app_strings[fieldDef.options];
                    for (var key in optionsToSelect) {
                        if (optionsToSelect.hasOwnProperty(key)) {
                            var value = optionsToSelect[key];
                            options.push({value: key, label: value});
                        }
                    }
                }
                return (
                    <Select
                        options={options}
                        containerClassName={fieldDef.className}
                        containerStyle={fieldDef.style}
                        key={fieldName}
                        myRef={this[fieldName]}
                        onChange={e => fieldDef.onChange?.(e.target.value)}
                        defaultValue={fieldDef.defaultValue}
                        error={fieldDef.error ? fieldDef.error : this.state.errors?.[fieldName]}
                    />
                );
            case 'multienum':
                var options = [];
                // var optionsSelected = [];
                if (fieldDef.customOptions) {
                    options = fieldDef.customOptions;
                } else {
                    let optionsToSelect = sAction.app_strings[fieldDef.options];
                    for (var key in optionsToSelect) {
                        if (optionsToSelect.hasOwnProperty(key)) {
                            var value = optionsToSelect[key];
                            options.push({value: key, label: value});
                        }
                    }
                }
                return (
                    <MultiSelect
                        id="multiSelect"
                        key={fieldName}
                        options={options}
                        defaultValue={this.state.optionsSelected}
                        open={false}
                        myRef={this[fieldName]}
                        containerClassName={fieldDef.className}
                        className={fieldDef.className + (fieldDef.error ? fieldDef.error : this.state.errors?.[fieldName] && " errorRequired")}
                        valueFromProps={true}
                        onChange={(value) => {
                            this.setState({optionsSelected: value});
                            fieldDef.onChange?.(value);
                        }}
                        onBlur={(value) => {
                            this.setState({ optionsSelected: value });
                            fieldDef.onChange?.(value);
                        }}
                        error={fieldDef.error ? fieldDef.error : this.state.errors?.[fieldName]}
                    />
                );
            case 'dynEnum':
            case 'acmDynamicEnum':
                let dynEnumOptions = [];
                if (fieldDef.customOptions) {
                    dynEnumOptions = fieldDef.customOptions;
                } else {
                    sAction.app_strings?.['dynamicEnum']?.[fieldDef?.options].forEachObject((value, key) => {
                        dynEnumOptions.push({value: key, label: value});
                    });
                }

                return (
                    <Select
                        options={dynEnumOptions}
                        containerClassName={fieldDef.className}
                        containerStyle={fieldDef.style}
                        key={fieldName}
                        myRef={this[fieldName]}
                        onChange={e => fieldDef.onChange?.(e.target.value)}
                        defaultValue={fieldDef.defaultValue}
                        error={this.state.errors?.[fieldName]}
                    />
                );
            case 'checkbox':
                return (
                    <TickBox
                        ref={this[fieldName]}
                        key={fieldName}
                        className={fieldDef.className}
                        style={fieldDef.style}
                        onChange={e => {
                            this[fieldName].current.value = e;
                            fieldDef.onChange?.(e);
                        }}
                        checked={fieldDef.defaultValue}
                        error={this.state.errors?.[fieldName]}
                    />
                );
            case 'date':
                return (
                    <AcmDate
                        myRef={this[fieldName]}
                        key={fieldName}
                        className={fieldDef.className}
                        style={fieldDef.style}
                        onChange={e => {
                            this[fieldName].current = {value: e};
                            fieldDef.onChange?.(e);
                        }}
                        defaultValue={fieldDef.defaultValue}
                        error={this.state.errors?.[fieldName]}
                    />
                );
            case 'datetime':
                return (
                    <AcmDateTime
                        myRef={this[fieldName]}
                        key={fieldName}
                        className={fieldDef.className}
                        style={fieldDef.style}
                        onChange={e => {
                            this[fieldName].current = {value: e};
                            fieldDef.onChange?.(e);
                        }}
                        defaultValue={fieldDef.defaultValue}
                        error={this.state.errors?.[fieldName]}
                    />
                );
            case 'relate':
                return (
                    <Relate
                        newRecord={false}
                        module={fieldDef.module}
                        updateField={true}
                        data={{
                            value: fieldDef.defaultValue?.name,
                            id_value: fieldDef.defaultValue?.id,
                        }}
                        buttons={[]}
                        callback={item => {
                            this[fieldName].current = {value: item};
                            fieldDef.onChange?.(item);
                        }}
                        key={fieldName}
                        className={fieldDef.className}
                        style={fieldDef.style}
                        defaultFilter={fieldDef.defaultFilter}
                        error={this.state.errors?.[fieldName]}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        ref={this[fieldName]}
                        key={fieldName}
                        className={fieldDef.className + ' acmPopupContentTextarea' + (this.state.errors?.[fieldName] ? ' error' : '')}
                        style={fieldDef.style}
                        onChange={e => {
                            this[fieldName].current = {value: e};
                            fieldDef.onChange?.(e);
                        }}
                        defaultValue={fieldDef.defaultValue}
                        error={this.state.errors?.[fieldName]}
                    >

                    </textarea>
                );
            case 'button':
                return (
                    <Button
                        className={fieldDef.className + (this.state.errors?.[fieldName] ? ' error' : '')}
                        style={fieldDef.style}
                        key={fieldName}
                        onClick={(e) => fieldDef.onClick?.(e)}
                    >
                        {fieldDef.label}
                    </Button>
                );
            default:
                return (
                    <div>&nbsp;</div>
                );
            // Poznamka pro dalsi rozsirovani: pokud chcete dalsi typy fieldu, tak si je nadefinujte
        }
    }

    buttonOnClick(buttonName) {
        if (typeof this.props?.buttons?.[buttonName]?.onClick === 'function') {
            let isOk = true;
            let errors = {};
            let data = {};
            if (this.props?.buttons?.[buttonName]?.returnButtonName !== false) {
                data.buttonName = buttonName;
            }

            if (this.props?.buttons?.[buttonName]?.checkRequired !== false) {
                if (Object.prototype.toString.call(this.props.fields) === '[object Object]') {
                    this.props.fields.forEachObject((fieldDef, fieldName) => {
                        if (['header', 'button'].includes(fieldDef.type)) {
                            return;
                        }
                        if (fieldDef.required === true &&
                            ((fieldDef.type !== 'multienum' && fieldDef.type === 'relate' && !(this[fieldName]?.current?.value.id)) ||
                            (fieldDef.type !== 'multienum' && fieldDef.type !== 'relate' && !(this[fieldName]?.current?.value)) ||
                            (fieldDef.type === 'multienum' && fieldDef.type !== 'relate' && (this.state.optionsSelected.length === 0)))
                        ) {
                            isOk = false;
                            errors[fieldName] = true;
                        }
                        data[fieldName] = this[fieldName]?.current?.value;
                    });
                } else if (Object.prototype.toString.call(this.props.fields) === '[object Array]') {
                    this.props.fields.forEachObject((colData, colKey) => {
                        colData?.forEachObject?.((fieldDef, fieldName) => {
                            if (['header', 'button'].includes(fieldDef.type)) {
                                return;
                            }
                            if (fieldDef.required === true &&
                                ((fieldDef.type === 'relate' && !(this[fieldName]?.current?.value.id)) ||
                                    (fieldDef.type !== 'relate' && !(this[fieldName]?.current?.value)))
                            ) {
                                isOk = false;
                                errors[fieldName] = true;
                            }
                            data[fieldName] = this[fieldName]?.current?.value;
                        });
                    });
                }
            }

            data.optionsSelected = this.state.optionsSelected
            this.setState({errors: errors});

            if (isOk) {
                return this.props.buttons?.[buttonName]?.onClick?.(data);
            }
        }
    }

    createFormFields(fields) {
        let fieldsDOM = [];
        let returnDom = null;

        if (Object.prototype.toString.call(fields) === '[object Object]') {
            // Jednosloupcove reseni
            fieldsDOM = this.processFields(fields);

            returnDom = (
                <div className="sfFields">
                    {fieldsDOM}
                </div>
            );
        } else if (Object.prototype.toString.call(fields) === '[object Array]') {
            // Vicesloupcove reseni
            fields?.forEachObject((fieldArray, key) => {
                let columnDOM = this.processFields(fieldArray);
                fieldsDOM.push(
                    <div className='sfFields' key={key}>
                        {columnDOM}
                    </div>
                );
            });
            returnDom = (
                <div className="sfColumns">
                    {fieldsDOM}
                </div>
            );
        }

        return returnDom;
    }

    processFields(fields) {
        let fieldsDOM = [];

        fields.forEachObject((fieldDef, fieldName) => {
            if (fieldDef.type !== 'header') {
                fieldsDOM.push(
                    <div className="sfRow" key={fieldName} id={fieldName}>
                        <div className="sfCell">{sAction.translate(fieldDef.label)} {fieldDef.required && (<span className='required'>*</span>)}</div>
                        <div className="sfCell">
                            {this.getFieldType(fieldName, fieldDef)}
                        </div>
                    </div>
                );
            } else {
                fieldsDOM.push(
                    <div className="sfRow" key={fieldName} id={fieldName}>
                        <div className="sfCell header" style={{flexGrow: 1}}>{sAction.translate(fieldDef.label)}</div>
                    </div>
                );

            }
        });

        return fieldsDOM;
    }

    createFormButtons(buttons) {
        let buttonsArray = [];
        buttons.forEachObject((buttonDef, buttonName) => {
            buttonsArray.push(
                <Button
                    onClick={e => this.buttonOnClick(buttonName)}
                    key={buttonName}
                    className={buttonDef?.className ? buttonDef?.className : ''}
                    style={buttonDef?.style}
                >
                    {buttonDef.icon && (
                        <span className={'actionPanelButtonIcon ' + buttonDef.icon}></span>
                    )}
                    {sAction.translate(buttonDef.label)}
                </Button>
            );
        });

        return buttonsArray;
    }

    render() {
        const props = this.props;
        const fields = props.fields;
        const buttons = props.buttons;

        let fieldArray = this.createFormFields(fields);
        let buttonsArray = this.createFormButtons(buttons);

        return (
            <div className={"sfTable " + this.props?.className ? this.props?.className : ''} style={this.props?.style}>
                {fieldArray}
                <div className="sfButtonRow sfButtons viewActionPanelButtons">
                    {buttonsArray}
                </div>
            </div>
        );
    }
}
