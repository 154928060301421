export default function checkDPH(sendData, prefix = "view") {
  if (!sendData.dic) {
    this.error(
      this.translate("LBL_CHECK_DIC_MSGERR_NODIC", "Accounts"),
      this.translate("LBL_CHECK_DIC_MSGERR_ENTERDIC", "Accounts")
    );
    return;
  }

  this.load();
  this.rest.post("detailapi/checkdph", sendData, data => {
    this.dsClear();

    let html = "";

    if (data.stat === 1) {
      html = `<b>${this.translate("LBL_CHECK_DPH_MSG_FOUND_REC", "Accounts")}</b>
      <br /><br />
      ${this.translate("LBL_CHECK_DPH_MSG_DATE", "Accounts")}:
      <b>${data.nespdph_zapsan_dne_pretty}</b><br />
      ${this.translate("LBL_CHECK_DPH_MSG_LISTED", "Accounts")}:
      ${data.nespdph_fu_pretty} <br />`;
    } else {
      html = this.translate("LBL_CHECK_DPH_MSG_NOTFOUND_REC", "Accounts");
    }

    delete data.stat;

    this.updateFields(data, prefix);

    this.alert(html, this.translate("LBL_CHECK_DPH_MSG_TITLE", "Accounts"));
  });
}
