export default function createMultipleCallsSave(params) {
    this.load();
    this.rest.post('createMultiCalls', params, returnData => {
        this.unLoad();
        if (returnData['status']) {
            this.popupHide();
            this.alert(this.translate(returnData['message']));

            // echo ok
        } else {
            this.error(this.translate(returnData['message']));
        }
    });
    // console.log(data);
    // console.log(records);
}