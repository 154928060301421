export default function detailEditAddNewTab(e,way,module,tabs){
    e.stopPropagation();

    const newIndex = tabs.size + 1;
    const newTabTranslate = this.translate("LBL_EDIT_NEW_TAB");
    
    const newLabelKey = "LBL_CUSTOM_TAB_NUMBER_"+newIndex+"_"+Date.now()

    const params = {
        module: module,
        translate: newTabTranslate,
        vname:newLabelKey
    }

    this.load();
    this.rest.post("saveCustomTranslate",params,ret => {
        this.unLoad()
        if(ret.status === true){
            this.moduleTranslate[module][newLabelKey] = newTabTranslate
            this.dataAdd(way+"/tabs",{id:newIndex,name:newLabelKey,type:"standard"},"Tab")
        }
    })
}
