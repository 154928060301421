import React, { PureComponent } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import  sAction  from "sAction";

export class EmailMenuContextMenu extends PureComponent {
  render() {
    let {
      openCreateFolderPopup,
      openRenameFolderPopup,
      openDeleteFolderPopup,
      closeContextMenu,

      data: {
        anchorEl,
        contextMenuData: {
          mbox,
          is_group,
          isGroup,
          is_dynamic,
          isDynamic,
          isImap,
        }
      }
    } = this.props;
    const open = Boolean(anchorEl);

    const isRoot = !mbox;
    const isInbox = mbox && mbox.toUpperCase() === "INBOX";

    isGroup = is_group === "true" || isGroup === "true";
    isDynamic = is_dynamic === "true" || isDynamic === "true";

    // CONTEXT MENU PRAVIDLA
    //
    // IMAP
    //
    // Zkontrolovat poštu
    // Synchronizovat
    // (pokud není root) Vytvořit složku
    // (pokud není root a INBOX) Smazat složku
    // (pokud není root a INBOX) Přejmenovat složku
    // Vyprázdnit koš
    // (pokud je root) Vymazat dočasné soubory (cache)
    //
    // Group Folder
    //
    // Vyprázdnit koš
    // (pokud je root) Vymazat dočasné soubory (cache)
    //
    // Group Folder - AutoImport - nic nezobrazovat
    //
    // SugarFolders:
    //
    // Vytvořit složku
    // (pokud není dynamic) Smazat složku
    // (pokud není dynamic) Přejmenovat složku
    // (pokud není dynamic) Vypráznit koš

    const e = window.event;

    let left = e && e.pageX ? e.pageX : 0;
    let top = e && e.pageY ? e.pageY : 0;

    return (
      <Menu
        id="menu"
        //anchorEl={anchorEl}
        open={open}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ left, top }}
      >
        {!isRoot || (isRoot && isImap) && !isGroup && (
          <MenuItem onClick={openCreateFolderPopup}>
              <div className="contextMenuIcon icon-addFolder" />
            {/*Vytvořit složku*/}
            {sAction.translate("LBL_EMAIL_MENU_ADD_FOLDER", "Emails")}
          </MenuItem>
        )}

        {!isRoot && !isDynamic && !isGroup && !isInbox && (
          <MenuItem onClick={openRenameFolderPopup}>
              <div className="contextMenuIcon icon-rename" />
            {/*Přejmenovat složku*/}
            {sAction.translate("LBL_EMAIL_MENU_RENAME_FOLDER", "Emails")}
          </MenuItem>
        )}

        {!isRoot && !isDynamic && !isGroup && !isInbox && (
          <MenuItem onClick={openDeleteFolderPopup}>
              <div className="contextMenuIcon icon-deleteIcon" />
            {/*Smazat složku*/}
            {sAction.translate("LBL_EMAIL_MENU_DELETE_FOLDER", "Emails")}
          </MenuItem>
        )}
      </Menu>
    );
  }
}
