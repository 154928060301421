import React from "react";
import PureComponent from "../../pure";

//import Chip from '@material-ui/core/Chip';
//import Avatar from '@material-ui/core/Avatar';

export default class PtItem extends PureComponent {
    render(){
        const data = this.props.data;

        /*
        <div className={"ptItem"}>
                <Chip 
                    className={"ptItemChip"} 
                    label={data.get("name")} 
                    avatar={
                        <Avatar onClick={() => this.props.onClick({id : data.get("id")})}>
                            <div className="icon-addIcon quoteFavAddIcon"/>
                        </Avatar>
                    }
                            
                />
            </div>
        */
        let name = data.get("name");
        let num = data.get("mft_part_num_int");
        if(num){
            name = num + " - " + name;
        }

        return (
            <div className="ptItem" onClick={() => this.props.onClick({id : data.get("id")})}>
                <div className="icon-addIcon quoteFavAddIcon"/>
                <div className="ptItemText">{name}</div>
            </div>                      
        )
    }

}

