import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
export default class TickBox extends React.PureComponent {
  handleChange = e => {
    const { onChange } = this.props;
    if (onChange != undefined) {
      onChange(e.target.checked);
    }
  };

  render() {
    const {onChange, ...rest} = this.props;

    var className = "acmTickBox";
    if(this.props.disabled == true){
      className += " disabled";
    }

    return (
      <Checkbox
        className={className}
        {...rest}
        //checked={checked}
        onChange={this.handleChange}
      />
    );
  }
}
