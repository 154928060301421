export default function baseOnKeyDown(e){

    if(e.ctrlKey && e.keyCode == 32){
        this.toggleSweetSpot();
    }

    if(e.keyCode == 116 || (e.ctrlKey && e.keyCode == 82) || (e.ctrlKey && (e.which || e.keyCode) == 116) || (e.ctrlKey && (e.which || e.keyCode) == 82) ){
        if(this.needSave() == false){
        e.preventDefault();
        this.route(false);
        }else{
            this.alert("Provedené změny nebudou po odchodu uloženy. Ulože prosím záznam.");
        }
      }
}