import React from "react";
import moment from 'moment';
import PureComponent from "../../pure";
import Select from '../../formElements/Select';
import Button from "../../formElements/Button";

import sAction from "sAction";
import Loader from "../../loader";

export default class QuoteCreateQuoteFromTemplate extends PureComponent {
    constructor(props) {
        super(props);

        let date_quote_from = new Date();
        let date_quote_expected_closed = new Date(moment().add(30, 'days').format("YYYY-MM-DD"));

        let febPom = "-02-28";
        if (moment().isLeapYear()) {
            febPom = "-02-29";
        }

        const pomDate = new Date(moment().format("YYYY" + febPom));

        if (date_quote_from < pomDate && date_quote_expected_closed > pomDate) {
            date_quote_expected_closed = pomDate;
        }


        this.state = {
            prefix: props.data?.get("prefix"),
            trans_period: 0,
            date_quote_from: date_quote_from,
            date_quote_expected_closed: date_quote_expected_closed,
            systemTemplates: [],
            userTemplates: [],
            loaded: false,
            selectedTemplate: null,
        }
    }

    handleCreate() {
        if (!this.state.selectedTemplate) {
            sAction.error(sAction.translate("LBL_NO_TEMPLATE_SELECTED"));
            return;
        }

        const params = {
            action: "quote_create_quote_from_template",
            template: this.state.selectedTemplate,
        };

        sAction.rest.post("customAction", params, returnData => {
            if(!returnData.status){
                sAction.error(sAction.translate("LBL_ERROR"))
            }

            sAction.dataSet(`${this.state.prefix}/customData/productData/groups`, []);

            returnData.message.data.forEach((group, index) => {
                group.way = `${this.state.prefix}/customData/productData/groups`;
                group.bundle_id = ""
                group.id = ""
                this.updateCustomLines("addProdGroup", group)
                group.product_lines.forEach(product => {
                    product.way = `${this.state.prefix}/customData/productData/groups/${index}/lines`
                    product.id = ""
                    this.updateCustomLines("addProdLine", product)
                })
            })
            sAction.popupHide()
        });

    }

    updateCustomLines(type, data) {
        sAction.dsClear()
        switch (type) {
            case 'addProdGroup':
                sAction.dsAdd("add", data.way, {
                    id: data.bundle_id,
                    name: data.name,
                    type: "normal",
                    description: data.description,
                    bundle_stage: data.bundle_stage,
                    cenotvorba_discount: data.cenotvorba_discount,
                    cenotvorba_discount_select: data.cenotvorba_discount_select,
                }, "productGroup");
                break;
            case 'addProdLine':
                sAction.dsAdd("add", data.way, data, "product");
                break;
        }
        sAction.dsProcess()
    }

    componentDidMount() {
        sAction.rest.post("getQuoteTemplates", {}, templates => {
            if (!templates) {
                sAction.popupHide();
                // Nemáte přiřazeny žádné aktivní šablony.
                sAction.error(sAction.translate("LBL_NO_QUOTE_TEMPLATES_FOUND", "Quotes"));
                return;
            }
            let systemTemplates = []
            let userTemplates = []

            templates.forEachObject?.((templateObj, templateKey) => {
                if (templateObj.length === 0 || typeof templateObj === 'string') {
                    return;
                }

                if (templateObj && templateKey === "user_templates") {
                    templateObj.forEach(template => {
                        userTemplates.push(template)
                    })
                } else if (templateObj && templateKey === "system_templates") {
                    templateObj.forEach(template => {
                        systemTemplates.push(template)
                    })
                }
            })

            this.setState({
                systemTemplates: systemTemplates,
                userTemplates: userTemplates,
                loaded: true,
            });
        });
    }


    render() {
        if (!this.state.loaded) {
            return <Loader/>;
        }


        let systemTemplateOptions = [];
        let userTemplateOptions = [];

        this.state.systemTemplates.forEach(template => {
            systemTemplateOptions.push({value: template.id, label: template.name});
        });

        this.state.userTemplates.forEach(template => {
            userTemplateOptions.push({value: template.id, label: template.name});
        })

        return (
            <div>
                <div className="viewActionPanelButtons">
                    <Button className="hoverGreen" onClick={() => this.handleCreate()}>
                        <div className={"icon-saveIcon calHeaderIcon"}/>
                        {sAction.translate("LBL_CREATE_QUOTE", "Quotes")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"}/>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
                <div className="templateCreatorFormContainer templateCreatorForm">
                    <div className="templateCreatorFormRow">
                        <div className="templateSelect">
                            <div className="quoteTemplNameLbl">
                                <b>{sAction.translate("LBL_SYSTEM_TEMPLATES")}:</b>
                            </div>
                            <Select
                                open={false}
                                name="system_template_name"
                                options={systemTemplateOptions}
                                onChange={(ev) => this.setState({selectedTemplate: ev.target.value})}
                            />
                        </div>

                        <div className="templateSelect">
                            <div className="quoteTemplNameLbl">
                                <b>{sAction.translate("LBL_USER_TEMPLATE")}:</b>
                            </div>
                            <Select
                                open={false}
                                name="user_template_name"
                                options={userTemplateOptions}
                                onChange={(ev) => this.setState({selectedTemplate: ev.target.value})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
