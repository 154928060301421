export default function checkPSC(psc, prefix = "view", fieldPrefix = "") {
  psc = psc.replace(/[^0-9]+/g, "");

  if (!psc) return;

  this.load();
  this.rest.post("detailapi/checkpsc", { psc }, data => {
    data.forEachObject((v,k) => {
      if (k.charAt(0) === "_") {
        data[fieldPrefix+k] = v;
        delete data[k];
      }
    });
    this.updateFields(data, prefix);
  });
}
