import React from "react";
import PureComponent from "../pure";
import Button from "../formElements/Button";
import Input from "../formElements/InputText";
import  sAction  from "sAction";

export default class ResetPassRequest extends PureComponent {
    constructor() {
        super();

        this.state = {
            errorMessage: '',
            error: false,
            message: '',
        };
        this.name = React.createRef();
    }

    onKeyDown(e) {
        if (e.keyCode == 13) {
            this.createResetPassRequest();
        }
    }

    createResetPassRequest() {
        const login = this.name.current.value;
        if (!login) {
            this.setState({ error: true, errorMessage: '', message: '' });
            return;
        }
        const self = this;
        sAction.createPasswordResetToken(login, (data) => {
            if (!data['status']) {
                self.setState({ error: true, errorMessage: data['message'], message: '' });
                return;
            }
            self.setState({ error: false, message: data['message'], errorMessage: '' });
        });
    }

    render() {
        var ret = !this.state.error && this.state.message ? this.getMessage() : this.getForm();

        return (
            <table className="loginTable" onKeyDown={e => this.onKeyDown(e)}>
                <tbody>
                    <tr>
                        <td colSpan="2" className="login PageLogoContainer"><img src={sAction.param.companyLogo} /></td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        );
    }

    getForm() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className='center error'>
                            {sAction.translate(this.state.errorMessage)}
                        </div>
                        <Input
                            autoFocus={true}
                            error={this.state.error}
                            myRef={this.name}
                            label={sAction.translate("LBL_LOGIN_NAME")}
                            variant="outlined"
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <Button
                                className="loginButton"
                                onClick={() => this.createResetPassRequest()}
                            >
                                {sAction.translate("LBL_RESET_PASSWORD")}
                            </Button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            {sAction.translate("LBL_FORGOTTEN_PASSWORD_RETURN")}
                            &nbsp;
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate("LBL_FORGOTTEN_PASSWORD_RETURN_BUTTON")}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    getMessage() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className={'center'}>
                            {sAction.translate(this.state.message)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate("LBL_BACK_TO_LOGIN")}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}
