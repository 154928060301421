import React, { Component } from 'react'
import PureComponent from '../../pure'
import Button from "../../formElements/Button";

import InvRow from './invRow';
import InvCandidateRow from './invCandidateRow';

import  sAction  from "sAction";

class InviteesContainer extends PureComponent{

    constructor(){
        super();

        this.fname = React.createRef();
        this.lname = React.createRef();
        this.email = React.createRef();
    }

    searchInv(){

        const params = {
            first_name:this.fname.current.value,
            last_name:this.lname.current.value,
            email_address:this.email.current.value,
            prefix: this.props.detailWay,
        }

        sAction.searchInvitees(params, this.props.prefix);
    }

    newContact(){
        sAction.detailPredefinedFields = [];

        let detailWay = this.props.detailWay;//.replace('meetingView', 'detailView').replace('callView', 'detailView');

        let parent_type = sAction.dataGet(detailWay+"/fields/parent_type/value");
        let parent_type2 = sAction.dataGet(detailWay+"/fields/parent_name/def/parent_type_value");
        let parent_name = sAction.dataGet(detailWay+"/fields/parent_name/value");
        let parent_id = sAction.dataGet(detailWay+"/fields/parent_name/def/id_value");
        if((parent_type == "Accounts" || parent_type2 == "Accounts") && parent_id){
            let relate_simple = {
                fieldName: "account_name",
                name: "account_id",
                value: {
                  name: parent_name,
                  id: parent_id
                }
              }

            sAction.detailPredefinedFields.push({
                type:"relate_simple",
                ...relate_simple
            });
        }

        sAction.popupDetail({
            module: "Contacts",
            record: "",
            saveCallback: (conData) => {

                let conParams = {
                    module : "Contacts",
                    id : conData.record,
                    action: "getBeanInfo",
                    fields: ["email1", "phone_work", "phone_mobile"],
                }

                sAction.rest.post("customAction", conParams, (resp) => {

                    if(resp.status){
                        sAction.dsClear();
                        resp.message.email = resp.message.email1;
                        sAction.dsAdd("add", this.props.prefix+"/relatedInv/Contacts",resp.message);
                        sAction.dsProcess();
                    }
                })
            },
            exitCallback: data => {

            }
        });

    }

    render(){
        const data = this.props.data.relatedInv;
        const searchInv = this.props.data.searchInv;
        const prefix = this.props.prefix;
        const pomClass = this.props.pomClass;

        let invArr = [];

        let ind = 0;
        data.Users.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        ind = 0;
        data.Contacts.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        ind = 0;
        data.Leads.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        let searchRes = [];

        ind = 0;
        searchInv.Users.forEach(elem => {
           searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix}/>);
        });

        ind = 0;
        searchInv.Contacts.forEach(elem => {
            searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix}/>);
         });

         ind = 0;
         searchInv.Leads.forEach(elem => {
            searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix}/>);
         });

         const newContactBtn = <a
            className="invCreateContactBtn"
            target="_blank"
            title={sAction.translate("LBL_NEW_CONTACTS")}
            onClick={() => this.newContact()}
          >
            <span className={"icon-Contacts"} />
          </a>;

        return (
            <div className={"pageContainer calInvComponent "+pomClass}>
                <div className="detailViewContainer">
                    <div className="detailcard">
                        <div className="detailView">
                            <div className="detailViewTabContent">
                                <div className="relInvContainer">
                                <table className="invTable">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th title={sAction.translate("LBL_ACCEPT_STATUS", "Meetings")}>{sAction.translate("LBL_ACCEPT_STATUS", "Meetings")}</th>
                                        <th title={sAction.translate("LBL_NAME_HEADLINE", "Contacts")}>{sAction.translate("LBL_NAME_HEADLINE", "Contacts")}</th>
                                        <th title={sAction.translate("LBL_TITLE", "Contacts")}>{sAction.translate("LBL_TITLE", "Contacts")}</th>
                                        <th title={sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}>{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</th>
                                        <th title={sAction.translate("LBL_PHONE_WORK", "Contacts")}>{sAction.translate("LBL_PHONE_WORK", "Contacts")}</th>
                                        <th title={sAction.translate("LBL_PHONE_MOBILE", "Contacts")}>{sAction.translate("LBL_PHONE_MOBILE", "Contacts")}</th>
                                        <th align="center">{newContactBtn}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {invArr}
                                    </tbody>
                                </table>
                                </div>
                                <div className="invSearchContainer">
                                <table>
                                    <tbody>
                                        <tr><td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_FIRST_NAME", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.fname} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_LAST_NAME", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.lname} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.email} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <a onClick={() => this.searchInv()}><div className="icon-refresh calRefreshIcon"></div></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                {searchRes.length > 0 && (
                                <div className="invCandidatesContainer">
                                <table className="invTable">
                                    <thead>
                                    <tr><th></th><th title={sAction.translate("LBL_NAME", "Contacts")}>{sAction.translate("LBL_NAME", "Contacts")}</th><th title={sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}>{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</th><th title={sAction.translate("LBL_PHONE_WORK", "Contacts")}>{sAction.translate("LBL_PHONE_WORK", "Contacts")}</th><th title={sAction.translate("LBL_PHONE_MOBILE", "Contacts")}>{sAction.translate("LBL_PHONE_MOBILE", "Contacts")}</th><th></th></tr>
                                    </thead>
                                    <tbody>
                                    {searchRes}
                                    </tbody>
                                </table>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default InviteesContainer
