export default function editFieldToEdit(way,module,def){ 

    const optionsList = [{ label: "", value: "" }];
    this.app_strings.forEachObject((element, index) => {
      if (typeof element === "object") {
        optionsList.push({ label: index, value: index });
      }
    });
    this.temporaryData.optionsList = optionsList

    this.popup("editFieldsNewField",{
      closeFromBc: false,
      customClass:"popupNewField",
        new: false,
        name:def.get("name"),
        type:def.get("type"),
        required: def.get("required") == true ? 1 : 0,
        default:def.get("default"),
        help: def.get("help"),
        min: def.get("min"),
        max: def.get("max"),
        unified_search: def.get("unified_search") == true ? 1 : 0,
        readonly: def.get("readonly"),
        massupdate: def.get("massupdate"),
        selectOption : def.get("options") ?? "",
        header: this.translate("LBL_CREATE_NEW_FIELD"),
        module:module,
        way:way,
        view:"newField"
    });
}