import InputField from "../inputFieldClass";
import  sAction  from "sAction";

export default class ApiName extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
    //  reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/
    };
  }

  check() {
    let ok = super.check();

    const value = this.getValue();

    if (ok && value && value!==this.props.data.value) {
      sAction.checkName(value);
    }

    return ok;
  }
}
