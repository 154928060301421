import PureComponent from "../pure";
import  sAction  from "sAction";

export default class FormField extends PureComponent {
  componentDidMount() {}
  getTabData(shift = false) {
    if (shift === false) {
      return {
        name: this.props.data.name,
        prefix: this.props.prefix,
        index: this.props.index,
        rowWay: this.props.rowWay,
        type:this.props.data.def.get("type"),
        shift: false
      };
    } else {
      return {
        name: this.props.data.name,
        prefix: this.props.prefix,
        index: this.props.index,
        rowWay: this.props.rowWay,
        type:this.props.data.def.get("type"),
        shift: true
      };
    }
  }
  onKeyDown(e, type = null, save = true) {
    var data = null;
    if (e.shiftKey && e.keyCode === 9) {
      if (type === null || type === "first") {
        data = this.getTabData(true);
      }
    } else if (e.shiftKey === false && e.keyCode === 9) {
      if (type === null || type === "last") {
        data = this.getTabData(false);
      }
    }
    if (data != null && data.prefix) {
      e.preventDefault();
      if (save === true) {
        if (this.check()) {
          this.save(e);
        } else {
          data["error"] = true;
        }
      }
      sAction.detailTabNext(data,save);
    }
  }

  getValue() {
    const value = this.refs.input.value;
    return { name: this.props.data.name, value: value };
  }
}
