import DefaultParent from "../../defaultFields/Parent/Parent";
import  sAction  from "sAction";

export default class Parent extends DefaultParent {

	getParentModule() {
	  return this.props.data.def.get("parent_type_value");
	}
	
	saveField(data, doUpdate = true, isChangeModule = false) {
		sAction.saveField(data, doUpdate);
	}
}
