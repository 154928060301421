import React, { PureComponent } from "react";
import InputText from "../formElements/InputText";
import Button from "../formElements/Button";
import Select from "../formElements/Select";
import  sAction  from "sAction";
import EmailRelateField from "./EmailRelateField";

//pro datepicker musi byt orig material
import AcmDate from "../formElements/AcmDate";
import EmailAssignField from "./EmailAssignField";

export default class EmailSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emailUIAction: "searchAdvanced",
      mbox: "Search Results",
      id: "search",
      ieId: "",
      getUnread: 0,
      name: "",
      from_addr: "",
      to_addrs: "",
      body_text: "",
      searchDateFrom: undefined,
      searchDateTo: undefined,
      assigned_user_name: "",
      assigned_user_id: "",
      data_parent_id_search: "",
      data_parent_name_search: "",
      attachmentsSearch: "",
      data_parent_type_search: "",
    };
  }

  search = () => {
    let data = { ...this.state };
    if (data.searchDateFrom)
      data.searchDateFrom = data.searchDateFrom;
    if (data.searchDateTo) data.searchDateTo = data.searchDateTo;

    const { way, search } = this.props;

    data.mbox = search.mbox;
    data.ieId = search.ieId;
    data.isImap = search.isImap;
    data.limit = search.limit;

    sAction.emailGetList(data, way);
  };

  resetSearch = () => {
    this.setState({
      getUnread: 0,
      name: "",
      from_addr: "",
      to_addrs: "",
      body_text: "",
      searchDateFrom: undefined,
      searchDateTo: undefined,
      assigned_user_name: "",
      assigned_user_id: "",
      data_parent_id_search: "",
      data_parent_name_search: "",
      attachmentsSearch: "",
      data_parent_type_search: "",
    }, () => {
      this.search();
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRelate = (returnData) => {
    this.setState({
      data_parent_id_search: returnData.id,
      data_parent_name_search: returnData.name,
      data_parent_type_search: returnData.type,
    });
  };

  handleAssign = (returnData) => {
    this.setState({
      assigned_user_id: returnData.id,
      assigned_user_name: returnData.name,
    });
  };

  close = () => {
    sAction.dataSet(this.props.way + "/advancedSearch", false);
  };

  render() {
    const { name, from_addr, to_addrs, body_text, searchDateFrom, searchDateTo, attachmentsSearch } = this.state;

    return (
      <div className={this.props.className} onKeyUp={(event) => event.keyCode === 13 && this.search()}>
        {/*Rozšířené hledání*/}
        <h1>{sAction.translate("LBL_EMAIL_SEARCH_ADVANCED")}</h1>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
            <InputText
              margin="dense"
              type="text"
              // label="Předmět"
              label={sAction.translate("LBL_SUBJECT", "Emails")}
              name="name"
              value={name}
              onChange={this.handleChange}
            />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
            <InputText
              margin="dense"
              type="text"
              // label="Tělo zprávy"
              label={sAction.translate("LBL_BODY", "Emails")}
              name="body_text"
              value={body_text}
              onChange={this.handleChange}
            />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
            <InputText
              margin="dense"
              type="text"
              // label="Odesílatel"
              label={sAction.translate("LBL_FROM", "Emails")}
              name="from_addr"
              value={from_addr}
              onChange={this.handleChange}
            />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
            <InputText
              margin="dense"
              type="text"
              // label="Komu"
              label={sAction.translate("LBL_EMAIL_TO")}
              name="to_addrs"
              value={to_addrs}
              onChange={this.handleChange}
            />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
            <AcmDate
              placeholder={sAction.translate("LBL_EMAIL_SEARCH_DATE_FROM", "Emails")}
              value={searchDateFrom}
              onChange={(searchDateFrom) => this.setState({ searchDateFrom: searchDateFrom ? searchDateFrom : "" })}
            />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        <div className="email__compose__field-row">
          <div className="email__compose__field-row__input">
          <AcmDate
              placeholder={sAction.translate("LBL_EMAIL_SEARCH_DATE_UNTIL", "Emails")}
              value={searchDateTo}
              onChange={(searchDateTo) => this.setState({ searchDateTo: searchDateTo ? searchDateTo : "" })}
           />
          </div>
          <div className="email__compose__field-row__button" />
        </div>

        {this.props.search.isImap !== true && (
          <React.Fragment>
            <EmailAssignField onChange={this.handleAssign} />

            <div className="email__compose__field-row">
              <div className="email__compose__field-row__input">
                <Select
                  // label="Má přílohu"
                  label={sAction.translate("LBL_HAS_ATTACHMENT", "Emails")}
                  options={[
                    //Nerozhoduje
                    {
                      value: "",
                      label: sAction.translate("LBL_EMAIL_DOES_NOT_MATTER", "Emails"),
                    },
                    // Ano
                    { value: "1", label: sAction.translate("LBL_EMAIL_YES") },
                    // Ne
                    { value: "0", label: sAction.translate("LBL_EMAIL_NO") },
                  ]}
                  name="attachmentsSearch"
                  value={attachmentsSearch}
                  onChange={this.handleChange}
                />
              </div>
              <div className="email__compose__field-row__button" />
            </div>

            <EmailRelateField onChange={this.handleRelate} />
          </React.Fragment>
        )}

        <br />
        <br />
        <div className="email__compose__field-row">
          <Button onClick={this.search} className="stillHover">
            {/*Hledat*/}
            {sAction.translate("LBL_SEARCH", "Home")}
          </Button>
          <Button onClick={this.resetSearch} className="stillHover">
            {/*Vyprázdnit*/}
            {sAction.translate("LBL_SEARCH_RESET", "Emails")}
          </Button>
          <Button onClick={this.close} className="stillHover">
            {/*Zavřít*/}
            {sAction.translate("LBL_EMAIL_CLOSE", "Emails")}
          </Button>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
