export default function deleteRecord(paramData){
    var self = this;
    const module = paramData.module;
    const isFromRightPanel = paramData.type === 'rightPanel';

    // promazani zmen v local storage
    this.removeChangesFromLS(module,this.dataGet('conf/user/id'))

    this.dsClear();
    this.dsAdd("set","conf/load",true);
    this.dsAdd("set","conf/popup/show",false);
    this.dsProcess();

    this.rest.delete("delete/"+module+"/"+paramData.id,null,() => {
        self.dataSet(paramData.prefix+"/changes", {fields: [], files: []})
        if ( ! isFromRightPanel) {
            window.location.href = "#list/"+module;

            return;
        }

        this.route();
    });
}
