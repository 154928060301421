


export default function changeDetailTab(index,prefix,prevIndex,fields){
    if(index !== prevIndex){
      this.dsClear();

      fields?.entrySeq().forEach(e => {
            if(e[1].actEdit == true){
              this.dsAdd("set",prefix+"/fields/"+e[0]+"/actEdit",false);
            }
      });

      this.dsAdd("set",prefix+"/tabs/"+prevIndex+"/active",false);
      this.dsAdd("set",prefix+"/tabs/"+index+"/active",true);
      this.dsAdd("set",prefix+"/activeTab",index);
      this.dsProcess();
    }
  }
