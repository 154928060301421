import React from "react";

import DropZone from "../DropZone/DropZone";
import  sAction  from "sAction";

export default class EmailUploadFile extends React.PureComponent {
  state = {
    hidden: true,
    uploading: {
      percent: 100,
      loaded: 0,
      total: 0
    }
  };

  update = files => {
    const data = {
      data : {
        cache: true,
        note: files
      },
      action: "createNote",
      responseType: "json",
      success: response => {
        //prilohy se ulozi do storu
        sAction.dsClear();
        response.forEach(f => {
          sAction.dsAdd("set", this.props.way + "/" + f.id, f);
        });
        sAction.dsProcess();
      },
      progress: progress => {
        let { loaded, total, percent } = progress;
        percent = Math.round((Math.ceil(percent) || 0) / 4) * 4;
        if (percent !== this.state.uploading.percent)
          this.setState({ uploading: { percent, loaded, total } });
      }
    }

    sAction.filesXhr(data);
  };

  delete = (i, deleteAll = false) => {
    if (!this.props.attachments) return;

    const a = this.props.attachments.get(i);

    if (!deleteAll) sAction.dsClear();
    sAction.dsAdd("delete", this.props.way, i);

    // uzivatel maze prave nahravane soubory - smazou se ze serveru a storu
    if (a.get("source") !== "sugar" && a.get("source") !== "sugartemplate") {
      sAction.rest.post("email/removeUploadedAttachment", {
        file: a.get("id") + a.get("name")
      });
    }

    if (!deleteAll) sAction.dsProcess();
  };

  deleteAll = e => {
    e.preventDefault();
    e.stopPropagation();

    sAction.confrim(
      sAction.translate("LBL_EMAIL_UPLOAD_DELETE_ALL_CONFIRM", "Emails"),
      () => {
        sAction.popupHide();

        sAction.dsClear();
        this.props.attachments
          .keySeq()
          .forEach(id => {
            this.delete(id, true);
          });
        sAction.dsProcess();
      }
    );
  };

  addSugarDocument = e => {
    e.stopPropagation();
    e.preventDefault();

    this.changeHidden();

    const paramData = {
      module: "Documents",
      selectedActive: true,
    };
    sAction.openRelatePopup(paramData, returnData => {
      sAction.load();
      sAction.rest.post("email/getSugarDocumentSize", {document_id: returnData.id}, docs => {
        sAction.unLoad();
        sAction.popupHide();
        sAction.dsClear();
        let errors = [];
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].size) {
            sAction.dsAdd("set", this.props.way + "/" + docs[i].id, docs[i]);
          } else {
            errors.push(docs[i].name);
          }
        }
        sAction.dsProcess();

        if (errors.length) {
          sAction.error(sAction.translate("LBL_UPLOAD_FILES_NOT_FOUND", "Emails") + ":<br><br>" + errors.join("<br>"));
        }
      });
    });
  };

  changeHidden = () => {
    if (this.state.hidden) {
      this.setState({ hidden: false });
      //return;
    }
  };

  downloadFile = e => {
    e.stopPropagation();
    e.preventDefault();
    const attachment = this.props.attachments.get(e.target.dataset.i)
    if (!attachment) return;
    const id = attachment.get("id");
    const name = attachment.get("name");

    const imapData = attachment.get("imapData");

    if (imapData) {
      sAction.downloadFile({
        id,
        name,
        module: 'Emails',
        partNum: imapData.get("partnum"),
        ieId: imapData.get("ieId"),
        uid: imapData.get("uid"),
        isImap: true,
      });
    } else {
      sAction.downloadFile({
        id,
        name,
        module: 'Emails',
        source: attachment.get("source"),
      });
    }
  };

  render() {
    const { hidden, uploading } = this.state;
    return (
        <DropZone
          attachments={this.props.attachments}
          addSugarDocument={this.addSugarDocument}
          hidden={hidden}
          delete={this.delete}
          deleteAll={this.deleteAll}
          update={this.update}
          uploading={uploading}
          changeHidden={this.changeHidden}
          handleDownload={this.downloadFile}
        />
    );
  }
}
