export default function customDataUpdate({ way, recordMap, recordIndex, field, value, computed }) {
	const record = recordMap.toJS();
	const absoluteWay = way + "/records/" + recordIndex;
	const changesWay = absoluteWay.replace("/customData", "/changes/customData");
	const moduleWay = (way + "/module").replace("/customData", "/changes/customData");
	record[field] = value;
	if (computed) {
		computed.forEachObject((expression, relatedField) => {
			const command = expression.replace(/\[([a-zA-Z_]+)\]/g, (str, expressionField) => {
				return 'parseFloat(record["' + expressionField + '"])';
			});
			const result = eval(command);
			record[relatedField] = result;
		});
	}
	this.dsClear();
	this.dsAdd('set', moduleWay, this.dataGet(way + "/module"));
	this.dsAdd('set', absoluteWay, record);
	this.dsAdd("set", changesWay, record);
	this.dsProcess();
}