export default { 
    fields: [
        {
            name: "mft_part_num_int",
            type: "text",
            lbl: "LBL_MFT_PART_NUM_INT",
            lblMod : "ProductTemplates",
            visible: true,
            disabled: true,
            width: 7
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 23
        },
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 6
        },
        // {
        //     name: "cost_price",
        //     type: "price",
        //     lbl: "LBL_LIST_COST_PRICE",
        //     lblMod : "Quotes",
        //     visible: false,
        //     disabled: false,
        //     width: 12
        // },
        {
            name: "mj",
            type: "text",
            lbl: "LBL_MJ",
            lblMod : "Products",
            visible: true,
            disabled: true,
            width: 5
        },
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 9
        },
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 9
        },
        {
            name: "discount_select",
            type: "discount_select",
            lbl: "",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 6
        },
        {
            name: "margin_check",
            type: "semaphore",
            lbl: "LBL_MARGIN_CHECK",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 4
        },
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "opce_c",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 5
        },
        {
            name: "skl_stav",
            type: "float",
            lbl: "Dostupnost",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "skl_popt",
            type: "float",
            lbl: "Rezervace",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "skl_obj",
            type: "float",
            lbl: "Objednáno",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_render_field",
            type: "stock",
            lbl: "LBL_STOCK_RENDER",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 5
        },
    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "36%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        // {
        //     name: "total_cost_price",
        //     type: "price",
        //     lbl: "LBL_TOTAL_COST_PRICE",
        //     lblMod: "Quotes",
        //     visible: true,
        //     width: "9%"
        // },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            name: "total_discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            type: "space",
            visible: true,
            width: "22%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        }
    ]
};
