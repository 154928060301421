import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";

import NewRecordLine from "./NewRecordLine"

export default class NewRecordContainer extends PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {}
  componentDidUpdate() {}

  sortByLabel( a, b ) {
    const alabel = a && typeof a.label === "string" ? a.label : "";
    return alabel.localeCompare(b.label);
  }

  render() {
    const def = this.props.def;
    const module = this.props.module;
    const way = this.props.way
    const record = this.props.record;

    var linesRender = [];

    let linesData = [];

    let ignoredTypes = ["id", "link"];
    def.entrySeq().forEach((e, key) => {
      let value = record.get(e[0]);
      if(record.get(e[0])){
        value = sAction.getValueOfMergeField(record.get(e[0]), e[1]);
      }
      if((ignoredTypes.includes(e[1].get("type")) || e[1].get("dbType") === "id") && e[0] !== "currency_id"){
        return;
      }

      let label = sAction.translate(e[1].get("vname"), this.props.module);
      label = label.charAt(0).toUpperCase() + label.slice(1).replace(":", "");

      linesData.push({
        label,
        field: e[0],
        def: e[1],
        value
      });
    });

    linesData.sort(this.sortByLabel);

    linesRender = [];
    linesData.forEach(e => {
      linesRender.push(<NewRecordLine key={e.field} way={way} module={module} field={e.field} def={e.def} value={e.value} label={e.label} />)
    });

    return (
      <div className="recordContainer new">
        <div className="mergeRecordHeader">
          <div>{sAction.translate("LBL_MERGE_RECORDS_RESULT_HEADER")}</div>
          <div>
            <div
              className="icon-deleteIcon mergeAddFieldButton"
              title={sAction.translate("LBL_DELETE")}
              onClick={() => sAction.dataSet(way+"/newRecord",{})}
            />
          </div>
        </div>
        <div className="mergeRecordBody">
          {linesRender}
        </div>
      </div>
    );
  }
}
