import React from "react";
export default function Date(props) {
    
    var lineRender = props.value
    

  return (
    <div className={"newListViewLine " + props.extraClass} onClick={props.onClick}><span>{lineRender}</span></div>
  );
}
