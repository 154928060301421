import React from "react";

import InputField from "../inputFieldClass";
import InputText from "../../formElements/InputText";
import  sAction  from "sAction";
import TickBox from "../../formElements/TickBox";
import Radio from "../../formElements/Radio";

import Tooltip from '@material-ui/core/Tooltip';

export default class Email extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      checkedDuplicity: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  componentDidMount() {
    const count = this.props.data.def.get("emails").size;
    if (!count) {
      this.addEmail("1");
    }
  }

  onChange = (e) => {
    let i = e.target.value;
    let val = e.target.checked ? "1" : "0";
    const way = this.props.way; // view/fields/email1
    sAction.dsClear();

    switch (e.target.name) {
      //odhlaseny
      case "OptOutFlag":
        sAction.dsAdd("set", way + "/def/emails/" + i + "/opt_out", val);
        break;

      //nevalidni
      case "InvalidFlag":
        sAction.dsAdd("set", way + "/def/emails/" + i + "/invalid_email", val);
        break;

      //primarni
      case "PrimaryFlag":
        const v = parseInt(i, 10);
        const count = this.props.data.def.get("emails").size;

        for (let j = 0; j < count; j++) {
          const val = j === v ? "1" : "0";
          sAction.dsAdd("set", way + "/def/emails/" + j + "/primary_address", val);
        }
        break;

      default:
        // meni se email
        sAction.dsAdd("set", way + "/def/emails/" + e.target.name + "/email_address", i);
        sAction.dsAdd("set", way + "/def/emails/" + e.target.name + "/error", this.checkError(i));

        break;
    }

    sAction.dsProcess();

    const canSave = this.indicateChange();

    return canSave;
  };

  indicateChange = () => {
    const canSave = !this.checkErrors();
    this.checkDuplicityInModule();

    sAction.dsClear();
    sAction.dsAdd("set", this.props.prefix + "/changes/fields/email1", true);
    sAction.dsAdd("set", this.props.way + "/value", canSave || null); //aby to (ne)proslo pres required field
    sAction.dsAdd("set", this.props.prefix + "/canSave", canSave);
    sAction.dsAdd("set", this.props.way + "/def/edit", true);
    sAction.dsProcess();

    return canSave;
  };

  keyUpCall(e) {
    const keyCode = e.keyCode;
    if ((keyCode == 13 || keyCode == 27) && e.ctrlKey === false) {
      if (keyCode == 13) {
        const canSave = this.onChange(e);
        if (!canSave) return;
      }
      this.save();
    }
  }

  saveCheck = () => {
    //nutny timeout, aby se pockalo na predchozi udalost...
    setTimeout(() => {
      if (!this.checkErrors()) {
        this.save();
      }
    }, 1000);
  }

  save = () => {
    this.checkDuplicityInModule();
    sAction.dataSet(this.props.way + "/actEdit", false);
  };

  checkDuplicityInModule = () => {
    if (this.props.data.def.get('checkDuplicity') && this.state.checkedDuplicity === false) {
      this.setState({checkedDuplicity:true})
      sAction.checkDuplicityPost({
        field: this.props.data.name,
        value: sAction.dataGet(this.props.way+"/def/emails"),
        vname: sAction.dataGet(this.props.way+"/def/vname"),
        prevValue: '',
        isEmail: true,
        prefix: this.props.prefix,
        module: this.props.module
      },() => {
        this.setState({checkedDuplicity:false})
      });
    }
  }

  checkError = (email) => {
    if (!email) return false;
    let isValid = this.state.reg.test(email);
    return isValid ? false : sAction.translate("LBL_WRONG_VALUE");
  };

  /**
   * Je v emailech chyba?
   * @returns {boolean} vraci true, pokud byla nalezena chyba
   */
  checkErrors = () => {
    const emails = sAction.dataGet(this.props.way + "/def/emails");
    const isRequiredField = this.props.data.def.get("required");
    const existingEmails = [];
    let isError = false;

    if (emails && emails.size) {
      let i = -1;
      for (let email of emails) {
        i++;
        email = email.get("email_address");
        if (email) {
          const isValid = this.state.reg.test(email);
          if (!isValid) {
            isError = true;
          }
          existingEmails.push(email);
          // pokud je email validni, ale je jiz jednou na seznamu...
          const ccount = existingEmails.filter(x => x === email).length;
          if (ccount > 1) {
            // Tento e-mail je již na seznamu.
            sAction.dataSet(this.props.way + "/def/emails/" + i + "/error", sAction.translate("ERR_EMAIL_ALREADY_ON_LIST", "Emails"));
            isError = true;
          }
        } else if (isRequiredField && !existingEmails.length) {
          isError = true;
        }
      }
    } else if (isRequiredField) {
      isError = true;
    }

    return isError;
  }

  deleteEmail = (e) => {
    const way = this.props.way;
    sAction.dataDelete(way + "/def/emails", e.target.dataset.id);

    this.indicateChange();
  };

  addEmail = (primary_address = "0") => {
    sAction.dataAdd(this.props.way + "/def/emails", {
      email_address: "",
      opt_out: "0",
      invalid_email: "0",
      primary_address,
    });
  };

  unlockRecord() {
    sAction.dataSet(this.props.prefix + "/canSave", false);
  }

  componentWillUnmount() {
    const emails = sAction.dataGet(this.props.way + "/def/emails");
    const isRequiredField = this.props.data.def.get("required");
    if (isRequiredField) {
      return;
    }

    if (emails && emails.size) {
      let i = 0;
      for (let email of emails) {
        email = email.get("email_address");
        const isValid = this.state.reg.test(email);
        if (!isValid) {
          sAction.dataDelete(this.props.way + "/def/emails", i);
        }
        i++;
      }
    }

    this.indicateChange();
  }

  render() {
    const data = this.props.data;
    var containerClass = "inputEditContainer";

    let content = [];

    const emails = data.def.get("emails");

    emails.forEach((email, i) => {
      let key = email.get("email_address_id");
      if (!key) key = email.get("email_address");

      let error = email.get("error");
      
      content.push(
        <tr key={key + i}>
          <td>
            <div className={containerClass} data-fieldname={data.name} data-fieldvalue={email.get("email_address")}>
              <div className="inputContainer">
                <InputText
                  onKeyUp={(e) => this.keyUpCall(e)}
                  onKeyDown={(e) => i == 0 && this.onKeyDown(e, "first")}
                  autoFocus={i==0 ? true : false}
                  //onKeyDown={e => this.onKeyDown(e)}
                  myRef={this.input}
                  onBlur={this.onChange}
                  error={!!error}
                  defaultValue={email.get("email_address")}
                  name={i.toString()}
                />
              </div>
              <div className="buttonContainer">
              {!!error &&
              (
                <div
                tabIndex="-1"
                className="warningMessage inputEditButton"
                >
                  <Tooltip title={error} arrow open>
                    <div className="icon-warning" onMouseDown={this.saveCheck} />
                  </Tooltip>
                </div>
              )}
                <div tabIndex="0" className="inputEditButton" onMouseDown={this.saveCheck}>
                  <div className="icon-detailConfrim"></div>
                </div>
                <div className=" inputEditButton">
                  <div
                    tabIndex="0"
                    className={"icon-detailCancel"}
                    onClick={this.deleteEmail}
                    data-id={i}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        this.deleteEmail(e);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </td>
          <td>
            <Radio
              checked={email.get("primary_address") === "1"}
              onChange={(checked) =>
                this.onChange({
                  target: {
                    name: "PrimaryFlag",
                    value: i,
                    checked,
                  },
                })
              }
            />
          </td>
          <td>
            <TickBox
              checked={email.get("opt_out") === "1"}
              onChange={(checked) =>
                this.onChange({
                  target: { name: "OptOutFlag", value: i, checked },
                })
              }
            />
          </td>
          <td>
            <TickBox
              checked={email.get("invalid_email") === "1"}
              tabIndex={0}
              onChange={(checked) =>
                this.onChange({
                  target: { name: "InvalidFlag", value: i, checked },
                })
              }
            />
          </td>
        </tr>
      );

    });

    const primary = sAction.translate("LBL_EMAIL_PRIMARY");
    const opt_out = sAction.translate("LBL_EMAIL_OPT_OUT", "Emails");
    const invalid = sAction.translate("LBL_EMAIL_INVALID");

    const primary_letter = primary[0];
    const opt_out_letter = opt_out[0];
    const invalid_letter = invalid[0];

    const astyle = !emails.size && data.def.get("required") ? {color: "red"} : {};

    return (
      <React.Fragment>
        {emails.size > 0 && (
          <table className="DetailViewValue__emails">
            <tbody>
              <tr>
                <th/>
                {/*Primární*/}
                <th><span title={primary}>{primary_letter}</span></th>
                {/*Odhlášený*/}
                <th><span title={opt_out}>{opt_out_letter}</span></th>
                {/*Neplatný*/}
                <th><span title={invalid}>{invalid_letter}</span></th>
              </tr>
              {content}
            </tbody>
          </table>
        )}
        {/*Přidat e-mail*/}
        <a
        onKeyDown={e => this.onKeyDown(e, "last")}
        onClick={() => this.addEmail()}
          tabIndex="0"
          style={astyle}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              this.addEmail();
            }
          }}
        >
          + <span className="icon-Emails" />
          {" "+sAction.translate("LNK_NEW_EMAIL", "Contacts")}
        </a>
      </React.Fragment>
    );
  }
}
