import React, { PureComponent } from "react";
import  sAction  from "sAction";
import Checkbox from "../formElements/TickBox";

import Favorite from "@material-ui/icons/Star";
import FavoriteBorder from "@material-ui/icons/StarBorder";

export class EmailListEmails extends PureComponent {
  openEmail = (email, e) => {
    this.props.openEmail(email, e);
  };

  render() {
    const { emails, selectedData } = this.props;

    if (!emails.size) {
      return (
        <tr>
          <td>
            <div className="email__wrapper">
              <h3>
                {/*Nenalezeny žádné e-maily.*/}
                <em style={{ color: "gray" }}>{sAction.translate("LBL_NO_EMAILS_FOUND", "Emails")}</em>
              </h3>
            </div>
          </td>
        </tr>
      );
    }

    return emails.map((email, i) => {
      // viz DataConstructors.EmailList
      let from = email.from ? sAction.htmlDecode(email.from).replace(/\s*<[^>]+>/g, "") : "";
      let to_addrs = email.to_addrs ? sAction.htmlDecode(email.to_addrs).replace(/\s*<[^>]+>/g, "") : "";

      let show_from = true;
      let show_to = true;

      // kdyz je slozka odchozi nebo rozepsana, nebude se zbytecne zobrazovat muj email jakozto odesilatele
      switch (email.type) {
        case "out":
        case "draft":
          show_from = false;
          break;

        // kdyz je slozka prichozi, nebude se zbytecne zobrazovat, ze se ten email posila mne
        case "inbound":
        case "remote":
          show_to = false;
          break;
      }

      let date_sent = <span title={sAction.mysqlToDate(email.date, true)}>{sAction.mysqlToDate(email.date)}</span>;

      let email_subject = email.subject || sAction.translate("LBL_NO_SUBJECT", "Emails");
      email_subject = email_subject.replace(/<[^>]+>/g, "");

      let email_text = (
        <React.Fragment>
          <span className="email__list__body__table__row__preview__title">{email_subject}</span>
          {typeof email.description === "string" && email.description && (
            <span className="email__list__body__table__row__preview__description">
              {" "}
              &ndash; {email.description.replace(/<[^>]+>/g, "")}
            </span>
          )}
        </React.Fragment>
      );

      const checked = selectedData.indexOf(email.uid) > -1;

      let className =
        email.seen == 0 || email.seen == "0"
          ? "email__list__body__table__row email__list__body__table__row--unread"
          : "email__list__body__table__row";

      if (checked || this.props.selectedEmail === email.uid) {
        className += " email__list__body__table__row--highlighted";
      }
      return (
        <tr
          key={email.uid}
          className={className}
          draggable
          onDragStart={(e) => {
            this.props.handleCheckboxChange(true, email.uid);
            this.props.handleOnDragStart(e);
          }}
          onDragOver={this.props.handleDragOver}
          onContextMenu={(e) => this.props.openContextMenu(e, email)}
        >
          <td className="email__list__body__table__row__checkbox">
            {(email.seen == 0 || email.seen == "0") && (
              <div className="email__list__body__table__row__checkbox__vline" />
            )}
            <Checkbox checked={checked} onChange={(checked) => this.props.handleCheckboxChange(checked, email.uid)} />
          </td>

          <td onClick={(e) => this.openEmail(email, e)} className="email__list__body__table__row__preview">
            <div>
              {show_from && (
                <div className="email__list__body__table__row__preview__name" title={"Od: " + from}>
                  {from}
                </div>
              )}
              {show_to && (
                <div className="email__list__body__table__row__preview__name" title={"Komu: " + to_addrs}>
                  {to_addrs}
                </div>
              )}
              <span title={email.description}>{email_text}</span>
            </div>
          </td>

          <td className="email__list__body__table__row__date">
            {!!email.hasAttach && <span className="icon-attachment" />} {date_sent}
            <br />
            <Checkbox
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              disableRipple
              //defaultChecked={email.flagged === 1}
              defaultChecked={email.flagged != ""}
              //onChange={checked => console.log(checked)}
              onChange={(checked) =>
                this.props.markFlagged({
                  type: checked ? "flagged" : "unflagged",
                  uids: [email.uid],
                  folder: email.mbox,
                  ieId: email.ieId,
                })
              }
            />
          </td>
        </tr>
      );
    });
  }
}
