import React from "react"
import PureComponent from "../../pure"
// import InputText from '../../formElements/InputText';
import  sAction  from "sAction";
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel"

export default class EmailSelectFolderPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFolders: props.data.get("value").split(",")
    };
  }

  handleChange = e => {
    var { name, options } = e.target;
    var value = [];
    let i = 0
    const l = options.length;
    for (; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    this.setState({ [name]: value });
  };

  savePopup = () => {
    sAction.popupHide();

    const data = this.props.data;
    data.get("callback")(data.get("input"), this.state.selectedFolders.join(","));
  };

  render() {
    const data = this.props.data;

    const folder = data.get("input");
    const folders = data.get("folders").split(",");
    const { selectedFolders } = this.state;

    let translation = "";
    switch (folder) {
      case "mailbox":
        translation = "LBL_SELECT_SUBSCRIBED_FOLDERS";
        break;
      case "trashFolder":
        translation = "LBL_SELECT_TRASH_FOLDERS";
        break;
      case "sentFolder":
        translation = "LBL_SELECT_SENT_FOLDERS";
        break;
    }

    return (
      <div className="email__popup">
        <div className="acmPopupHeader">
          {/*Vazba*/}
          {sAction.translate(translation, "InboundEmail")}
        </div>

        <EmailPopupActionPanel onSave={this.savePopup} />

        <div style={{padding: 30}}>

        {folder == "mailbox" ? sAction.translate("LBL_EMAIL_SUBSCRIPTION_FOLDER_HELP") : ""}

        <div className="email__popup__items">
          <select
            size="12"
            name="selectedFolders"
            onChange={this.handleChange}
            multiple={folder == "mailbox"}
            defaultValue={folder == "mailbox" ? selectedFolders : selectedFolders.join()}
          >
            {folders.map(folder => (
              <option
                key={folder}
                value={folder}
              >
                {folder}
              </option>
            ))}
          </select>
        </div>

        </div>
      </div>
    );
  }
}
