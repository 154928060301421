import React from "react";
import MaterialSelect from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import Select from "./Select";

export default class SelectAcm extends Select {

  render() {
    var labelRender = null;
    if (this.props.label != undefined) {
      labelRender = (
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
      );
    }
    const options = this.props.options;

    var optionsRender = [];
    options.forEach((value, key) => {
      if (value.type == "header") {
        optionsRender.push(
          <MenuItem key={key} disabled={true} className="acmSelectHeader">
            {value.label}
          </MenuItem>
        );
      } else {
        optionsRender.push(
          <MenuItem key={key} value={value.value}>
            {value.label}
          </MenuItem>
        );
      }
    });

    var className = "acmSelectContainer";
    if (this.state.focus == true) {
      className = "acmSelectContainer focus";
    }
    if (this.props.error == true) {
      className += " error";
    }
    // const attr = this.createAttr("acmSelect");

    var value = this.state.value;
    if (this.props.value != undefined) {
      value = this.props.value;
    }
    return (
      <FormControl
        className={
          this.props.containerClassName != undefined
            ? className + " " + this.props.containerClassName
            : className
        }
        disabled={this.props.disabled}
        onKeyDown={e => this.onKeyDown(e)}
        autoFocus={this.props.autoFocus}
      >
        {labelRender}
        <MaterialSelect
          className="acmSelect"
          value={value}
          id={this.props.id}
          inputProps={{ id: this.props.id }}
          onChange={e => this.onChange(e)}
          onOpen={e => this.onOpen(e)}
          onClose={e => this.onClose(e)}
          onBlur={e => this.onBlur(e)}
          onKeyDown={e => this.onKeyDown(e)}
          onFocus={e => this.onFocus(e)}
          inputRef={this.props.myRef}
          open={this.state.open}
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          input={<BootstrapInput name={this.props.name} />}
        >
          {optionsRender}
        </MaterialSelect>
      </FormControl>
    );
  }
}

const BootstrapInput = withStyles(theme => ({
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    /*'&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },*/
  },
}))(InputBase);