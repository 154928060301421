export default function requiredFieldsCheckMsg(prefix) {
  if (!prefix) {
    console.error("Neni vyplnen prefix");
  }
  if (!this.requiredFieldsCheck(prefix)) {
    const save = document.getElementById("save_record");
    if (save) {
      save.click();
    } else {
      this.error("Nejprve prosím vyplňte všechny povinné položky.");
    }
    return false;
  }

  return true;
}