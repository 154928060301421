export default function hrefModuleList(params) {
	if (params.type === 'rightPanel') {
		this.dataSet("rightPanel", {}, "RightPanel");

		return;
	}
	
	let previousUrl = this.dataGet("history/urls/1");
	
	let routeTo = previousUrl || "#list/" + params.relModule;
	
	if (params.blank) {
		window.open(routeTo, "_blank");
	} else {
		this.href(routeTo);
	}
}