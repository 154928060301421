import React from 'react'
import PureComponent from '../../pure'
import sAction from "sAction";
import Map from "immutable"

export default class StockLine extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showOptions: false,
            style: {}
        }
    }

    showStockOptions(event) {

        this.setState({
            showOptions: true,
            style: {
                top: event.pageY - 100 + 'px',
                left: event.currentTarget.offsetLeft - 210 + 'px'
            },
            id: 'adicon_box'
        })
    }

    getColor(mainStock) {
        const stockState = parseFloat(mainStock)
        switch (true) {
            case stockState <= 0:
                return "isNotInStock"
            case stockState <= 3:
                return "lowOnStock"
            case stockState >= 4:
                return "isInStock"
            default:
                return "stockStateUnknown"
        }
    }

    createStockOptions(stockOptions, currentStock) {
        if (!stockOptions.current_stock) {
            return;
        }

        let tableRows = []

        stockOptions.current_stock.forEach((stock, index) => {

            let currStockValue = currentStock[stock.name]
            if (!currStockValue) {
                currStockValue = "0.00"
            }
            tableRows.push(
                <tr key={stock.name} className="stockLine">
                    <td className="stockStateName" key={stock.name}>{sAction.translate(stock.lbl)}</td>
                    <td className="stockStateNumber" key={currentStock[stock.name]}>{currStockValue}</td>
                </tr>
            )
        })

        return (
            <table className="stockStateTable">
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    getStockQuantity(stock) {
        if (!stock) {
            return
        }

        if (parseFloat(stock) >= 100) {
            return "99+"
        }

        return Math.ceil(parseFloat(stock))
    }

    render() {
        let currentStock = this.props.productData.current_stock
        if(Map.isMap(this.props.productData.current_stock)){
            currentStock = this.props.productData.current_stock.toJS()
        }

        let lineRender = (
            <span className={"stockSquare " + this.getColor(currentStock.skl_stav)}
                  style={{borderRadius: 0}}>
                {this.getStockQuantity(currentStock.skl_stav)}
            </span>
        );

        let popup = null
        if (this.state.showOptions) {
            let stockOptionsTable = this.createStockOptions(this.props.stockOptions, currentStock)

            popup = (<div className="stockPopup" style={this.state.style}>
                    <div className="calAdiconHeader">
                        <span className="calAdiconBoxTitle">{sAction.translate("LBL_STOCK_STATE")}</span>
                    </div>
                    <div className="stockOptionsContainer">{stockOptionsTable}</div>
                </div>
            )
        }

        return (
            <div
                className={"newListViewLine " + this.props.extraClass}
                onMouseEnter={(e) => this.showStockOptions(e)}
                onMouseLeave={() => this.setState({showOptions: false})}
            >
                {lineRender}
                {this.state.showOptions ? popup : null}
            </div>
        );
    }

}