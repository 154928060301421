export default function saveCellField(params) {
	const data = {
		module: params.module,
		id: params.id,
		name: params.name,
		value: params.value,
		newModule: params.newModule || null,
		fieldName: params.fieldName || null
	};
	this.load();
	this.rest.post('savelistviewline', data, response => {
		const rows = this.dataGet(`${params.way}/rows`);
		const records = this.dataGet(`${params.way}/records`);
		const lineIndex = records.indexOf(params.id);
		this.dsClear();
		rows.forEach((row, rowKey) => {
			const way = `${params.way}/rows/${rowKey}/records/${lineIndex}`;
			this.dsAdd('set', `${way}/value`, response[row.key]);
			if (params.fieldName && row.key == params.fieldName.toUpperCase()) {
				var name = params.name.toUpperCase();
				this.dsAdd('set', `${way}/id`, response[name]);
			}
			this.dsAdd('set', `${way}/actEdit`, false);
			if (row.def.get("type") == "currency") {
				this.dsAdd('set', `${way}/currency_id`, response['CURRENCY_ID']);
			}
		});
		this.dsProcess();
		this.unLoad();
		this.afterLoadListView(params.way);
	});
}