import React, { Component } from 'react'
import PureComponent from '../../pure'

import Button from "../../formElements/Button";
import LeadConvertModule from "./LeadConvertModule";

import  sAction  from "sAction";

class LeadConvertContainer extends PureComponent {

    show(e,data){
        e.stopPropagation();
        sAction.rightPanelDetail(data.module,data.record);
    }
    
    handleSave(prefix){
        sAction.convertLeadSave(prefix);
    }

    handleCancel(rec){
        sAction.href("#detail/Leads/"+rec);       
    }

    renderDisplayResults() {
        let newBeansComp = [];
        let relateBeansComp = [];
        let activitiesComp = [];

        this.props.data?.newBeans.forEach(bean => {
            newBeansComp.push(
                <div className="leadConvertNewRecord" key={bean.module}>
                    <div className="lcNewRecModLbl">{sAction.translate("LBL_MODULE_NAME", bean.module)}</div>:
                    <div
                        onClick={e => this.show(e, bean)}
                        className="icon-eye detailViewFieldIconEye"
                    />
                    <a target='_blank' key={bean.record} href={'/#detail/'+bean.module+'/'+bean.record}>{bean.name}</a>
                </div>
            );
        });
        if (newBeansComp) {
            newBeansComp = (
                <div className="LeadConvertModule">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                {sAction.translate("LBL_CONVERT_NEW_RECORDS", "Leads")}
                            </div>
                        </div>
                        <div className="LeadConvertResults">
                            {newBeansComp}
                        </div>
                    </div>
                </div>
            );
        }
        this.props.data?.relatedBeans.forEach((bean) => {
            relateBeansComp.push(
                <div className="leadConvertNewRecord" key={bean.module}>
                    <div className="lcNewRecModLbl">{sAction.translate("LBL_MODULE_NAME", bean.module)}</div>:
                    <div
                        onClick={e => this.show(e, bean)}
                        className="icon-eye detailViewFieldIconEye"
                    />
                    <a target='_blank' key={bean.record} href={'/#detail/'+bean.module+'/'+bean.record}>{bean.name}</a>
                </div>
            );
        });
        if (relateBeansComp) {
            relateBeansComp = (
                <div className="LeadConvertModule">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                {sAction.translate("LBL_CONVERT_RELATED_RECORDS", "Leads")}
                            </div>
                        </div>
                        <div className="LeadConvertResults">
                            {relateBeansComp}
                        </div>
                    </div>
                </div>
            );
        }
        if (this.props.data?.activities) {
            let label = null;
            if (this.props.data?.activities?.action === 'copy') {
                label = sAction.translate('LBL_CONVERT_CONVERTED_ACTIVITIES_COPIED', 'Leads');
            } else if (this.props.data?.activities?.action === 'move') {
                label = sAction.translate('LBL_CONVERT_CONVERTED_ACTIVITIES_CONVERTED', 'Leads');
            }
            const bean = this.props.data?.activities?.bean;
            activitiesComp = (
                <div className="LeadConvertModule">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                {sAction.translate("LBL_CONVERT_CONVERTED_ACTIVITIES", "Leads")}
                            </div>
                        </div>
                        <div className="LeadConvertResults">
                            <div className="leadConvertNewRecord">{label} {this.props.data?.activities?.activitiesFound}</div>
                            <div className="leadConvertNewRecord">
                                {sAction.translate('LBL_CONVERT_CONVERTED_ACTIVITIES_TO', 'Leads')}
                                <div
                                    onClick={e => this.show(e, bean)}
                                    className="icon-eye detailViewFieldIconEye"
                                />
                                <a target='_blank' key={bean.record}
                                   href={'/#detail/' + bean.module + '/' + bean.record}
                                >{bean.name}</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <div className="LeadConvertContainer">
            <div className="leadConvertResultsContainer">
                {newBeansComp}
                {relateBeansComp}
                {activitiesComp}
            </div>
        </div>
    }

    render(){
        const way = this.props.way;
        const action = this.props.data.action;
        var renderComp = [];

        if(action == "displayResults"){
            return this.renderDisplayResults();
        }

        const views = this.props.data.detailViews;
        const record = this.props.data.record;

        views.forEach((view, ind) => {
            if(view.module == null){
                return;
            }
            let prefix = way+"/detailViews/"+ind;
            renderComp.push(
               <LeadConvertModule 
                data={view} 
                key={view.module} 
                prefix={prefix} 
                way={prefix + "/tabs/0"} 
                index={ind} 
                />
            );
        });

        
        return <div className="LeadConvertContainer">
        <div className="viewActionPanel leadConvertPanel">
            <div className="viewActionPanelButtons">
                <Button className="hoverGreen acmButton" onClick={() => {this.handleSave(way)}}><div className="icon-saveIcon actionPanelButtonIcon" />{sAction.translate("LBL_SAVE_RECORD")}</Button>
                <div className="viewPanelButtonsDelimiter" />
                <Button className="acmButton" onClick={() => {this.handleCancel(record)}}><div className="icon-deleteIcon actionPanelButtonIcon" />{sAction.translate("LBL_STORNO")}</Button>
            </div>
        </div>
            <div className="LeadConvertModulesContainer">
                {renderComp}
            </div>
        </div>
    }
}
export default LeadConvertContainer
