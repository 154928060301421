import React from "react";
import moment from 'moment';
import PureComponent from "../../pure";
import Select from '../../formElements/Select';
import Button from "../../formElements/Button";

import  sAction  from "sAction";
import Loader from "../../loader";

// header: this.translate("LBL_PRINT_INV", module),
//   content:"Select",
//   buttons: [
//     { label: this.translate("LBL_CANCEL"), callback: () => this.popupHide() },
//     // Tisk faktury/objednávky
//     {
//         label: this.translate("LBL_BTN_PRINTINV", module),
//         callback: () => this.printPrepare({...params, type: "all"}),
//     },
//     {
//         label: this.translate("LBL_BTN_PRINTINV_EN", module),
//         callback: () => this.printPrepare({...params, type: "en"}),
//     },
// ],

export default class ContractSelectTemplate extends PureComponent {
    constructor(props){
        super(props);

        let date_quote_from = new Date();
        let date_quote_expected_closed = new Date(moment().add(30, 'days').format("YYYY-MM-DD"));

        let febPom = "-02-28";
        if(moment().isLeapYear()){
            febPom = "-02-29";
        }

        const pomDate = new Date(moment().format("YYYY"+febPom));
        
        if(date_quote_from < pomDate && date_quote_expected_closed > pomDate){
            date_quote_expected_closed = pomDate;
        }
        

        this.state = {
            template: "",
            trans_period : 0,
            date_quote_from : date_quote_from,
            date_quote_expected_closed : date_quote_expected_closed,
            templates : [],
            loaded: true
        }
    }



    componentDidMount() {
    }


    render(){
        if (!this.state.loaded) return <Loader />;

        const params = this.props.data.get('data');
        const prefix = params.get('prefix');

        var druh = sAction.dataGet(prefix+"/fields/type").value;

        // var templateOptions = [];
        // templateOptions.push(
        //   {value: 'PP', label: sAction.translate("LBL_TEMPLATE_PP", "acm_contracts")},
        //   {value: 'SE', label: sAction.translate("LBL_TEMPLATE_SE", "acm_contracts")},
        //   {value: 'SD', label: sAction.translate("LBL_TEMPLATE_SD", "acm_contracts")},
        //   {value: 'ME', label: sAction.translate("LBL_TEMPLATE_ME", "acm_contracts")},
        //   {value: 'PA', label: sAction.translate("LBL_TEMPLATE_PA", "acm_contracts")},
        //   {value: 'SEO', label: sAction.translate("LBL_TEMPLATE_SEO", "acm_contracts")},
        //   {value: 'KP', label: sAction.translate("LBL_TEMPLATE_KP", "acm_contracts")},
        //   {value: 'GDPR', label: sAction.translate("LBL_TEMPLATE_GDPR", "acm_contracts")},
        //   {value: 'WD', label: sAction.translate("LBL_TEMPLATE_WD", "acm_contracts")},
        //   {value: 'DI', label: sAction.translate("LBL_TEMPLATE_DI", "acm_contracts")},
        //   {value: 'WE', label: sAction.translate("LBL_TEMPLATE_WE", "acm_contracts")},
        //   );

        return <div>
        <div className="acmPopupHeader">{sAction.translate("LBL_PRINT_CONTRACT", "acm_contracts")}</div>
        <div className="icon-Close popupCloseIcon"></div>

        <div className="templateCreatorFormContainer templateCreatorForm">
            <div className="templateCreatorFormRow">

                {sAction.translate("LBL_PRINT_THIS_CONTRACT", "acm_contracts")}
                {/*<div className="quoteTemplNameLbl">*/}
                {/*    {sAction.translate("LBL_TEMPLATE_NAME", "Quotes")}:*/}
                {/*</div>*/}
                {/*<Select */}
                {/*    open={false} */}
                {/*    name="template_name"*/}
                {/*    options={templateOptions}*/}
                {/*    onChange={(ev) => this.setState({template : ev.target.value})}*/}
                {/*/>*/}
            </div>
        </div>
        <div className="acmPopupButtons">
            {/*<Button className="hoverBlue" onClick={() => sAction.printPrepare({id:params.get('id'),module:params.get('module'),name:params.get('number'), type: druh})}>*/}
            {/*    <div className={"icon-print calHeaderIcon"} ></div>*/}
            {/*    {sAction.translate("LBL_PRINT_PDF", "acm_contracts")}*/}
            {/*</Button>*/}
            <Button className="hoverGreen" onClick={() => sAction.printWordPrepare({id:params.get('id'),module:params.get('module'),name:params.get('number'), type: druh})}>
                <div className={"icon-print calHeaderIcon"} ></div>
                {sAction.translate("LBL_PRINT_WORD", "acm_contracts")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
    </div>;
    }
}
