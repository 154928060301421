import DefaultEnum from "../../defaultFields/Enum/Enum";
import  sAction  from "sAction";

export default class Mc_lists extends DefaultEnum {
  constructor(){
      super();
      this.forceOpen = true;
  }
  saveField(data) {
    sAction.saveField(data);
  }

  getOptions(value){
    return value.toJS();
  }

}