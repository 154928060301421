import PropTypes from "prop-types";
import React from "react";
import PureComponent from "../pure";

import EmailMenu from "./EmailMenu";
import EmailList from "./EmailList";
import EmailDetail from "./EmailDetail";

import  sAction  from "sAction";
import EmailSearch from "./EmailSearch";
import EmailSettings from "./EmailSettings";
import EmailSearchSimple from "./EmailSearchSimple";
import Button from "../formElements/Button";

export default class Email extends PureComponent {
  constructor(props) {
    super(props);

    let emailUIAction = "";

    if (props.data.selectedFolder.get("mbox")) {
      emailUIAction = props.data.selectedFolder.get("isImap")
        ? "getMessageList"
        : "getMessageListSugarFolders";
    }

    const selectedFolder = props.data.selectedFolder;
    let mbox = selectedFolder.get("mbox");
    let ieId = selectedFolder.get("ieId");
    let isImap = selectedFolder.get("isImap");

    this.isResizing = false;
    this.startOffset = 0;
    this.orientation = window.innerWidth < 1500 ? "horizontal" : "vertical";

    this.state = {
      search: {
        //originalni sugar promenne

        // to_pdf: "",
        // module: "",
        // action: "",
        emailUIAction,
        mbox,
        ieId,
        isImap
      }
    };

    sAction.dataSet(props.way + "/search", this.state.search, "EmailSearch");
  }

  handleMousedown = e => {
    if (this.isResizing) return;
    this.isResizing = true;

    if (this.orientation === "vertical") {
      this.startOffset =
        document.querySelector(".email__list").offsetWidth - e.pageX;
    } else {
      this.startOffset =
        document.querySelector(".email__list").offsetHeight - e.pageY;
    }
    document
      .querySelector(".email")
      .addEventListener("mousemove", this.handleMousemove);
    document.body.addEventListener("mouseup", this.handleMouseup);
  };

  handleMousemove = e => {
    if (!this.isResizing) return;

    let newWidth;
    if (this.orientation === "vertical") {
      newWidth = this.startOffset + e.pageX;
      if (newWidth < 300) newWidth = 300;
      if (newWidth > 1000) newWidth = 1000;
    } else {
      newWidth = this.startOffset + e.pageY;
      if (newWidth < 200) newWidth = 200;
      if (newWidth > 500) newWidth = 500;
    }

    document.querySelector(".email__list").style.flexBasis = newWidth + "px";
  };

  handleMouseup = e => {
    this.isResizing = false;
    document
      .querySelector(".email")
      .removeEventListener("mousemove", this.handleMousemove);
    document.body.removeEventListener("mouseup", this.handleMouseup);
  };

  openSettings = () => {
    sAction.dsClear();
    if (this.props.data.view1 !== "settings") {
      sAction.dsAdd("set", this.props.way + "/layout", 1);
      sAction.dsAdd("set", this.props.way + "/view1", "settings");
    } else {
      sAction.dsAdd("set", this.props.way + "/layout", 2);
      sAction.dsAdd("set", this.props.way + "/view1", "");
    }
    sAction.dsProcess();
  };

  render() {
    const { data, way } = this.props; //viz DataConstructors.Emails

    // console.log("render emailContainer()", data);

    let content = "";

    let layout = data.layout;
    let view1 = data.view1;
    // let view2 = data.view2;
    // view2 = "compose";

    if (layout == 1) {
      switch (view1) {
        case "settings":
          content = <EmailSettings way={way} />;
          break;
        case "detail":
          content = (
            <EmailDetail
              data={data.activeEmail}
              folderWay={data.selectedFolder.get("way")}
              orientation={this.orientation}
            />
          );
          break;
      }
    }

    if (layout == 2) {
      content = (
        <EmailDetail
          data={data.activeEmail}
          folderWay={data.selectedFolder.get("way")}
        />
      );
    }

    return (
      <div className="pageContainer ListViewContainer">
        <div className="ListViewContainerBody">
          <div className="email__header">
            <div
              className="refreshIcon"
              onClick={() => sAction.emailsRefresh(way)}
              tabIndex={0}
            />

            <EmailSearchSimple search={data.search} way={way} />

            <div className="settingsContainer">
              <div className="viewActionPanelButtons">
                <Button onClick={this.openSettings} className="hoverBlue">
                  {/*Nastavení - Zavřít nastavení*/}
                  {view1 !== "settings" ? (
                    <React.Fragment>
                      <div className="actionPanelButtonIcon icon-settings" />
                      {sAction.translate("LBL_EMAIL_SETTINGS")}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="actionPanelButtonIcon icon-closeIconLight" />
                      {sAction.translate("LBL_EMAIL_SETTINGS_CLOSE")}
                    </React.Fragment>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <div className="email">
            <EmailMenu
              selectedFolder={data.selectedFolder}
              folders={data.folders}
              foldersIMAP={data.foldersIMAP}
              search={data.search}
              way={way}
            />

            <EmailSearch
              className={
                "email__search " +
                (!data.advancedSearch ? "" : "email__search--visible")
              }
              way={way}
              search={data.search}
            />

            <div
              className={
                "email__list-detail email__list-detail--" + this.orientation
              }
            >
              {layout == 2 && (
                <React.Fragment>
                  <div className="email__list">
                    {this.orientation === "vertical" && (
                      <div
                        className="email__list__resizer"
                        onMouseDown={this.handleMousedown}
                      />
                    )}
                    <EmailList
                      search={data.search}
                      emails={data.emails}
                      selectedFolder={data.selectedFolder}
                      selectedEmail={data.selectedEmail}
                      way={way}
                      orientation={this.orientation}
                    />
                  </div>
                </React.Fragment>
              )}

              {this.orientation === "horizontal" && layout == 2 && (
                <div
                  className="email__list__resizer--horizontal"
                  onMouseDown={this.handleMousedown}
                />
              )}

              <div className="email__detail">{content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  data: PropTypes.object,
  way: PropTypes.string.isRequired //view
};
