import React from "react";
import * as Graph from "react-chartjs-2";

export default class ChartJS extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return true;
  // }

  render() {
    const defaultDataset = {
      label: "My First dataset",
      backgroundColor: ["#EF5350","#5C6BC0","#66BB6A","#FFEE58","#8D6E63","#26C6DA","#9CCC65","#DC7633","#AF7AC5"],
      borderColor: "#FFF",
      borderWidth: 1,
     // hoverBackgroundColor: "rgba(255,99,132,0.4)",
     // hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 59, 80, 81, 56, 55, 40] // bug: spatny vypocet u for cyklu: pro 7 labelu zde musi byt 7+1 hodnot atd.
    }
    var options = {
   responsive: true,
        maintainAspectRatio: false 
    }
    const default_data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        defaultDataset
      ]
    };

    // tohle je error-free reseni oproti Class.defaultProps = {}
    var data = this.props.data
    data.datasets.forEachObject((dataset,key) => {
      defaultDataset.forEachObject((d,k) => {
        if(data.datasets[key][k] === undefined){
          data.datasets[key][k] = d
        }
      })
    });
    if(this.props.options != undefined){
      this.props.options.forEachObject((option,key) => {
        options[key] = option;
      })
    }
    const width = "100%";
    const height = "100%";
    

    // tohle resi bug "spatny vypocet u for cyklu: pro 7 labelu zde musi byt 7+1 hodnot atd."
    const ChartName = this.props.type;
    if (
      ChartName == "Bar" ||
      ChartName == "HorizontalBar" ||
      ChartName == "CrazyDataLine"
    ) {
      data.datasets.map(d => d.data.push(0));
    }
    let name = "";
    switch (ChartName) {
      case "Bar":
        name = <Graph.Bar data={data} height={height} options={options} />;
        break;
      case "Bubble":
        name = <Graph.Bubble data={data} height={height} options={options} />;
        break;
      case "Doughnut":
        name = <Graph.Doughnut data={data} height={height} options={options} />;
        break;
      case "Horizontalbar":
        name = (
          <Graph.HorizontalBar data={data} height={height} options={options} />
        );
        break;
      case "Line":
        name = <Graph.Line data={data} height={height} options={options} />;
        break;
      case "Pie":
        name = <Graph.Pie data={data} options={options} />;
        break;
      case "Polar":
        name = <Graph.Polar data={data} height={height} options={options} />;
        break;
      case "Radar":
        name = <Graph.Radar data={data} height={height} options={options} />;
        break;
      case "Scatter":
        name = <Graph.Scatter data={data} height={height} options={options} />;
        break;
      default:
        name = "Nepodařilo se vygenerovat graf...";
    }

    return <div style={{ width: width }} >{name}</div>;
  }
}
